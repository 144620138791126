/* eslint-disable max-len */
const endpoints = {
  steps: `${process.env.REACT_APP_API_URL}/api/v2/onboarding/steps/`,
  details: `${process.env.REACT_APP_API_URL}/api/v2/onboarding/details/`,
  propertyDetails: `${process.env.REACT_APP_API_URL}/api/v2/onboarding/property-calculated-values/`,
  progress: `${process.env.REACT_APP_API_URL}/api/v2/onboarding/progress/`,
  logout: `${process.env.REACT_APP_API_URL}/api/v1/auth/logout/`,
  resetOnboarding: `${process.env.REACT_APP_API_URL}/api/v2/onboarding/resetonboardingdata/`,
  requestCallback: `${process.env.REACT_APP_API_URL}/api/v2/onboarding/requestcallback/`,
};

export default endpoints;
