import LocaleContext from 'contexts/LocaleContext';
import { useMemo, useState } from 'react';
import messages from 'translations';

function LocaleContextProvider({ children }) {
  const [locale, setLocale] = useState<string>('de-de');

  const value = useMemo(() => {
    return {
      locale,
      setLocale: (newLocale: string) => {
        // Only set the locale if there are translation for it
        if (messages[newLocale] && newLocale !== locale) {
          setLocale(newLocale);
        }
      },
    };
  }, [locale]);

  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
}

export default LocaleContextProvider;
