import SectionsContext, {
  ISectionsContextData,
} from 'contexts/SectionsContext';
import { useMemo, useState } from 'react';

function SectionsContextProvider({ children }) {
  const [sectionsData, setSectionsData] = useState<ISectionsContextData>({
    current: null,
    future: null,
    skipped: null,
    completed: null,
  });

  const value = useMemo(() => {
    return {
      sectionsData,
      setSectionsData,
    };
  }, [sectionsData]);

  return (
    <SectionsContext.Provider value={value}>
      {children}
    </SectionsContext.Provider>
  );
}

export default SectionsContextProvider;
