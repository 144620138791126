import ExitModalContext from 'contexts/ExitModalContext';
import { useMemo, useState } from 'react';

function ExitModalContextProvider({ children }) {
  const [isExitModalVisible, setIsExitModalVisible] = useState(false);

  const value = useMemo(() => {
    return {
      isExitModalVisible,
      setIsExitModalVisible,
    };
  }, [isExitModalVisible]);

  return (
    <ExitModalContext.Provider value={value}>
      {children}
    </ExitModalContext.Provider>
  );
}

export default ExitModalContextProvider;
