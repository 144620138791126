import PropertyContext, { IPropertyData } from 'contexts/PropertyContext';
import { useMemo, useState } from 'react';

function PropertyContextProvider({ children }) {
  const [propertyData, setPropertyData] = useState<IPropertyData>({
    property_type: null,
    size: null,
    zip_code: null,
    city: null,
    pricehubble_valuation_lower: null,
    pricehubble_valuation_upper: null,
    pricehubble_valuation_accepted: null,
    valuation_customer: null,
  });

  const value = useMemo(() => {
    return {
      propertyData,
      setPropertyData,
    };
  }, [propertyData, setPropertyData]);

  return (
    <PropertyContext.Provider value={value}>
      {children}
    </PropertyContext.Provider>
  );
}

export default PropertyContextProvider;
