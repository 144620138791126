import { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// @ts-ignore
const ScrollToTop = ({ children, location }: RouteComponentProps) => {
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return children;
};

export default withRouter(ScrollToTop);
