import React from 'react';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import Page from 'components/Page';

export const FormWrapper = ({ children }: React.PropsWithChildren<{}>) => (
  <Row style={{ maxWidth: 780 }}>
    <Col xl={8}>{children}</Col>
  </Row>
);

interface Props {
  progressIndicator?: React.ReactNode;
  backButton?: React.ReactNode;
  isPropertyInfoVisible?: boolean;
}

export const FormLayout = ({
  progressIndicator,
  backButton,
  children,
  isPropertyInfoVisible,
}: React.PropsWithChildren<Props>) => (
  <Page
    progressIndicator={progressIndicator}
    backButton={backButton}
    isPropertyInfoVisible={isPropertyInfoVisible}
  >
    {children}
  </Page>
);

export const FormFullWidthLayout = ({
  children,
}: React.PropsWithChildren<{}>) => (
  <Page>
    <Container>{children}</Container>
  </Page>
);

export default FormLayout;
