/* eslint-disable max-len */
/* eslint-disable import/no-anonymous-default-export */
export default {
  footer: {
    email: 'E-Mail',
    email_address: 'beratung@bambus.io',
    menu: {
      dataProtection: 'Datenschutz',
      conditions: 'AGB',
      imprint: 'Impressum',
      jobs: 'Jobs',
      restart: 'Antrag neu starten',
    },
  },
  cookie_banner: {
    cookies_agreement_start:
      'Diese Website verwendet Cookies, um bestimmte Funktionen und das Angebot zu verbessern. Indem Sie hier fortfahren, stimmen Sie der Nutzung von Cookies und Analytics gemäß unserer ',
    data_protection: 'Datenschutzerklärung ',
    cookies_agreement_end: 'zu.',
    agree: 'Einverstanden',
    decline: 'Nein, danke',
  },
  registration: {
    expired: {
      resend_link: 'Link erneut senden',
      to_protect_data: 'Aus Sicherheitsgründen ist dieser Link abgelaufen.',
      only_valid_once:
        'Zum Schutz Ihrer Daten ist dieser Link nur 48 Stunden gültig. Hier können Sie ',
      request_new_link: 'einen neuen Link beantragen.',
      did_not_receive_new_link:
        'Sie haben keinen Link erhalten? Kontaktieren Sie unsere Berater unter +43 80 000 7171.',
    },
  },
  property_options: {
    apartment: 'Eigentumswohnung',
    single_family_house: 'Einfamilienhaus',
    semi_detached_house: 'Doppelhaushälfte',
    two_family_house: 'Zweifamilienhaus',
    property: 'Grundstück',
    corner_terraced_house: 'Reiheneckhaus',
    mid_terraced_house: 'Reihenmittelhaus',
    apartment_house: 'Mehrfamilienhaus',
    commercial_and_residential_building: 'Wohn- und Geschäftshaus',
  },
  previous_step: 'vorheriger Schritt',
  next_step: 'Weiter',
  confirm: 'Bestätigen',
  child: 'Kind ',
  day: 'Tag',
  month: 'Monat',
  year: 'Jahr',
  select: 'Auswählen',
  select_option: 'Wählen...',
  continue: 'Weiter',
  back_to_home: 'Zur Startseite',
  fill_out_later: 'Später ausfüllen',
  submit: 'Einreichen',
  edit_data: 'Angaben bearbeiten',
  mobile_sidebar_toggle: 'Überblick',
  please_fill_all_fields_correctly:
    'Bitte füllen Sie alle erforderlichen Felder richtig aus, um fortzufahren.',
  there_is_an_error_contact_us:
    'Sollte dieser Fehler mehrfach auftreten, kontaktieren Sie uns bitte unter {country_specific_hotline}.',
  unit: {
    years: 'Jahr(e)',
    m2: 'm2',
    percent: '%',
  },
  errors: {
    generic_error:
      'Etwas ist schief gelaufen, bitte versuchen Sie es später noch einmal.',
    sixty_seconds_error: 'Bitte warten Sie 60 Sekunden.',
    could_not_save:
      'Etwas ist schief gelaufen, wir konnten die Daten nicht speichern.',
    error_page_title: '404 - Seite nicht gefunden',
    error_page_subtitle: 'Es tut uns leid...',
    error_page_text:
      '...Sie wollten Sich diese Seite ansehen und wir stehen mit leeren Händen da. Soetwas gehört sich nicht, das wissen wir.',
    error_valid_number: 'Bitte geben Sie eine gültige Zahl ein.',
    error_date: {
      error_date_future: 'Dieses Datum muss in der Zukunft liegen.',
      error_date_past: 'Dieses Datum muss in der Vergangenheit liegen.',
    },
  },
  exitModal: {
    mail: 'E-Mail Adresse',
    question: 'Sind Sie sicher?',
    description:
      'Wenn Sie jetzt abbrechen, speichern wir Ihren Fortschritt. Sie können uns später kontaktieren und fortfahren.',
    exitButton: 'Zurück',
    submitButton: 'Abmelden',
  },
  sections: {
    application: 'Finanzierungswunsch',
    property: 'Beleihungsobjekt',
    property_debt: 'Bestehende Vorlasten',
    real_estate: 'Weiteres Immobilienvermögen',
    main_applicant: 'Hauptantragsteller',
    applicant_2: 'Weiterer Antragsteller',
    new_property: 'Neue Immobilie',
    tv_application: 'Auszahlungsbetrag',
    tv_property: 'Ihre Immobilie',
    tv_main_applicant: 'Persönliche Daten',
  },
  forms: {
    no_permission: {
      pagetitle: 'Es tut uns leid, wir konnten diese Seite nicht laden. ',
      description:
        'Da ist etwas schiefgelaufen, Sie haben keinen Zugriff auf diese Seite.',
      contact_us: 'Bitte kontaktieren Sie Ihren Berater.',
    },
    no_step_supplied_from_server: {
      pagetitle: 'Es tut uns leid, wir konnten Ihren Antrag nicht laden.',
      description:
        'Bitte kontaktieren Sie Ihren Ihren Berater unter {bambus_country_specific_hotline} oder schreiben Sie uns unter beratung@bambus.io.',
      contact_us: 'Bitte kontaktieren Sie Ihren Berater.',
    },
    application: {
      intro: {
        title: 'Ihr Finanzierungswunsch',
        description:
          'Mit diesen ersten Angaben helfen Sie uns zu verstehen, welche Art von Finanzierung für Sie passend ist.',
        duration: 'Dauer: ca. 1 Minute',
      },
      purpose: {
        pagetitle: 'Finanzierungsvorhaben',
        radios: {
          renovation_or_reconstruction: 'Renovierung / Umbau',
          new_construction: 'Neubau',
          deposit_or_new_purchase: 'Anzahlung / Kauf neue Immobilie',
          debt_rescheduling: 'Umschuldung',
          free_use: 'Freie Verwendung',
        },
        free_use_comments: {
          title: 'Bitte beschreiben Sie kurz Ihren Verwendungszweck',
        },
        checkbox:
          'Ich möchte eine weitere Finanzierung für die neue Immobilie beantragen (zusätzlich zur Beleihung der Bestandsimmobilie).',
        tooltip: {
          title: 'Was haben Sie vor?',
          content:
            'Geben Sie hier den oder die Verwendungszweck(e) der Finanzierung an.',
        },
      },
      equity_release: {
        pagetitle:
          'Möchten Sie für Ihre Finanzierung eine bestehende Immobilie als Sicherheit verwenden?',
        consider_equity_release: {
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
      },
      new_build: {
        pagetitle: 'Bitte beschreiben Sie kurz Ihr geplantes Bauvorhaben',
      },
      preferences: {
        pagetitle: 'Finanzierungspräferenzen',
        tooltip: {
          title: 'tooltip-title',
          content: 'tooltip-content',
        },
        desired_loan_amount: 'Darlehenssumme',
        desired_loan_amount_error: 'Bitte geben Sie eine gültige Zahl ein',
        desired_term: 'Bevorzugte Laufzeit (Jahre)',
        desired_monthly_rate: 'Monatliche Rate',
        desired_monthly_rate_error: 'Mindestens 200,00 €.',
        wants_to_enter_more_preferences: {
          title:
            'Wollen Sie weitere Finanzierungspräferenzen angeben (z.B. Rate, Zinsbindung)?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        desired_fixed_term_length: 'Dauer der Zinsbindung (Jahre)',
        tooltips: {
          desired_loan_amount: {
            title: 'tooltip-title',
            content: 'Wie hoch soll die Darlehenssumme insgesamt sein?',
            content_additional:
              'Bei Neukauf mit Beleihung Ihrer Bestandsimmobilie, geben Sie bitte den gewünschten Gesamtbetrag an.',
          },
          desired_monthly_rate: {
            title: 'tooltip-title',
            content: 'Haben Sie eine Präferenz zur Höhe der monatlichen Rate?',
          },
          desired_fixed_term_length: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        desired_type_of_interest_rate: {
          title: 'Zinssatz',
          options: {
            fixed_rate: 'Fester Zinssatz',
            variable_rate: 'Variabler Zinssatz',
          },
        },
      },
      details: {
        pagetitle: 'Immobilienbestand',
        main_property_has_debt: {
          title:
            'Bestehen Vorlasten, Immobilienkredite und/oder Bauspardarlehen auf der zu beleihende Immobilie?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Wählen Sie "Ja", wenn die Immobilie die Sie beleihen möchten bereits jetzt als Sicherheit für Darlehen dient, ein Wohn- oder Fruchtgenussrecht besteht.',
          },
        },
        consider_equity_release: {
          title:
            'Möchten Sie für Ihre Finanzierung eine bestehende Immobilie als Sicherheit verwenden?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        has_additional_properties: {
          title: 'Haben Sie bestehendes Immobilienvermögen?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        negative_schufa: {
          title: 'Haben Sie einen negativen Schufaeintrag?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'Diese Angabe wird von uns und von der Bank nachgeprüft.',
          },
        },
      },
      applicants: {
        is_applicant: {
          title: 'Füllen Sie diesen Antrag für jemanden anderen aus?',
          options: {
            true: 'Nein',
            false: 'Ja',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        negative_schufa: {
          title: 'Haben Sie einen negativen Schufaeintrag?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'Diese Angabe wird von uns und von der Bank nachgeprüft.',
          },
        },
        has_second_applicant: {
          title: 'Gibt es einen zweiten Antragsteller?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            content:
              'Die Mitverpflichtung des Ehepartners ist von unseren Partnerbanken vorgesehen, außer wenn eine Gütertrennung vereinbart ist oder der Partner aus bestimmten Gründen nicht als Kreditnehmer angesetzt werden kann.',
          },
        },
        relationship_applicants: {
          title: 'Beziehungsstand',
          options: {
            spouse: 'Ehepartner/in',
            registered_civil_partner: 'eingetragener Lebenspartner/in',
            life_partner: 'Lebenspartner/in',
            third_party: 'Dritter',
          },
        },
      },
      review: {
        pagetitle: 'Überprüfen Sie Ihre Angaben',
        purpose: {
          title: 'Finanzierungsvorhaben',
          purpose: 'Vorhaben',
          free_use_comments: 'Verwendungszweck',
        },
        equity_release: {
          title: 'Bestehende Immobilie beleihen?',
          consider_equity_release:
            'Möchten Sie für Ihre Finanzierung eine bestehende Immobilie als Sicherheit verwenden?',
        },
        new_build: {
          title: 'Geplantes Bauvorhaben',
          construction_project_description: 'Beschreibung',
        },
        preferences: {
          title: 'Finanzierungspräferenzen',
          desired_loan_amount: 'Darlehenssumme',
          desired_monthly_rate: 'Monatliche Rate',
          desired_term: 'Bevorzugte Laufzeit (Jahre)',
          desired_fixed_term_length: 'Dauer der Zinsbindung',
          desired_type_of_interest_rate: 'Zinssatz',
        },
        details: {
          title: 'Immobilienbestand',
          consider_equity_release:
            'Möchten Sie für Ihre Finanzierung eine bestehende Immobilie als Sicherheit verwenden?',
          main_property_has_debt:
            'Bestehen Vorlasten, Immobilienkredite und/oder Bauspardarlehen auf der zu beleihende Immobilie?',
          has_additional_properties:
            'Haben Sie bestehendes Immobilienvermögen?',
          negative_schufa: 'Haben Sie einen negativen Schufaeintrag?',
        },
        applicants: {
          title: 'Antragsteller',
          primary_contact_is_main_applicant: 'Sind Sie der Hauptantragsteller?',
          negative_schufa: 'Haben Sie einen negativen Schufaeintrag?',
          has_second_applicant: 'Gibt es einen zweiten Antragsteller?',
          relationship_applicants: 'Beziehungsstand Antragsteller',
        },
      },
    },
    tv_application: {
      intro: {
        title: 'Ihr gewünschter Auszahlungsbetrag',
        description:
          'Mit diesen ersten Angaben helfen Sie uns Ihr Vorhaben besser zu verstehen.',
        duration: 'Dauer: ca. 1 Minute',
      },
      preferences: {
        tv_amount:
          'Wie viel Kapital möchten Sie durch den Teilverkauf generieren?',
        tv_amount_error: 'Bitte geben Sie eine gültige Zahl ein',
        tv_important_aspects: {
          title: 'Welche Aspekte des Teilverkaufs sind Ihnen am wichtigsten?',
          options: {
            longterm_buy_back: 'In den kommenden Jahren zurückkaufen',
            longterm_sell_entirely: 'In den kommenden Jahren ganz verkaufen',
            longterm_partial_ownership: 'Langfristig einen Teil besitzen',
            sell_small_amount_no_rent:
              'Lieber ein geringerer Teilverkaufserlös und keine anteilige Miete zahlen',
            sell_large_amount_with_rent:
              'Lieber ein höherer Teilverkaufserlös und dafür anteilig Miete zahlen',
          },
        },
      },
      options: {
        tv_reason: {
          title: 'Warum möchten Sie teilverkaufen?',
          options: {
            for_spending: 'Mehr zum Leben haben',
            give_assests_to_heirs: 'Vermögen an die Erben übergeben',
            invest_own_company: 'In die eigene Firma investieren',
            new_project: 'Neues Projekt realisieren',
            repay_loan: 'Darlehen ablösen',
            other: 'Andere',
          },
        },
        has_considered_alternatives: {
          title:
            'Haben Sie sich bereits über andere Finanzierungslösungen informiert?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        alternatives_considered: {
          title: 'Welche?',
        },
      },
      questions: {
        tv_open_questions: {
          title:
            'Haben Sie Fragen zum Teilverkauf? Teilen Sie sie uns gerne mit!',
        },
      },
      review: {
        pagetitle: 'Überprüfen Sie Ihre Angaben',
        preferences: {
          title: 'Präferenzen',
          tv_amount: 'Kapital durch den Teilverkauf',
          tv_important_aspects: 'Wichtigsten Aspekte',
        },
        options: {
          title: 'Gründe und Alternativen',
          tv_reason: 'Gründe',
          has_considered_alternatives:
            'Über andere Finanzierungslösungen informiert?',
          alternatives_considered: 'Welche?',
        },
        questions: {
          title: 'Offene Fragen',
          tv_open_questions: 'Fragen zum Teilverkauf',
        },
      },
    },
    tv_property: {
      intro: {
        title: 'Angaben zu Ihrer Immobilie',
        description:
          'Geben Sie in diesem Teil Details Ihrer Immobilie an, die Sie teilverkaufen möchten.',
        duration: 'Dauer: ca. 4 Minuten',
      },
      details: {
        pagetitle: 'Angaben zur Immobilie',
        type: {
          title: 'Art der Immobilie',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            apartment: 'Eigentumswohnung',
            single_family_house: 'Einfamilienhaus (freistehend)',
            semi_detached_house: 'Doppelhaushälfte',
            two_family_house: 'Zweifamilienhaus',
            corner_terraced_house: 'Reiheneckhaus',
            mid_terraced_house: 'Reihenmittelhaus',
            apartment_house: 'Mehrfamilienhaus',
            commercial_and_residential_building: 'Wohn- und Geschäftshaus',
            property: 'Grundstück',
            other: 'Andere',
          },
        },
        apartment_type: {
          title: 'Art der Wohnung',
          options: {
            top_floor_apartment: 'Dachwohnung',
            flat: 'Etagenwohnung',
            penthouse: 'Penthouse',
            apartment_with_garden: 'Gartenwohnung',
            holiday_apartment: 'Ferienwohnung',
            collective_apartment: 'Genossenschaftswohnung',
            unfinished_apartment: 'Rohdachboden',
            flat_in_an_apartment_building: 'ETW in Zweifamilienhaus',
            other: 'Andere',
          },
        },
        year_built: {
          title: 'Baujahr',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Das Baujahr muss in der Vergangenheit liegen.',
        },
        year_renovated: {
          title: 'Jahr der letzten Generalsanierung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Kann nicht vor dem Baujahr sein',
        },
        living_area: {
          title: 'Wohnfläche',
          tooltip: {
            title: 'tooltip-title',
            content: 'In m2',
          },
        },
        ground_area: {
          title: 'Grundfläche',
          tooltip: {
            title: 'tooltip-title',
            content: 'In m2, falls zutreffend.',
          },
          error: 'Bitte geben Sie eine gültige Zahl ein',
        },
      },
      address: {
        pagetitle: 'Adresse der Immobilie',
        street_name: 'Straße',
        house_number: 'Hausnummer',
        address_line_2: 'Adresszusatz',
        zipcode: 'PLZ',
        zipcode_error: 'Bitte geben Sie eine gültige PLZ an.',
        city: 'Ort',
        country: 'Land',
        tooltip: {
          title: 'tooltip-title',
          content: 'tooltip-content',
        },
      },
      usage: {
        pagetitle: 'Nutzung der Immobilie',
        radios: {
          self_occupied: 'Selbst bewohnt',
          empty: 'Leerstehend',
          partly_rented: 'Teilweise vermietet',
          rented: 'Vermietet',
        },
        tooltip: {
          title: 'tooltip-title',
          content: 'tooltip-content',
        },
      },
      additional_details: {
        pagetitle: 'Angaben zur Immobilie',
        building_has_lift: {
          title: 'Hat das Gebäude einen Lift?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        current_condition: {
          title: 'Zustand',
          options: {
            needs_renovation: 'Renovierungsbedürftig',
            well_maintained: 'Gut gepflegt',
            newly_renovated: 'Neu renoviert',
          },
        },
        number_rooms: {
          title: 'Anzahl Zimmer',
          subtitle: 'Bewohnbare Räume, exklusive Küche und Bad',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        number_of_bathrooms: {
          title: 'Anzahl Badezimmer',
        },
      },
      parking: {
        pagetitle: 'Angaben zur Immobilie',
        has_garage: {
          title: 'Besteht eine Garage?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        has_parking_outside: {
          title: 'Bestehen Außenparkplätze am Grundstück?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
      },
      user_valuation: {
        pagetitle: 'Wertangaben der Immobilie',
        has_current_appraisal: {
          title: 'Haben Sie ein aktuelles Gutachten?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        valuation_customer: {
          title: 'Geschätzter Immobilienwert?',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Wenn Sie kein aktuelles Wertgutachten haben, geben Sie Ihre eigene Schätzung an.',
          },
        },
        valuation_customer_source: {
          title: 'Quelle der Bewertung',
          options: {
            bank: 'Hausbank',
            broker: 'Makler',
            appraiser: 'Gutachter',
            own_estimate: 'Eigene Schätzung',
          },
        },
      },
      restrictions: {
        pagetitle:
          'Sind bei Ihrer Immobilie Besitzeinschränkungen im Grundbuch eingetragen?',
        restriction_right_of_residence: {
          title: 'Wohnrecht',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Notariell im Grundbuch festgeschriebenes Recht für eine bestimmte Person die Immobilie selbst zu bewohnen.',
          },
        },
        restriction_leasehold: {
          title: 'Baurecht',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Ein zeitlich begrenztes Recht auf einem fremden Grundstück ein Haus zu erbauen oder zu verkaufen, ohne selbst das Grundstück besitzen zu müssen.',
          },
        },
        restriction_usufructuary_right: {
          title: 'Fruchtgenussrecht',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Notariell im Grundbuch festgeschriebenes Recht für eine bestimmte Person die Immobilie vollumfänglich zu nutzen, z.B. bewohnen, vermieten oder über die daraus resultierenden Einnahmen verfügen.',
          },
        },
        restriction_assisted_living: {
          title: 'Betreutes Wohnen',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Ist die Immobilie Teil einer Einrichtung für betreutes Wohnen?',
          },
        },
      },
      property_loan: {
        has_mortgage: {
          title:
            'Bestehen Vorlasten, Immobilienkredite und/oder Bauspardarlehen auf der Immobilie?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            content:
              'Wählen Sie "Ja", wenn die Immobilie die Sie teilverkaufen möchten bereits jetzt als Sicherheit für ein Darlehen dient.',
          },
        },
        amount_remaining: {
          title: 'Wie hoch ist die noch ausstehende Restschuld?',
        },
      },
      ownership: {
        ownership_single_owner: {
          title: 'Gehört Ihnen die Immobilie alleine?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        ownership_multiple_description: {
          title: 'Bitte beschreiben Sie das Besitzverhältnis:',
        },
      },
      review: {
        pagetitle: 'Überprüfen Sie Ihre Angaben',
        details: {
          title: 'Angaben zur Immobilie',
          property_type: 'Art der Immobilie',
          apartment_type: 'Art der Wohnung',
          year_built: 'Baujahr',
          year_renovated: 'Jahr der letzten Generalsanierung',
          living_area: 'Wohnfläche',
          ground_area: 'Grundfläche',
        },
        address: {
          title: 'Adresse der Immobilie',
          street_name: 'Straße',
          house_number: 'Hausnummer',
          address_line_2: 'Adresszusatz',
          zipcode: 'Postleitzahl',
          city: 'Stadt',
          country: 'Land',
        },
        usage: {
          title: 'Nutzung',
          usage: 'Nutzung',
        },
        additional_details: {
          title: 'Weitere Angaben',
          building_has_lift: 'Lift',
          current_condition: 'Zustand',
          number_rooms: 'Anzahl Zimmer',
          number_of_bathrooms: 'Anzahl Badezimmer',
        },
        parking: {
          title: 'Parken',
          has_garage: 'Garage',
          has_parking_outside: 'Außenparkplätze',
        },
        user_valuation: {
          title: 'Wertangaben',
          has_current_appraisal: 'Aktuelles Gutachten',
          valuation_customer: 'Geschätzter Immobilienwert',
          valuation_customer_source: 'Quelle der Bewertung',
        },
        restrictions: {
          title: 'Besitzeinschränkungen',
          restriction_right_of_residence: 'Wohnrecht',
          restriction_leasehold: 'Baurecht',
          restriction_usufructuary_right: 'Fruchtgenussrecht',
          restriction_assisted_living: 'Betreutes Wohnen',
        },
        property_loan: {
          title: 'Bestehende Darlehen',
          has_mortgage: 'Immobiliendarlehen',
          amount_remaining: 'Ausstehende Restschuld',
        },
        ownership: {
          title: 'Besitzer der Immobilie',
          ownership_single_owner: 'Alleiniger Besitzer',
          ownership_multiple_description: 'Besitzverhältnis',
        },
      },
    },
    property: {
      intro: {
        title: 'Angaben zur Bestandsimmobilie',
        description:
          'Geben Sie in diesem Teil Details der Immobilie an, die Sie als Sicherheit verwenden möchten. Weiteres Immobilienvermögen können Sie in einem späteren Schritt angeben.',
        duration: 'Dauer: ca. 3 Minuten',
      },
      details: {
        pagetitle: 'Angaben zur Bestandsimmobilie',
        type: {
          title: 'Art der Immobilie',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            apartment: 'Eigentumswohnung',
            single_family_house: 'Einfamilienhaus',
            semi_detached_house: 'Doppelhaushälfte',
            two_family_house: 'Zweifamilienhaus',
            property: 'Grundstück',
            corner_terraced_house: 'Reiheneckhaus',
            mid_terraced_house: 'Reihenmittelhaus',
            apartment_house: 'Mehrfamilienhaus',
            commercial_and_residential_building: 'Wohn- und Geschäftshaus',
          },
        },
        apartment_type: {
          title: 'Art der Wohnung',
          options: {
            top_floor_apartment: 'Dachwohnung',
            flat: 'Etagenwohnung',
            penthouse: 'Penthouse',
            apartment_with_garden: 'Gartenwohnung',
            holiday_apartment: 'Ferienwohnung',
            collective_apartment: 'Genossenschaftswohnung',
            unfinished_apartment: 'Rohdachboden',
            flat_in_an_apartment_building: 'ETW in Zweifamilienhaus',
          },
        },
        year_built: {
          title: 'Baujahr',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Das Baujahr muss in der Vergangenheit liegen.',
        },
        year_renovated: {
          title: 'Jahr der letzten Generalsanierung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Kann nicht vor dem Baujahr sein.',
        },
        living_area: {
          title: 'Wohnfläche',
          tooltip: {
            title: 'tooltip-title',
            content: 'In m2.',
          },
        },
        ground_area: {
          title: 'Grundfläche',
          tooltip: {
            title: 'tooltip-title',
            content: 'In m2, falls zutreffend.',
          },
          error: 'Bitte geben Sie eine gültige Zahl ein',
        },
      },
      address: {
        pagetitle: 'Adresse der Bestandsimmobilie',
        street_name: 'Straße',
        house_number: 'Hausnummer',
        address_line_2: 'Adresszusatz',
        zipcode: 'PLZ',
        zipcode_error: 'Bitte geben Sie eine gültige PLZ an.',
        city: 'Stadt',
        country: 'Land',
        tooltip: {
          title: 'tooltip-title',
          content: 'tooltip-content',
        },
      },
      usage: {
        pagetitle: 'Nutzung der Bestandsimmobilie',
        radios: {
          self_occupied: 'Selbst genutzt',
          empty: 'Leerstehend',
          partly_rented: 'Teilweise vermietet',
          rented: 'Vermietet',
        },
        tooltip: {
          title: 'tooltip-title',
          content: 'tooltip-content',
        },
      },
      rented: {
        pagetitle: 'Vermietete Bestandsimmobilie',
        rental_income: {
          title: 'Monatliche Netto-Kaltmieteinnahmen',
          tooltip: {
            title: 'tooltip-title',
            content: 'Geben Sie hier die monatlichen Nettomieteinnahmen an.',
          },
          error: 'Mindestens 200,00 €.',
        },
        rental_area: {
          title: 'Vermietete Fläche',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Wenn die gesamte [Wohnung] vermietet ist, geben Sie hier die Wohnfläche ein.',
          },
          error: 'Zwischen 5 - 2.000 m2.',
        },
      },
      user_valuation: {
        pagetitle: 'Wertangaben der Bestandsimmobilie',
        pagetitle_valuation_available_part1:
          'Anhand der Angaben wurde der Immobilienwert auf ',
        pagetitle_valuation_available_part2: ' geschätzt.',
        tooltip_valuation: {
          title: 'Wie errechnet sich dieser Wert?',
          content:
            'Wir passen die Bemessung anhand der durchschnittlichen Quadratmeterpreise in Ihrer Region an und werten diese anhand des Immobilientyps aus. Sämtliche Angaben dienen als Richtwert und sind ohne Gewähr.',
        },
        is_automatic_valuation_accepted: {
          title: 'Diesen Wert für den Antrag übernehmen?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        valuation_customer: {
          title: 'Geschätzter Immobilienwert',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Wenn Sie kein aktuelles Wertgutachten haben, geben Sie Ihre eigene Schätzung an',
          },
        },
        valuation_customer_source: {
          title: 'Quelle der Bewertung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            bank: 'Hausbank',
            broker: 'Makler',
            appraiser: 'Gutachter',
            own_estimate: 'Eigene Schätzung',
          },
        },
        if_applicable: 'Falls zutreffend:',
        purchase_price: {
          title: 'Ursprünglicher Kaufpreis',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        purchase_year: {
          title: 'Kaufjahr',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Das Kaufjahr muss in der Vergangenheit liegen.',
        },
      },
      restrictions: {
        pagetitle: 'Besitzeinschränkungen der Bestandsimmobilie',
        restriction_right_of_residence: {
          title: 'Wohnrecht',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Notariell im Grundbuch festgeschriebenes Recht für eine bestimmte Person die Immobilie selbst zu bewohnen.',
          },
        },
        restriction_leasehold: {
          title: 'Baurecht',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Ein zeitlich begrenztes Recht auf einem fremden Grundstück ein Haus zu erbauen oder zu verkaufen, ohne selbst das Grundstück besitzen zu müssen.',
          },
        },
        restriction_usufructuary_right: {
          title: 'Fruchtgenussrecht',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Notariell im Grundbuch festgeschriebenes Recht für eine bestimmte Person die Immobilie vollumfänglich zu nutzen, z.B. bewohnen, vermieten oder über die daraus resultierenden Einnahmen verfügen.',
          },
        },
        restriction_assisted_living: {
          title: 'Betreutes Wohnen',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Ist die Immobilie Teil einer Einrichtung für betreutes Wohnen?',
          },
        },
      },
      leasehold: {
        pagetitle: 'Baurecht',
        leasehold_lessor: {
          title: 'Wer ist der Baugeber?',
          options: {
            church: 'Kirche',
            municipality: 'Gemeinde',
            private: 'Privat',
            company: 'Unternehmen',
            other: 'Andere',
          },
        },
        leasehold_end_date: {
          title: 'Auslaufdatum des Baurecht',
        },
        leasehold_ground_rent_monthy: {
          title: 'Bauzins monatlich',
        },
        leasehold_standstill_agreement: {
          title: 'Stillhalterklärung',
          options: {
            issued: 'wird abgegeben',
            not_issued: 'wird nicht abgegeben',
          },
        },
        leasehold_subordination: {
          title: 'Rangrücktritt',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        leasehold_rofr: {
          title: 'Vorkaufsrecht',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
      },
      land_register: {
        pagetitle: 'Grundbuchdaten',
        land_register_municipality: {
          title: 'Katastralgemeinde',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Die Katastralgemeinde finden Sie auf dem Grundbuchauszug.',
          },
        },
        land_register_number: {
          title: 'Einlagezahl',
          tooltip: {
            title: 'tooltip-title',
            content: 'Die Einlagezahl finden Sie auf dem Grundbuchauszug.',
          },
        },
        has_land_register_statement: {
          title: 'Haben Sie einen aktuellen Grundbuchauszug?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
      },
      construction: {
        pagetitle: 'Angaben zur Bestandsimmobilie',
        construction_style: {
          title: 'Bauweise',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            massive: 'Massiv',
            panel_building: 'Plattenbau',
            wooden_stand: 'Holzständer',
            wood: 'Holz',
            log_cabin: 'Blockhaus',
            half_timbered: 'Fachwerk',
            loam: 'Lehm',
          },
        },
        facilities_status: {
          title: 'Ausstattung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            simple: 'einfach',
            good: 'gut',
            very_comfortable: 'besonders komfortabel',
          },
        },
        orientation: {
          title: 'Orientierung des Hauptwohnraums',
          options: {
            north: 'Norden',
            east: 'Osten',
            south: 'Süden',
            west: 'Westen',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'In welche Himmelsrichtung sind die Fenster des Hauptwohnraums gerichtet?',
          },
        },
        building_has_lift: {
          title: 'Hat das Gebäude einen Aufzug?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      house_details: {
        pagetitle: 'Angaben zur Bestandsimmobilie',
        construction_house_details: {
          title: 'Besonderheiten der Bauart',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            prefabricated_house: 'Fertighaus',
            assembled_house: 'Bausatzhaus',
            shell_finishing_house: 'Ausbauhaus',
            not_specified: 'Keine Besonderheit',
          },
        },
        quality_seal_checkbox: 'Das Fertighaus hat einen Gütesiegel',
        quality_seal_tooltip: {
          title: 'tooltip-title',
          content:
            'Ein Gütesiegel bestätigt den Standard, nach dem ein Fertighaus errichtet wurde.',
        },
        specifications_loft: {
          title: 'Dachgeschoss',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            expanded: 'Ausgebaut',
            not_expandable: 'Nicht ausbaufähig',
            expandable: 'Ausbaufähig',
          },
        },
        specifications_cellar: {
          title: 'Keller',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            full_cellar: 'Voll unterkellert',
            part_with_cellar: 'Teil unterkellert',
            no_cellar: 'Nicht unterkellert',
          },
        },
      },
      apartment_details_1: {
        pagetitle: 'Angaben zur Bestandsimmobilie',
        number_rooms: {
          title: 'Anzahl Zimmer',
          subtitle: 'Bewohnbare Räume, exklusive Küche und Bad',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        number_floors_building: {
          title: 'Stockwerke im Gebäude',
          subtitle: 'Angabe ohne Dachgeschoss und Keller',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      apartment_details_2: {
        pagetitle: 'Angaben zur Wohnanlage',
        number_of_units: 'Anzahl Wohnungen',
        number_units_building: {
          title: 'Wohnungen im Gebäude',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Mindestens die Anzahl Wohnungen im Hausaufgang.',
        },
        number_units_per_entrance: {
          title: 'Wohnungen im Hausaufgang',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        multiple_buildings: {
          title: 'Hat die Wohnanlage mehrere Gebäude?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        number_units_complex: {
          title: 'Wohnungen in der gesamten Wohnanlage',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Mindestens die Anzahl Wohnungen im Gebäude.',
        },
      },
      buildings_details: {
        pagetitle: 'Stockwerke im Gebäude',
        tooltip: {
          title: 'tooltip-title',
          content: 'tooltip-content',
        },
      },
      buildings_units: {
        pagetitle: 'Aufstellung aller Wohn- und Nutzeinheiten',
        number_units_residential: {
          title: 'Wohneinheiten',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        number_units_commercial: {
          title: 'Nutzeinheiten',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      units_residential: {
        pagetitle: 'Wohneinheiten',
        total_area: {
          title: 'Gesamtfläche',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Zwischen 5 - 2.000 m2.',
        },
        empty_area: {
          title: 'Davon leerstehend',
          tooltip: {
            title: 'tooltip-title',
            content: 'Falls nur ein Teil der Einheit vermietet ist.',
          },
          error: 'Max. Gesamtfläche',
        },
        usage: {
          title: 'Nutzung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            self_occupied: 'Selbst bewohnt',
            empty: 'Leerstehend',
            partly_rented: 'Teilweise vermietet',
            rented: 'Vermietet',
          },
        },
        rental_contract_radios: {
          options: {
            temporary: 'befristet',
            not_temporary: 'unbefristet',
            partially_temporary: 'beides vorhanden',
          },
          error: 'Dieses Feld ist erforderlich.',
        },
        rental_income: {
          title: 'Monatliche Netto-Kaltmieteinnahmen',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      units_commercial: {
        pagetitle: 'Nutzeinheiten',
        total_area: {
          title: 'Gesamtfläche',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Zwischen 5 - 2.000 m2.',
        },
        empty_area: {
          title: 'Davon leerstehend',
          tooltip: {
            title: 'tooltip-title',
            content: 'Falls nur ein Teil der Einheit vermietet ist.',
          },
          error: 'Max. Gesamtfläche',
        },
        usage: {
          title: 'Nutzung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            self_occupied: 'Selbst bewohnt',
            empty: 'Leerstehend',
            partly_rented: 'Teilweise vermietet',
            rented: 'Vermietet',
          },
        },
        rental_contract_radios: {
          options: {
            temporary: 'befristet',
            not_temporary: 'unbefristet',
            partially_temporary: 'beides vorhanden',
          },
          error: 'Dieses Feld ist erforderlich.',
        },
        rental_income: {
          title: 'Monatliche Netto-Kaltmieteinnahmen',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      bathroom: {
        pagetitle: 'Badezimmer der Bestandsimmobilie',
        number_of_bathrooms: {
          title: 'Anzahl Badezimmer',
        },
        number_of_separate_toilets: {
          title: 'Anzahl getrennter Toiletten',
        },
        bathroom_equipment: {
          title: 'Ausstattung',
          options: {
            bathtub: 'Badewanne',
            bidet_in_the_bathroom: 'Bidet im Badezimmer',
            double_sink: 'Doppelwaschbecken',
            shower: 'Dusche',
            hand_wash_sink_in_the_seperate_toilet:
              'Handwaschbecken im getrennten WC',
            seperate_floor_heating: 'Seperate Fußbodenheizung',
            tiling_of_the_whole_bathroom: 'Verfließung des gesamten Bades',
            toilet: 'WC',
            wellness_elements_in_the_bathroom: 'Wellnesselemente im Badezimmer',
            openable_window: 'Zu öffnendes Fenster',
          },
        },
        bathroom_type: {
          title: 'Art des Badezimmers',
          options: {
            inside: 'Innenliegend',
            with_window: 'Mit Fenster',
          },
        },
      },
      facilities: {
        pagetitle: 'Ausstattung der Bestandsimmobilie',
        floor_living_room: {
          title: 'Wohnzimmerboden',
          options: {
            plastic: 'Kunststoff',
            tiles: 'Fliesen',
            parquet_natural_stone: 'Parkett/Naturstein',
            carpet_laminate: 'Teppich/Laminat',
            others: 'Sonstige',
          },
        },
        storeroom: {
          title: 'Abstellraum',
          options: {
            no_storeroom: 'Keiner',
            inside: 'Innerhalb',
            outside: 'Außerhalb',
            inside_and_outside: 'Innerhalb/Außerhalb',
          },
        },
        heater_type: {
          title: 'Heizung',
          options: {
            individual_heating: 'Individualheizung',
            central_heating: 'Zentralheizung',
          },
        },
        heating_system: {
          title: 'Heizsystem',
          options: {
            disctrict_heating: 'Fernwärme',
            solid_fuels_wood_chips_pellets:
              'Festbrennstoffe (Hackschnitzel, Pellets)',
            solid_fuels_coal_coke_wood:
              'Festbrennstoffe (Kohle/Koks, Holz, etc.)',
            gas: 'Gas',
            solar: 'Solar',
            electricity: 'Strom',
            heat_pump_air_water:
              'Wärmepumpe (Luft-, Warmwasser-, Erdwärmepumpe)',
            oil: 'Öl',
          },
        },
      },
      review: {
        pagetitle: 'Überprüfen Sie Ihre Angaben',
        details: {
          title: 'Angaben zur Bestandsimmobilie',
          property_type: 'Art der Immobilie',
          year_built: 'Baujahr',
          year_renovated: 'Jahr der letzten Generalsanierung',
          living_area: 'Wohnfläche',
          ground_area: 'Grundfläche',
          apartment_type: 'Art der Wohnung',
        },
        address: {
          title: 'Adresse der Bestandsimmobilie',
          street_name: 'Straße',
          house_number: 'Hausnummer',
          address_line_2: 'Adresszusatz',
          zipcode: 'Postleitzahl',
          city: 'Stadt',
          country: 'Land',
        },
        usage: {
          title: 'Nutzung',
          usage: 'Nutzung',
        },
        rented: {
          title: 'Vermietete Immobilie',
          rental_income: 'Monatliche Netto-Kaltmieteinnahmen',
          rental_area: 'Vermietete Fläche',
        },
        user_valuation: {
          title: 'Wertangaben',
          valuation_customer: 'Geschätzter Immobilienwert',
          property_worth: 'Immobilienwert',
          valuation_customer_source: 'Quelle der Bewertung',
          purchase_price: 'Ursprünglicher Kaufpreis',
          purchase_year: 'Kaufjahr',
        },
        restrictions: {
          title: 'Besitzeinschränkungen des Objekts',
          restriction_leasehold: 'Baurecht',
          restriction_assisted_living: 'Betreutes Wohnen',
          restriction_usufructuary_right: 'Fruchtgenussrecht',
          restriction_right_of_residence: 'Wohnrecht',
        },
        leasehold: {
          title: 'Baurecht',
          leasehold_lessor: 'Wer ist der Baugeber?',
          leasehold_end_date: 'Auslaufdatum des Baurecht',
          leasehold_ground_rent_monthy: 'Bauzins monatlich',
          leasehold_standstill_agreement: 'Stillhalterklärung',
          leasehold_subordination: 'Rangrücktritt',
          leasehold_rofr: 'Vorkaufsrecht',
        },
        land_register: {
          title: 'Grundbuchdaten',
          land_register_municipality: 'Katastralgemeinde',
          land_register_number: 'Einlagezahl',
          has_land_register_statement:
            'Haben Sie einen aktuellen Grundbuchauszug?',
        },
        construction: {
          title: 'Bauweise und Ausstattung',
          orientation: 'Orientierung des Hauptwohnraums',
          construction_style: 'Bauweise',
          facilities_status: 'Ausstattung',
          building_has_lift: 'Hat das Gebäude einen Aufzug?',
        },
        bathroom: {
          title: 'Badezimmer der Bestandsimmobilie',
          number_of_bathrooms: 'Anzahl Badezimmer',
          number_of_separate_toilets: 'Anzahl getrennter Toiletten',
          bathroom_equipment: 'Ausstattung',
          bathroom_type: 'Art des Badezimmers',
        },
        facilities: {
          title: 'Ausstattung der Bestandsimmobilie',
          floor_living_room: 'Wohnzimmerboden',
          storeroom: 'Abstellraum',
          heater_type: 'Heizung',
          heating_system: 'Heizsystem',
        },
        house_details: {
          title: 'Besonderheiten des Hauses',
          construction_house_details: 'Besonderheiten der Bauart',
          prefabricated_house_quality_seal:
            'Das Fertighaus hat einen Gütesiegel',
          specifications_loft: 'Dachgeschoss',
          specifications_cellar: 'Keller',
        },
        apartment_details_1: {
          title: 'Angaben zur Wohnung',
          number_rooms: 'Anzahl Zimmer',
          number_floors_building: 'Stockwerke im Gebäude',
        },
        apartment_details_2: {
          title: 'Angaben zur Wohnanlage',
          number_units_building: 'Anzahl Wohnungen',
          number_units_per_entrance: 'Wohnungen im Hausaufgang',
          multiple_buildings: 'Hat die Wohnanlage mehrere Gebäude?',
          number_units_complex: 'Wohnungen in der gesamten Wohnanlage',
        },
        buildings_details: {
          title: 'Stockwerke im Gebäude',
          number_floors_building: 'Anzahl',
        },
        buildings_units: {
          title: 'Aufstellung der Einheiten',
          number_units_residential: 'Anzahl Wohneinheiten',
          number_units_commercial: 'Anzahl Nutzeinheiten',
        },
        units_residential: {
          title: 'Wohneinheiten',
          total_area: 'Gesamtfläche',
          empty_area: 'Davon leerstehend',
          usage: 'Nutzung',
          rental_contract: 'Befristeter Mietvertrag vorhanden',
          rental_income: 'Monatliche Netto-Kaltmieteinnahmen',
        },
        units_commercial: {
          title: 'Nutzeinheiten',
          total_area: 'Gesamtfläche',
          empty_area: 'Davon leerstehend',
          usage: 'Nutzung',
          rental_contract: 'Befristeter Mietvertrag vorhanden',
          rental_income: 'Monatliche Netto-Kaltmieteinnahmen',
        },
      },
    },
    property_debt: {
      intro: {
        title: 'Ihre Bestandsimmobilie: Bestehende Vorlasten',
        description:
          'Dieser Teil bezieht sich auf ausstehende Immobilien- oder Bauspardarlehen bei der Immobilie, die Sie als Sicherheit verwenden möchten.',
        hint: 'Anmerkung: Für alle bestehenden Kredite wird später eine detaillierte Dokumentation (Darlehensvertrag und Darlehenskontoauszug) benötigt.',
        duration: 'Dauer: ca. 2 Minuten',
      },
      overview: {
        pagetitle: 'Angaben zur Bestandsimmobilie',
        has_mortgage: {
          title: 'Ausstehendes Immobiliendarlehen?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Dient die Immobilie als Sicherheit für ein anderes Darlehen? Wenn ja, muss dazu ein Eintrag in Abteilung III im Grundbuch bestehen.',
          },
        },
        has_construction_loan: {
          title: 'Bausparvertrag vorhanden?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      mortgage_1: {
        pagetitle: 'Ausstehendes Immobiliendarlehen',
        amount_original: {
          title: 'Ursprüngliche Darlehenshöhe',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Wie hoch war die Kreditsumme als Sie das Darlehen ursprünglich aufgenommen haben?',
          },
        },
        monthly_rate: {
          title: 'Monatliche Rate',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        start_date: {
          title: 'Wann wurde das Darlehen aufgenommen?',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Falls das Darlehen in Absprache mit Ihrer Bank maßgeblich abgeändert wurde, geben Sie das Datum der Abänderung an.',
          },
        },
        payoff_loan_checkbox: 'Ich möchte dieses Darlehen ablösen',
        payoff_loan_tooltip: {
          title: 'tooltip-title',
          content:
            'Wenn Sie das Darlehen vor weniger als 10 Jahren aufgenommen haben, könnte eine Ablöse nicht oder nur gegen Vorfälligkeitsentschädigung möglich sein.',
        },
        bank: {
          title: 'Bank',
        },
      },
      mortgage_2: {
        pagetitle: 'Ausstehendes Immobiliendarlehen',
        amount_remaining: {
          title: 'Aktuelle Restschuld',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        interest_rate: {
          title: 'Effektivzins',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        has_variable_rate: {
          title: 'Zinsbindung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        expiry_date: {
          title: 'Ablauf Zinsbindung',
          tooltip: {
            title: 'tooltip-title',
            content: 'Geben Sie hier den Effektivzins an.',
          },
        },
      },
      construction_loan: {
        pagetitle: 'Bausparvertrag',
        construction_contract_savings: {
          title: 'Bausparguthaben',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        construction_loan_remaining: {
          title: 'Bauspardarlehen aktuelle Restschuld',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      review: {
        pagetitle: 'Überprüfen Sie Ihre Angaben',
        overview: {
          title: 'Bestehende Vorlasten der Immobilie',
          has_mortgage: 'Ausstehendes Immobiliendarlehen?',
          has_construction_loan: 'Bausparvertrag vorhanden?',
        },
        mortgage_1: {
          title: 'Ausstehendes Darlehen',
          amount_original: 'Ursprüngliche Darlehenshöhe',
          monthly_rate: 'Monatliche Rate',
          start_date: 'Wann wurde das Darlehen aufgenommen?',
          payoff_with_new_loan: 'Darlehen mit dieser Finanzierung ablösen?',
          bank: 'Bank',
        },
        mortgage_2: {
          title: 'Ablauf des Darlehens',
          amount_remaining: 'Aktuelle Restschuld',
          interest_rate: 'Effektivzins',
          has_variable_rate: 'Zinsbindung',
          expiry_date: 'Ablauf Zinsbindung',
        },
        construction_loan: {
          title: 'Bausparvertrag',
          construction_contract_savings: 'Bausparguthaben',
          construction_loan_remaining: 'Bauspardarlehen aktuelle Restschuld',
        },
      },
    },
    real_estate: {
      intro: {
        title: 'Weiteres Immobilienvermögen',
        description:
          'Dieser Teil bezieht sich auf bestehendes Immobilienvermögen. Bei der Beleihung einer Bestandsimmobilie, geben Sie hier die Immobilien an, die Sie zusätzlich zu Ihrem Beleihungsobjekt besitzen.',
        duration: 'Dauer: ca. 2 Minuten',
      },
      number: {
        number_additional_properties: {
          title: 'Anzahl weiterer Immobilien',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        hint: 'Da Sie über ein größeres Immobilienvermögen verfügen, benötigen wir eine Immobilienaufstellung. Sie erhalten dafür automatisch einen Link per Email.',
        save_data_warning:
          'Falls Sie auf den folgenden Schritten bereits Daten zu den Immobilien eingegeben haben, kann es sein, dass nicht alle Daten gespeichert werden.',
      },
      address_n: {
        pagetitle: 'Weiteres Immobilienvermögen',
        properties: {
          address: '{address}',
          property_1: 'Immobilie 1',
          property_2: 'Immobilie 2',
          property_3: 'Immobilie 3',
        },
        tooltip: {
          title: 'tooltip-title',
          content: 'tooltip-content',
        },
        property: 'Immobilie {number}: ',
        address: 'Adresse der Immobilie {number}',
        street_name: 'Straße',
        house_number: 'Hausnummer',
        address_line_2: 'Adresszusatz',
        zipcode: 'PLZ',
        zipcode_error: 'Bitte geben Sie eine gültige PLZ an.',
        city: 'Stadt',
        country: 'Land',
      },
      details_n: {
        pagetitle: 'Details zur Immobilie',
        valuation_customer: {
          title: 'Immobilienwert',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Wenn Sie kein aktuelles Wertgutachten haben, geben Sie Ihre eigene Schätzung an.',
          },
        },
        property_type: {
          title: 'Art der Immobilie',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            apartment: 'Eigentumswohnung',
            single_family_house: 'Einfamilienhaus',
            semi_detached_house: 'Doppelhaushälfte',
            two_family_house: 'Zweifamilienhaus',
            property: 'Grundstück',
            corner_terraced_house: 'Reiheneckhaus',
            mid_terraced_house: 'Reihenmittelhaus',
            apartment_house: 'Mehrfamilienhaus',
            commercial_and_residential_building: 'Wohn- und Geschäftshaus',
          },
        },
        use_as_security: {
          title: 'Als Zusatzsicherheit verwenden?',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Sie können ein Darlehen mit mehreren Immobilien besichern.',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        has_mortgage: {
          title: 'Ausstehendes Immobiliendarlehen vorhanden?',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
      },
      usage_n: {
        pagetitle: 'Details zur Immobilie',
        living_area: {
          title: 'Wohnfläche',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Zwischen 5 - 2.000 m2.',
        },
        usage: {
          title: 'Nutzung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            self_occupied: 'Selbst bewohnt',
            empty: 'Leerstehend',
            partly_rented: 'Teilweise vermietet',
            rented: 'Vermietet',
          },
        },
        rental_area: {
          title: 'Vermietete Fläche',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Wenn die gesamte [Wohnung] vermietet ist, geben Sie hier die Wohnfläche ein.',
          },
          error: 'Max. Wohnfläche',
        },
        rental_income: {
          title: 'Monatliche Netto-Kaltmieteinnahmen',
          tooltip: {
            title: 'tooltip-title',
            content: 'Geben Sie hier die monatlichen Nettomieteinnahmen an.',
          },
        },
      },
      loan_n: {
        pagetitle: 'Details zur Immobilie',
        amount_remaining: {
          title: 'Restschuld',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        monthly_rate: {
          title: 'Monatliche Rate',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        expiry_date: {
          title: 'Ablauf Zinsbindung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        payoff_with_new_loan: {
          title: 'Darlehen ablösen?',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Hier muss geklärt sein, ob das bestehende Darlehen abgelöst werden darf.',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
      },
      review: {
        pagetitle: 'Überprüfen Sie Ihre Angaben',
        number: {
          title: 'Weiteres Immobilienvermögen',
          number_additional_properties: 'Anzahl Immobilien',
        },
        'address/1': {
          title: '{reference}',
          street_name: 'Straße',
          house_number: 'Hausnummer',
          address_line_2: 'Adresszusatz',
          zipcode: 'Postleitzahl',
          city: 'Stadt',
          country: 'Land',
        },
        'details/1': {
          title: 'Details',
          valuation_customer: 'Immobilienwert',
          property_type: 'Art der Immobilie',
          has_mortgage: 'Als Zusatzsicherheit verwenden?',
          use_as_security: 'Ausstehendes Immobiliendarlehen vorhanden?',
        },
        'usage/1': {
          title: 'Nutzung',
          living_area: 'Wohnfläche',
          usage: 'Nutzung',
          rental_area: 'Vermietete Fläche',
          rental_income: 'Monatliche Netto-Kaltmieteinnahmen',
        },
        'loan/1': {
          title: 'Ausstehendes Darlehen',
          amount_remaining: 'Restschuld',
          monthly_rate: 'Monatliche Rate',
          expiry_date: 'Ablauf Zinsbindung',
          payoff_with_new_loan: 'Darlehen ablösen?',
        },
        'address/2': {
          title: '{reference}',
          street_name: 'Straße',
          house_number: 'Hausnummer',
          address_line_2: 'Adresszusatz',
          zipcode: 'Postleitzahl',
          city: 'Stadt',
          country: 'Land',
        },
        'details/2': {
          title: 'Details',
          valuation_customer: 'Immobilienwert',
          property_type: 'Art der Immobilie',
          has_mortgage: 'Als Zusatzsicherheit verwenden?',
          use_as_security: 'Ausstehendes Immobiliendarlehen vorhanden?',
        },
        'usage/2': {
          title: 'Nutzung',
          living_area: 'Wohnfläche',
          usage: 'Nutzung',
          rental_area: 'Vermietete Fläche',
          rental_income: 'Monatliche Netto-Kaltmieteinnahmen',
        },
        'loan/2': {
          title: 'Darlehen',
          amount_remaining: 'Restschuld',
          monthly_rate: 'Monatliche Rate',
          expiry_date: 'Ablauf Zinsbindung',
          payoff_with_new_loan: 'Darlehen ablösen?',
        },
        'address/3': {
          title: '{reference}',
          street_name: 'Straße',
          house_number: 'Hausnummer',
          address_line_2: 'Adresszusatz',
          zipcode: 'Postleitzahl',
          city: 'Stadt',
          country: 'Land',
        },
        'details/3': {
          title: 'Details',
          valuation_customer: 'Immobilienwert',
          property_type: 'Art der Immobilie',
          has_mortgage: 'Als Zusatzsicherheit verwenden?',
          use_as_security: 'Ausstehendes Immobiliendarlehen vorhanden?',
        },
        'usage/3': {
          title: 'Nutzung',
          living_area: 'Wohnfläche',
          usage: 'Nutzung',
          rental_area: 'Vermietete Fläche',
          rental_income: 'Monatliche Netto-Kaltmieteinnahmen',
        },
        'loan/3': {
          title: 'Darlehen',
          amount_remaining: 'Restschuld',
          monthly_rate: 'Monatliche Rate',
          expiry_date: 'Ablauf Zinsbindung',
          payoff_with_new_loan: 'Darlehen ablösen?',
        },
      },
    },
    main_applicant: {
      intro: {
        title: 'Angaben zum Hauptantragsteller',
        title_with_name:
          'Angaben zum Hauptantragsteller: {first_name} {last_name}',
        description:
          'Ihre persönlichen Angaben benötigt der Kreditgeber, um Ihre Identität zu bestätigen und Ihre Bonität zu überprüfen.',
        duration: 'Dauer: ca. 4 Minuten',
      },
      name: {
        pagetitle: 'Angaben zum Hauptantragsteller',
        salutation: {
          title: 'Anrede',
          options: {
            mr: 'Herr',
            ms: 'Frau',
          },
        },
        title: 'Titel',
        title_error: 'Max. 10 Zeichen.',
        first_name: 'Vorname',
        last_name: 'Nachname',
      },
      contact_details: {
        pagetitle: 'Kontaktdetails',
        telephone: {
          title: 'Telefon',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        phone_error:
          'Bitte geben Sie Ihre Telefonnummer ohne Klammern und ohne führende Null ein.',
        email: {
          title: 'E-Mail',
          placeholder: 'max@musterman.de',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Bitte geben Sie eine gültige E-Mail Adresse an.',
        },
        reachability_hours: {
          title: 'Wann sind Sie erreichbar?',
          mornings: 'Vormittags',
          midday: 'Mittags',
          afternoon: 'Nachmittags',
        },
      },
      personal: {
        birthdate: {
          title: 'Geburtsdatum',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Diese Angaben benötigen wir, um Ihnen ein persönliches Finanzierungsangebot zu erstellen.',
          },
        },
        country_of_birth: {
          title: 'Geburtsland',
        },
        nationality: {
          title: 'Staatsbürgerschaft',
          country: 'Land',
          remove: 'Entfernen',
          addMore: 'Weitere hinzufügen',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        military_civilian_service_completed: {
          title: 'Präsenz- / Zivildienst abgeleistet?',
          options: {
            yes: 'Ja',
            no: 'Nein',
            exempt: 'Befreit',
          },
        },
        place_of_birth: {
          title: 'Geburtsort',
        },
        residency_permit: {
          title: 'Aufenthaltsgenehmigung',
          options: {
            temporary: 'befristet',
            unlimited: 'unbefristet',
          },
        },
        residency_permit_until: {
          title: 'Enddatum der Aufenthaltsgenehmigung',
        },
      },
      taxes: {
        pagetitle: 'Steuern',
        tooltip: {
          content:
            'Geben Sie hier an, in welchem Land Sie steuerpflichtig sind.',
        },
        tax_domicile: 'Steuerdomizil',
        add_more: 'Weitere hinzufügen',
        remove: 'Entfernen',
      },
      family: {
        family_status: {
          title: 'Familienstand',
          options: {
            single: 'ledig',
            married: 'verheiratet',
            registered_civil_partnership: 'eingetragene Lebenspartnerschaft',
            widowed: 'verwitwet',
            divorced: 'geschieden',
            separated: 'getrennt',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        marriage_property_scheme: {
          title: 'Güterstand',
          options: {
            profit_sharing: 'Zugewinngemeinschaft',
            separation_of_property: 'Gütertrennung',
            joint_property: 'Gütergemeinschaft',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      children: {
        pagetitle: 'Familie',
        description:
          'Diese Details werden benötigt, um Ihre Haushaltsrechnung zu ergänzen.',
        has_dependents: {
          title: 'Haben Sie Angehörige, für die Sie unterhaltspflichtig sind?',
          subtitle:
            'Eltern sind gegenüber ihren minderjährigen Kindern grundsätzlich unterhaltspflichtig.',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        count_of_dependents: {
          title: 'Anzahl',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        has_children: {
          title: 'Haben Sie Kinder?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        shared_children: {
          title: 'Haben Sie gemeinsame Kinder mit {shared_with_name}?',
        },
        children_field: {
          birthdate: {
            title: 'Geburtsdatum',
          },
        },
        inquiry_has_own_children: 'Haben Sie weitere Kinder?',
        request_birthdates_minors:
          'Bitte teilen Sie uns das Geburtsdatum aller minderjährigen Kinder mit.',
        own_children: {
          remove: 'Entfernen',
          addMore: 'Weitere hinzufügen',
        },
      },
      residence_status: {
        residence_status: {
          title: 'Wohnsituation',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            renting: 'zur Miete',
            owned_with_loan_installment: 'im Eigentum mit Darlehensrate',
            owned_paid_off: 'in abbezahltem Eigentum',
            rent_free_with_parents_or_partner: 'mietfrei bei Eltern, Partner',
          },
        },
        residence_since: {
          title: 'Wohnhaft seit',
          tooltip: {
            title: 'tooltip-title',
            content: 'Seit wann wohnen Sie an dieser Adresse?',
          },
        },
        monthly_rent: {
          title: 'Monatliche Mietausgaben',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      residence_status_at: {
        residence_status: {
          title: 'Wohnsituation',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            renting: 'zur Miete',
            renting_in_cooperative: 'zur Miete in Genossenschaftswohnung',
            owned_with_loan_installment: 'im Eigentum mit Darlehensrate',
            owned_paid_off: 'in abbezahltem Eigentum',
            rent_free_with_parents_or_partner: 'mietfrei bei Eltern, Partner',
          },
        },
        residence_since: {
          title: 'Wohnhaft seit',
          tooltip: {
            title: 'tooltip-title',
            content: 'Seit wann wohnen Sie an dieser Adresse?',
          },
        },
        monthly_rent: {
          title: 'Monatliche Mietausgaben',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      residence_address: {
        pagetitle: 'Wohnadresse',
        residence_main_applicant: {
          title: 'Immobilienadresse ist gleich Meldeadresse?',
          address_exists: '{address_main_property}',
          address_missing:
            'Die Adresse der Immobilie, die Sie beleihen möchten.',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        street_name: 'Straße',
        house_number: 'Hausnummer',
        address_line_2: 'Adresszusatz',
        zipcode: 'PLZ',
        zipcode_error: 'Bitte geben Sie eine gültige PLZ an.',
        city: 'Stadt',
        country: 'Land',
      },
      employment: {
        highest_schooling: {
          title: 'Höchste abgeschlossene Ausbildung',
          options: {
            apprenticeship: 'Lehre',
            highschool: 'Gymnasium',
            technical_college: 'Fachhochschule',
            higher_studies_master_bachelor: 'Studium (Magister/Bachelor)',
            higher_studies_doctorate: 'Höheres Studium (Doktorat)',
          },
        },
        employment_type: {
          title: 'Beschäftigungsverhältnis',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            employed: 'Angestellte/r',
            worker: 'Arbeiter/in',
            unemployed: 'Arbeitslose/r',
            official: 'Beamter/in',
            parental_leave: 'Elternzeit',
            freelancer: 'Freiberufler/in',
            marginally_employed: 'Geringfügig Beschäftigte/r',
            managing_partner: 'Geschäftsführende/r Gesellschafter/in',
            house_husband_or_wife: 'Hausmann, -frau',
            person_of_independent_means: 'Privatier/e',
            pensioner: 'Pensionist/in',
            self_employed_on_the_balance_sheet:
              'Selbstständige/r (bilanzierend)',
            self_employed_not_on_the_balance_sheet:
              'Selbstständige/r (nicht bilanzierend)',
            soldier: 'Soldat/in',
            student: 'Student/in',
            executive: 'Vorstand',
          },
        },
        employment_start: {
          title: 'Beschäftigt seit',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        employment_sector: {
          title: 'Branche',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            banks_and_insurance_companies: 'Banken und Versicherungen',
            construction_industry: 'Baugewerbe',
            education_and_training: 'Bildung und Erziehung',
            other_services: 'Sonstige Dienstleistungen',
            energy_supply: 'Energieversorgung',
            erotic_industry: 'Erotikbranche',
            financial_services: 'Finanzdienstleistungen',
            gastronomy: 'Gastronomie',
            public_health: 'Gesundheitswesen',
            wholesale_and_retail: 'Groß- und Einzelhandel',
            handicrafts: 'Handwerk',
            hotel_industry: 'Hotelgewerbe',
            real_estate_agency: 'Immobilienvermittlung',
            it_consulting: 'IT/Beratung',
            car_dealers: 'Kfz-Händler',
            car_workshop: 'Kfz-Werkstatt',
            ecclesiastical_sponsor: 'Kirchlicher Träger',
            kiosk: 'Kiosk',
            courier_service: 'Kurierdienst',
            agriculture: 'Landwirtschaft',
            public_service: 'Öffentlicher Dienst',
            dating_agency: 'Partnervermittlung',
            production_and_industry: 'Produktion und Industrie',
            travel_agency: 'Reisebüro',
            sun_and_fitness_studio: 'Sonnen- und Fitnessstudio',
            petrol_station: 'Tankstelle',
            taxi_company: 'Taxiunternehmen',
            transport: 'Transport',
            video_library: 'Videothek',
            other: 'Sonstige',
          },
        },
        employer: {
          title: 'Arbeitgeber',
          tooltip: {
            title: 'tooltip-title',
            content: 'Bei Selbstständigkeit, bitte Firmenname.',
          },
        },
        job_title: {
          title: 'Beruf',
        },
      },
      self_employed: {
        pagetitle: 'Ihr Unternehmen',
        shareholding_percent: {
          title: 'Besitzanteil',
          tooltip: {
            content:
              'Geben Sie hier an, welchen Anteil Sie an dem Unternehmen besitzen. Bei Ein-Personen-Unternehmen sind das 100%.',
          },
          error: 'Zwischen 0 - 100%.',
        },
        yearly_net_profit: {
          title: 'Netto Jahresgewinn',
          tooltip: {
            content:
              'Geben Sie hier den Durchschnittswert der letzten zwei vollständigen Geschäftsjahre an.',
          },
        },
      },
      employment_temporary: {
        employment_is_temporary: {
          title: 'Befristetes Beschäftigungsverhältnis',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        temporary_end_date: {
          title: 'Befristet bis',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      employment_trial: {
        employment_has_trial_period: {
          title: 'Beschäftigungsverhältnis in Probezeit',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        trial_period_end_date: {
          title: 'Probezeit bis',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      income: {
        salary_monthly_net: {
          title: 'Monatliches Nettoeinkommen',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Für Selbstständige: Ihr Anteil an nicht ausgeschütteten jährlichen Gewinnen kann bei Zusatzeinkommen angegeben werden.',
            content_additional:
              'Für Arbeitslose: Höhe des monatlichen Arbeitslosengeldes',
          },
          subtitle:
            'Bitte nur die Haupteinnahmequelle angeben. Bei keiner Einnahmequelle bitte 0,00 € angeben.',
        },
        salary_number_per_year: {
          title: 'Anzahl Gehälter pro Jahr',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            12: '12',
            12.5: '12,5',
            13: '13',
            13.5: '13,5',
            14: '14',
            0: 'Keine',
          },
        },
        has_additional_income: {
          title: 'Monatliche Zusatzeinkommen',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
      },
      additional_incomes: {
        type: 'Art',
        amount: 'Höhe',
        amount_tooltip: {
          title: 'tooltip-title',
          content: 'Monatliches Nettoeinkommen.',
        },
        description: 'Bezeichnung',
        more_than_5:
          'Bei mehr als 5 Zusatzeinkommen geben Sie im letzten bitte die gesamte Summe an.',
        number_of_additional_incomes: {
          title: 'Anzahl monatlicher Zusatzeinkommen',
          subtitle: 'Aus wie vielen Quellen beziehen Sie Zusatzeinkommen?',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Geben Sie jede Einkommenskategorie (zB.: Mieteinnahmen) als ein Zusatzeinkommen an.',
          },
        },
      },
      assets: {
        assets_bank_balance: 'Bankguthaben',
        assets_other: 'Sonstiges Vermögen',
      },
      monthly_costs: {
        pagetitle: 'Monatliche Kosten',
        description: 'Falls nicht zutreffend, bitte 0,00 € angeben.',
        expenses_monthly_energy: {
          title: 'Strom, Gas',
        },
        expenses_monthly_phone_internet_tv: {
          title: 'Telefon, Internet, Fernsehen',
        },
        expenses_monthly_living: {
          title: 'Weitere Lebenserhaltungskosten',
          tooltip: {
            content: 'Bitte ohne Mietausgaben angeben.',
          },
        },
        expenses_alimony: {
          title: 'Unterhaltszahlungen',
        },
      },
      pension: {
        pagetitle: 'Pensionsanspruch',
        subtitle:
          'Bitte geben Sie hier Ihren zukünftigen Pensionsanspruch ein.',
        pension_entitlement_state: {
          title: 'Gesetzlich',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        pension_entitlement_private: {
          title: 'Privat',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      insurance: {
        pagetitle: 'Private Versicherungsbeiträge',
        description: 'Falls zutreffend, bitte angeben.',
        private_health_insurance_monthly_payments: {
          title: 'Zusatzversicherung',
          tooltip: {
            title: 'tooltip-title',
            content: 'Zusatzversicherungen müssen hier nicht angegeben werden.',
          },
        },
        private_pension_insurance_monthly_payment: {
          title: 'Pensionsversicherung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      insurance_additional: {
        pagetitle: 'Versicherungen',
        has_insurance_endowment: {
          title: 'Ablebensversicherung',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        has_insurance_property_fire: {
          title: 'Feuer- /Sachversicherung',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        has_insurance_accident: {
          title: 'Unfallversicherung',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
      },
      cars: {
        has_cars: {
          title: 'PKW Eigentum',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        number_of_cars: {
          title: 'Anzahl',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      leasing: {
        has_car_leasing: {
          title: 'Ausstehende Leasingverträge',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        car_leasing_total_monthly_rate: {
          title: 'Monatliche Leasingkosten',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        car_leasing_remaining_debt: {
          title: 'Falls zutreffend: gesamte Restschuld',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      private_loans: {
        has_private_loans: {
          title: 'Ausstehende Privatkredite',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Privatkredite wie Konsumkredite, Ratenkredite, offene Kreditkartenschulden.',
          },
        },
        number_of_private_loans: {
          title: 'Anzahl',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        private_loans: {
          title: 'Ihre Privatkredite',
          description: 'Bezeichnung',
          bank: 'Bank',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Wählen Sie eine beliebige Bezeichnung, die Ihnen erlaubt den Kredit wiederzuerkennen. ZB.: Verwendungszweck.',
          },
        },
        more_than_3:
          'Hinweis: In den folgenden Fragen können Sie Angaben für bis zu drei Kredite angeben. Geben Sie die Kredite an die Sie mit dieser Beleihung ablösen möchten zuerst an.',
      },
      loan_n: {
        pagetitle: 'Ausstehender Privatkredit : {description}',
        amount_original: {
          title: 'Ursprüngliche Darlehenshöhe',
        },
        amount_remaining: {
          title: 'Restschuld',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        monthly_rate: {
          title: 'Monatliche Rate',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        expiry_rate: {
          title: 'Laufzeit bis',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        total_term_in_months: {
          title: 'Laufzeit insgesamt',
        },
        comments: {
          title: 'Weitere Anmerkungen',
        },
        payoff_loan_checkbox: 'Ich möchte dieses Darlehen ablösen',
        payoff_loan_tooltip: {
          title: 'tooltip-title',
          content:
            'Eine Beleihung ist üblicherweise günstiger als ein Privatkredit.',
        },
      },
      review: {
        pagetitle: 'Überprüfen Sie Ihre Angaben',
        name: {
          title: 'Angaben zum Hauptantragsteller',
          salutation: 'Anrede',
          applicant_title: 'Titel',
          first_name: 'Vorname',
          last_name: 'Nachname',
        },
        contact_details: {
          title: 'Kontaktdetails',
          phone_number: 'Telefon',
          email: 'E-Mail',
          reachability_hours: 'Wann sind Sie erreichbar?',
        },
        personal: {
          title: 'Persönliche Angaben',
          birthdate: 'Geburtsdatum',
          country_of_birth: 'Geburtsland',
          nationality: 'Staatsbürgerschaft',
          nationality2: 'Weitere Staatsbürgerschaft',
          military_civilian_service_completed:
            'Präsenz- / Zivildienst abgeleistet?',
          place_of_birth: 'Geburtsort',
          residency_permit: 'Aufenthaltsgenehmigung',
          residency_permit_until: 'Enddatum der Aufenthaltsgenehmigung',
        },
        taxes: {
          title: 'Steuern',
          tax_domicile: 'Steuerdomizil',
          tax_domicile2: 'Weiteres Steuerdomizil',
        },
        family: {
          title: 'Familienstand',
          family_status: 'Familienstand',
          marriage_property_scheme: 'Güterstand',
        },
        children: {
          title: 'Familie',
          count_of_dependents: 'Anzahl unterhaltspflichtige Personen',
          children: 'Kinder',
        },
        residence_status: {
          title: 'Wohnsituation',
          residence_status: 'Wohnsituation',
          residence_since: 'Wohnhaft seit',
          monthly_rent: 'Monatliche Mietausgaben',
        },
        residence_status_at: {
          title: 'Wohnsituation',
          residence_status: 'Wohnsituation',
          residence_since: 'Wohnhaft seit',
          monthly_rent: 'Monatliche Mietausgaben',
        },
        residence_address: {
          title: 'Wohnadresse',
          residence_main_applicant:
            'Immobilienadresse ist gleich Meldeadresse?',
          street_name: 'Straße',
          house_number: 'Hausnummer',
          address_line_2: 'Adresszusatz',
          zipcode: 'Postleitzahl',
          city: 'Stadt',
          country: 'Land',
        },
        employment: {
          title: 'Beschäftigungsverhältnis',
          highest_schooling: 'Höchste abgeschlossene Ausbildung',
          employment_type: 'Beschäftigungsverhältnis',
          employment_sector: 'Branche',
          employment_start: 'Beschäftigt seit',
          employer: 'Arbeitgeber',
          job_title: 'Beruf',
        },
        self_employed: {
          title: 'Ihr Unternehmen',
          shareholding_percent: 'Besitzanteil',
          yearly_net_profit: 'Netto Jahresgewinn',
        },
        employment_temporary: {
          title: 'Befristetes Beschäftigungsverhältnis',
          employment_is_temporary: 'Befristetes Beschäftigungsverhältnis',
          temporary_end_date: 'Befristet bis',
        },
        employment_trial: {
          title: 'Beschäftigungsverhältnis in Probezeit',
          employment_has_trial_period: 'Beschäftigungsverhältnis in Probezeit',
          trial_period_end_date: 'Probezeit bis',
        },
        income: {
          title: 'Einkommen',
          salary_monthly_net: 'Monatliches Nettoeinkommen',
          salary_number_per_year: 'Anzahl Gehälter pro Jahr',
          has_additional_income: 'Monatliche Zusatzeinkommen',
        },
        additional_incomes: {
          title: 'Zusatzeinkommen',
          number_of_additional_incomes: 'Anzahl monatlicher Zusatzeinkommen',
          main_applicant_additional_incomes: 'Zusatzeinkommen',
        },
        assets: {
          title: 'Vermögenswerte',
          assets_bank_balance: 'Bankguthaben',
          assets_other: 'Sonstiges Vermögen',
        },
        monthly_costs: {
          title: 'Monatliche Kosten',
          expenses_monthly_energy: 'Strom, Gas',
          expenses_monthly_phone_internet_tv: 'Telefon, Internet, Fernsehen',
          expenses_monthly_living: 'Weitere Lebenserhaltungskosten',
          expenses_alimony: 'Unterhaltszahlungen',
        },
        pension: {
          title: 'Pensionsanspruch',
          pension_entitlement_state: 'Gesetzlich',
          pension_entitlement_private: 'Privat',
        },
        insurance: {
          title: 'Private Versicherungsbeiträge',
          private_health_insurance_monthly_payments: 'Zusatzversicherung',
          private_pension_insurance_monthly_payment: 'Pensionsversicherung',
        },
        insurance_additional: {
          title: 'Versicherungen',
          has_insurance_endowment: 'Ablebensversicherung',
          has_insurance_property_fire: 'Feuer- /Sachversicherung',
          has_insurance_accident: 'Unfallversicherung',
        },
        cars: {
          title: 'PKW Eigentum',
          has_cars: 'PKW Eigentum',
          number_of_cars: 'Anzahl',
        },
        leasing: {
          title: 'PKW Leasing',
          has_car_leasing: 'Ausstehende Leasingverträge',
          car_leasing_total_monthly_rate: 'Monatliche Leasingkosten',
          car_leasing_remaining_debt: 'Falls zutreffend: gesamte Restschuld',
        },
        private_loans: {
          title: 'Ausstehende Privatkredite',
          has_private_loans: 'Ausstehende Privatkredite',
          number_of_private_loans: 'Anzahl',
          loans: 'Ihre Privatkredite',
        },
        'loan/1': {
          title: '{reference}',
          amount_original: 'Ursprüngliche Darlehenshöhe',
          amount_remaining: 'Restschuld',
          monthly_rate: 'Monatliche Rate',
          expiry_date: 'Laufzeit bis',
          total_term_in_months: 'Laufzeit insgesamt',
          comments: 'Weitere Anmerkungen',
          payoff_with_new_loan: 'Darlehen ablösen',
        },
        'loan/2': {
          title: '{reference}',
          amount_original: 'Ursprüngliche Darlehenshöhe',
          amount_remaining: 'Restschuld',
          monthly_rate: 'Monatliche Rate',
          expiry_date: 'Laufzeit bis',
          total_term_in_months: 'Laufzeit insgesamt',
          comments: 'Weitere Anmerkungen',
          payoff_with_new_loan: 'Darlehen ablösen',
        },
        'loan/3': {
          title: '{reference}',
          amount_original: 'Ursprüngliche Darlehenshöhe',
          amount_remaining: 'Restschuld',
          monthly_rate: 'Monatliche Rate',
          expiry_date: 'Laufzeit bis',
          total_term_in_months: 'Laufzeit insgesamt',
          comments: 'Weitere Anmerkungen',
          payoff_with_new_loan: 'Darlehen ablösen',
        },
      },
    },
    tv_main_applicant: {
      intro: {
        title: 'Persönliche Daten',
        title_with_name: 'Persönliche Daten: {first_name} {last_name}',
        description:
          'Ihre persönlichen Angaben benötigen wir, um Ihnen die passende, auf Sie zugeschnittene Lösung anbieten zu können.',
        duration: 'Dauer: ca. 3 Minuten',
      },
      name: {
        pagetitle: 'Persönliche Angaben',
        salutation: {
          title: 'Anrede',
          options: {
            mr: 'Herr',
            ms: 'Frau',
          },
        },
        title: 'Titel',
        title_error: 'Max. 10 Zeichen.',
        first_name: 'Vorname',
        last_name: 'Nachname',
      },
      contact_details: {
        pagetitle: 'Kontaktdetails',
        telephone: {
          title: 'Telefon',
        },
        phone_error:
          'Bitte geben Sie Ihre Telefonnummer ohne Klammern und ohne führende Null ein.',
        email: {
          title: 'E-Mail',
          placeholder: 'max@musterman.de',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Bitte geben Sie eine gültige E-Mail Adresse an.',
        },
        reachability_hours: {
          title: 'Erreichbarkeit',
          mornings: 'Vormittags',
          midday: 'Mittags',
          afternoon: 'Nachmittags',
        },
      },
      details: {
        pagetitle: 'Weitere Angaben',
        birthdate: {
          title: 'Geburtsdatum',
        },
        family_status: {
          title: 'Familienstand',
          options: {
            single: 'ledig',
            married: 'verheiratet',
            widowed: 'verwitwet',
            divorced: 'geschieden',
            separated: 'getrennt',
            registered_civil_partnership: 'eingetragene Lebenspartnerschaft',
          },
        },
        marriage_property_scheme: {
          title: 'Güterstand',
          options: {
            profit_sharing: 'Zugewinngemeinschaft',
            separation_of_property: 'Gütertrennung',
            joint_property: 'Gütergemeinschaft',
          },
        },
        has_children: {
          title: 'Haben Sie Kinder?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        household_count: {
          title: 'Wie viele Personen im Haushalt?',
        },
      },
      employment: {
        pagetitle: 'Weitere Angaben',
        employment_type: {
          title: 'Beschäftigungsverhältnis',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            employed: 'Angestellte/r',
            worker: 'Arbeiter/in',
            unemployed: 'Arbeitslose/r',
            official: 'Beamter/in',
            parental_leave: 'Elternzeit',
            freelancer: 'Freiberufler/in',
            marginally_employed: 'Geringfügig Beschäftigte/r',
            managing_partner: 'Geschäftsführende/r Gesellschafter/in',
            house_husband_or_wife: 'Hausmann, -frau',
            person_of_independent_means: 'Privatier/e',
            pensioner: 'Rentner/in',
            self_employed_on_the_balance_sheet:
              'Selbstständige/r (bilanzierend)',
            self_employed_not_on_the_balance_sheet:
              'Selbstständige/r (nicht bilanzierend)',
            soldier: 'Soldat/in',
            student: 'Student/in',
            executive: 'Vorstand',
          },
        },
        salary_monthly_net: {
          title: 'Monatliches Nettoeinkommen',
        },
      },
      residence_address: {
        pagetitle: 'Wohnadresse',
        residence_main_applicant: {
          title:
            'Sind Sie in der Immobilie gemeldet, die Sie teilverkaufen möchten?',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        street_name: 'Straße',
        house_number: 'Hausnummer',
        address_line_2: 'Adresszusatz',
        zipcode: 'PLZ',
        zipcode_error: 'Bitte geben Sie eine gültige PLZ an.',
        city: 'Ort',
        country: 'Land',
      },
      review: {
        pagetitle: 'Überprüfen Sie Ihre Angaben',
        name: {
          title: 'Angaben zum Hauptantragsteller',
          salutation: 'Anrede',
          applicant_title: 'Titel',
          first_name: 'Vorname',
          last_name: 'Nachname',
        },
        contact_details: {
          title: 'Kontaktdetails',
          phone_number: 'Telefon',
          email: 'E-Mail',
          reachability_hours: 'Erreichbarkeit',
        },
        details: {
          title: 'Persönliche Angaben',
          birthdate: 'Geburtsdatum',
          family_status: 'Familienstand',
          marriage_property_scheme: 'Güterstand',
          has_children: 'Kinder',
          household_count: 'Personen im Haushalt',
        },
        employment: {
          title: 'Beschäftigungsverhältnis',
          employment_type: 'Beschäftigungsverhältnis',
          salary_monthly_net: 'Monatliches Nettoeinkommen',
        },
        residence_address: {
          title: 'Wohnadresse',
          residence_main_applicant:
            'Immobilienadresse ist gleich Meldeadresse?',
          street_name: 'Straße',
          house_number: 'Hausnummer',
          address_line_2: 'Adresszusatz',
          zipcode: 'Postleitzahl',
          city: 'Stadt',
          country: 'Land',
        },
      },
    },
    new_property: {
      general_subheading:
        'Wenn Sie noch kein genaues Objekt im Sinn haben, geben Sie bitte Zielwerte ein, an die Sie sich bei Ihrer Immobiliensuche orientieren.',
      intro: {
        title: 'Angaben zur neuen Immobilie',
        description:
          'Geben Sie hier die Details der Immobilie an, die Sie kaufen möchten.',
        duration: 'Dauer: ca. 3 Minuten',
      },
      status: {
        pagetitle:
          'Haben Sie schon ein genaues Objekt für Ihre Finanzierung gefunden?',
        has_specific_property_for_purchase: {
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
      },
      details: {
        pagetitle: 'Angaben zur neuen Immobilie',
        type: {
          title: 'Art der Immobilie',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            apartment: 'Eigentumswohnung',
            single_family_house: 'Einfamilienhaus',
            semi_detached_house: 'Doppelhaushälfte',
            two_family_house: 'Zweifamilienhaus',
            property: 'Grundstück',
            corner_terraced_house: 'Reiheneckhaus',
            mid_terraced_house: 'Reihenmittelhaus',
            apartment_house: 'Mehrfamilienhaus',
            commercial_and_residential_building: 'Wohn- und Geschäftshaus',
          },
        },
        apartment_type: {
          title: 'Art der Wohnung',
          options: {
            top_floor_apartment: 'Dachwohnung',
            flat: 'Etagenwohnung',
            penthouse: 'Penthouse',
            apartment_with_garden: 'Gartenwohnung',
            holiday_apartment: 'Ferienwohnung',
            collective_apartment: 'Genossenschaftswohnung',
            unfinished_apartment: 'Rohdachboden',
            flat_in_an_apartment_building: 'ETW in Zweifamilienhaus',
          },
        },
        year_built: {
          title: 'Baujahr',
          title_no_new_property_selected: 'Bevorzugtes Baujahr',
          tooltip: {
            content: 'Falls nicht vorhanden, reicht eine Schätzung.',
          },
          error: 'Das Baujahr muss ein gültiges Jahr sein.',
        },
        year_renovated: {
          title: 'Jahr der letzten Generalsanierung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Kann nicht vor dem Baujahr sein.',
        },
        living_area: {
          title: 'Wohnfläche',
          tooltip: {
            title: 'tooltip-title',
            content: 'In m2.',
          },
        },
        ground_area: {
          title: 'Grundfläche',
          tooltip: {
            title: 'tooltip-title',
            content: 'In m2, falls zutreffend.',
          },
          error: 'Bitte geben Sie eine gültige Zahl ein',
        },
      },
      address: {
        pagetitle: 'Adresse der neuen Immobilie',
        subheading:
          'Falls Sie noch kein genaues Objekt im Sinn haben, können Sie als Richtwert einen Ort angeben in dem Sie suchen.',
        street_name: 'Straße',
        house_number: 'Hausnummer',
        address_line_2: 'Adresszusatz',
        zipcode: 'PLZ',
        zipcode_error: 'Bitte geben Sie eine gültige PLZ an.',
        city: 'Stadt',
        country: 'Land',
        tooltip: {
          title: 'tooltip-title',
          content: 'tooltip-content',
        },
      },
      usage: {
        pagetitle: 'Zukünftige Nutzung der neuen Immobilie',
        radios: {
          self_occupied: 'Selbst genutzt',
          empty: 'Leerstehend',
          partly_rented: 'Teilweise vermietet',
          rented: 'Vermietet',
        },
        tooltip: {
          title: 'tooltip-title',
          content: 'tooltip-content',
        },
      },
      rented: {
        pagetitle: 'Angaben zur neuen Immobilie',
        rental_income: {
          title: 'Monatliche Netto-Kaltmieteinnahmen',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Geben Sie hier die zukünftigen monatlichen Nettomieteinnahmen an. Falls nicht vorhanden, reicht eine Schätzung.',
          },
          error: 'Mindestens 200,00 €.',
        },
        rental_area: {
          title: 'Zu vermietende Fläche',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Wenn Sie vorhaben das ganze Objekt zu vermieten, geben Sie hier die Wohnfläche an.',
          },
          error: 'Zwischen 5 - 2.000 m2.',
          living_area_subheading: 'Von der Gesamtfläche {livingArea} m2.',
        },
      },
      restrictions: {
        pagetitle: 'Besitzeinschränkungen der neuen Immobilie',
        restriction_leasehold: {
          title: 'Baurecht',
          options: {
            true: 'Ja',
            false: 'Nicht bekannt',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Ein zeitlich begrenztes Recht auf einem fremden Grundstück ein Haus zu erbauen oder zu verkaufen, ohne selbst das Grundstück besitzen zu müssen.',
          },
        },
        restriction_assisted_living: {
          title: 'Betreutes Wohnen',
          options: {
            true: 'Ja',
            false: 'Nicht bekannt',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Ist die Immobilie Teil einer Einrichtung für betreutes Wohnen?',
          },
        },
      },
      land_register: {
        pagetitle: 'Grundbuchdaten der neuen Immobilie',
        land_register_municipality: {
          title: 'Katastralgemeinde',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Die Katastralgemeinde finden Sie auf dem Grundbuchauszug.',
          },
        },
        land_register_number: {
          title: 'Einlagezahl',
          tooltip: {
            title: 'tooltip-title',
            content: 'Die Einlagezahl finden Sie auf dem Grundbuchauszug.',
          },
        },
        has_land_register_statement: {
          title: 'Haben Sie einen aktuellen Grundbuchauszug?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
      },
      construction: {
        pagetitle: 'Angaben zur neuen Immobilie',
        construction_style: {
          title: 'Bauweise',
          title_no_exact_property: 'Bevorzugte Bauweise',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            massive: 'Massiv',
            panel_building: 'Plattenbau',
            wooden_stand: 'Holzständer',
            wood: 'Holz',
            log_cabin: 'Blockhaus',
            half_timbered: 'Fachwerk',
            loam: 'Lehm',
          },
        },
        facilities_status: {
          title: 'Ausstattung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            simple: 'einfach',
            good: 'gut',
            very_comfortable: 'besonders komfortabel',
          },
        },
        orientation: {
          title: 'Orientierung des Hauptwohnraums',
          options: {
            north: 'Norden',
            east: 'Osten',
            south: 'Süden',
            west: 'Westen',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'In welche Himmelsrichtung sind die Fenster des Hauptwohnraums gerichtet?',
          },
        },
        building_has_lift: {
          title: 'Hat das Gebäude einen Aufzug?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      house_details: {
        pagetitle: 'Angaben zur neuen Immobilie',
        construction_house_details: {
          title: 'Besonderheiten der Bauart',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            prefabricated_house: 'Fertighaus',
            assembled_house: 'Bausatzhaus',
            shell_finishing_house: 'Ausbauhaus',
            not_specified: 'Keine Besonderheit',
          },
        },
        quality_seal_checkbox: 'Das Fertighaus hat einen Gütesiegel',
        quality_seal_tooltip: {
          title: 'tooltip-title',
          content:
            'Ein Gütesiegel bestätigt den Standard, nach dem ein Fertighaus errichtet wurde.',
        },
        specifications_loft: {
          title: 'Dachgeschoss',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            expanded: 'Ausgebaut',
            not_expandable: 'Nicht ausbaufähig',
            expandable: 'Ausbaufähig',
          },
        },
        specifications_cellar: {
          title: 'Keller',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            full_cellar: 'Voll unterkellert',
            part_with_cellar: 'Teil unterkellert',
            no_cellar: 'Nicht unterkellert',
          },
        },
      },
      apartment_details_1: {
        pagetitle: 'Angaben zur neuen Immobilie',
        number_rooms: {
          title: 'Anzahl Zimmer',
          subtitle: 'Bewohnbare Räume, exklusive Küche und Bad',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        number_floors_building: {
          title: 'Stockwerke im Gebäude',
          subtitle: 'Angabe ohne Dachgeschoss und Keller',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      apartment_details_2: {
        pagetitle: 'Angaben zur Wohnanlage',
        number_of_units: 'Anzahl Wohnungen',
        number_units_building: {
          title: 'Wohnungen im Gebäude',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Mindestens die Anzahl Wohnungen im Hausaufgang.',
        },
        number_units_per_entrance: {
          title: 'Wohnungen im Hausaufgang',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        multiple_buildings: {
          title: 'Hat die Wohnanlage mehrere Gebäude?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        number_units_complex: {
          title: 'Wohnungen in der gesamten Wohnanlage',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Mindestens die Anzahl Wohnungen im Gebäude.',
        },
      },
      buildings_details: {
        pagetitle: 'Angaben zur neuen Immobilie',
        number_floors_building: 'Stockwerke im Gebäude',
        tooltip: {
          title: 'tooltip-title',
          content: 'tooltip-content',
        },
      },
      user_valuation: {
        pagetitle: 'Wertangaben der neuen Immobilie',
        valuation_customer: {
          title: 'Geschätzter Immobilienwert',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Wenn Sie kein aktuelles Wertgutachten haben, geben Sie Ihre eigene Schätzung an.',
          },
        },
        valuation_customer_source: {
          title: 'Quelle der Bewertung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            bank: 'Hausbank',
            broker: 'Makler',
            appraiser: 'Gutachter',
            own_estimate: 'Eigene Schätzung',
          },
        },
        purchase_price: {
          title: 'Kaufpreis',
          tooltip: {
            content: 'Falls nicht vorhanden, reicht eine Schätzung.',
          },
        },
      },
      valuation_construction: {
        pagetitle: 'Wertangaben der neuen Immobilie',
        construction_expected_costs: {
          title: 'Baukosten',
          tooltip: {
            content: 'Falls nicht vorhanden, reicht eine Schätzung.',
          },
        },
        construction_expected_valuation: {
          title: 'Voraussichtlicher Wert der Immobilie',
          subheading: 'Bitte auch das Grundstück berücksichtigen.',
          tooltip: {
            content: 'Falls nicht vorhanden, reicht eine Schätzung.',
          },
        },
      },
      review: {
        pagetitle: 'Überprüfen Sie Ihre Angaben',
        status: {
          title: 'Objekt',
          has_specific_property_for_purchase:
            'Haben Sie schon ein genaues Objekt für Ihre Finanzierung gefunden?',
        },
        details: {
          title: 'Angaben zur neuen Immobilie',
          property_type: 'Art der Immobilie',
          year_built: 'Baujahr',
          year_renovated: 'Jahr der letzten Generalsanierung',
          living_area: 'Wohnfläche',
          ground_area: 'Grundfläche',
          apartment_type: 'Art der Wohnung',
        },
        address: {
          title: 'Adresse der neuen Immobilie',
          street_name: 'Straße',
          house_number: 'Hausnummer',
          address_line_2: 'Adresszusatz',
          zipcode: 'Postleitzahl',
          city: 'Stadt',
          country: 'Land',
        },
        usage: {
          title: 'Zukünftige Nutzung',
          usage: 'Nutzung',
        },
        rented: {
          title: 'Zu vermietende Immobilie',
          rental_income: 'Monatliche Netto-Kaltmieteinnahmen',
          rental_area: 'Zu vermietende Fläche',
        },
        restrictions: {
          title: 'Besitzeinschränkungen der neuen Immobilie',
          restriction_leasehold: 'Baurecht',
          restriction_assisted_living: 'Betreutes Wohnen',
        },
        land_register: {
          title: 'Grundbuchdaten',
          has_land_register_statement: 'Aktueller Grundbuchauszug?',
          land_register_municipality: 'Katastralgemeinde',
          land_register_number: 'Einlagezahl',
        },
        construction: {
          title: 'Bauweise und Ausstattung',
          orientation: 'Orientierung des Hauptwohnraums',
          construction_style: 'Bauweise',
          facilities_status: 'Ausstattung',
          building_has_lift: 'Hat das Gebäude einen Aufzug?',
        },
        house_details: {
          title: 'Besonderheiten des Hauses',
          construction_house_details: 'Besonderheiten der Bauart',
          prefabricated_house_quality_seal:
            'Das Fertighaus hat einen Gütesiegel',
          specifications_loft: 'Dachgeschoss',
          specifications_cellar: 'Keller',
        },
        apartment_details_1: {
          title: 'Angaben zur Wohnung',
          number_rooms: 'Anzahl Zimmer',
          number_floors_building: 'Stockwerke im Gebäude',
        },
        apartment_details_2: {
          title: 'Angaben zur Wohnanlage',
          number_units_building: 'Anzahl Wohnungen',
          number_units_per_entrance: 'Wohnungen im Hausaufgang',
          multiple_buildings: 'Hat die Wohnanlage mehrere Gebäude?',
          number_units_complex: 'Wohnungen in der gesamten Wohnanlage',
        },
        buildings_details: {
          title: 'Stockwerke im Gebäude',
          number_floors_building: 'Anzahl',
        },
        valuation_purchase: {
          title: 'Wertangaben der neuen Immobilie',
          valuation_customer: 'Geschätzter Immobilienwert',
          valuation_customer_source: 'Quelle der Bewertung',
          purchase_price: 'Kaufpreis',
        },
        valuation_construction: {
          title: 'Wertangaben der neuen Immobilie',
          construction_expected_costs: 'Baukosten',
          construction_expected_valuation: 'Voraussichtlicher Wert ',
        },
      },
    },
    applicant_2: {
      intro: {
        title: 'Weiterer Antragsteller',
        description:
          'Diese persönlichen Angaben benötigt der Kreditgeber, um die Identität des Antragstellers zu bestätigen und dessen Bonität zu überprüfen.',
        duration: 'Dauer: ca. 4 Minuten',
      },
      name: {
        pagetitle: 'Weiterer Antragsteller',
        salutation: {
          title: 'Anrede',
          options: {
            mr: 'Herr',
            ms: 'Frau',
          },
        },
        title: 'Titel',
        title_error: 'Max. 10 Zeichen.',
        first_name: 'Vorname',
        last_name: 'Nachname',
      },
      contact_details: {
        pagetitle: 'Kontaktdetails',
        telephone: {
          title: 'Telefon',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        phone_error:
          'Bitte geben Sie Ihre Telefonnummer ohne Klammern und ohne führende Null ein.',
        email: {
          title: 'E-Mail',
          placeholder: 'max@musterman.de',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          error: 'Bitte geben Sie eine gültige E-Mail Adresse an.',
        },
      },
      personal: {
        birthdate: {
          title: 'Geburtsdatum',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Diese Angaben benötigen wir, um Ihnen ein persönliches Finanzierungsangebot zu erstellen.',
          },
        },
        country_of_birth: {
          title: 'Geburtsland',
        },
        nationality: {
          title: 'Staatsbürgerschaft',
          country: 'Land',
          remove: 'Entfernen',
          addMore: 'Weitere hinzufügen',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        military_civilian_service_completed: {
          title: 'Präsenz- / Zivildienst abgeleistet?',
          options: {
            yes: 'Ja',
            no: 'Nein',
            exempt: 'Befreit',
          },
        },
        place_of_birth: {
          title: 'Geburtsort',
        },
        residency_permit: {
          title: 'Aufenthaltsgenehmigung',
          options: {
            temporary: 'befristet',
            unlimited: 'unbefristet',
          },
        },
        residency_permit_until: {
          title: 'Enddatum der Aufenthaltsgenehmigung',
        },
      },
      taxes: {
        pagetitle: 'Steuern',
        tooltip: {
          content:
            'Geben Sie hier an, in welchem Land Sie steuerpflichtig sind.',
        },
        tax_domicile: 'Steuerdomizil',
        add_more: 'Weitere hinzufügen',
        remove: 'Entfernen',
      },
      family: {
        family_status: {
          title: 'Familienstand',
          options: {
            single: 'ledig',
            married: 'verheiratet',
            registered_civil_partnership: 'eingetragene Lebenspartnerschaft',
            widowed: 'verwitwet',
            divorced: 'geschieden',
            separated: 'getrennt',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        marriage_property_scheme: {
          title: 'Güterstand',
          options: {
            profit_sharing: 'Zugewinngemeinschaft',
            separation_of_property: 'Gütertrennung',
            joint_property: 'Gütergemeinschaft',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      children: {
        pagetitle: 'Familie',
        has_dependents: {
          title_masculine:
            'Hat {name} Angehörige, für die er unterhaltspflichtig ist?',
          title_feminine:
            'Hat {name} Angehörige, für die sie unterhaltspflichtig ist?',
          subtitle:
            'Eltern sind gegenüber ihren minderjährigen Kindern grundsätzlich unterhaltspflichtig.',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        count_of_dependents: {
          title: 'Anzahl',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Nur angeben, wenn nicht bereits beim Hauptantragsteller erfasst.',
          },
        },
        has_children: {
          title: 'Hat {name} Kinder?',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        shared_children: {
          title: 'Gemeinsame Kinder mit {shared_with_name}?',
        },
        children_field: {
          birthdate: {
            title: 'Geburtsdatum',
          },
        },
        inquiry_has_own_children: 'Weitere Kinder?',
        request_birthdates_minors:
          'Bitte teilen Sie uns das Geburtsdatum aller minderjährigen Kinder mit.',
        own_children: {
          remove: 'Entfernen',
          addMore: 'Weitere hinzufügen',
        },
      },
      residence_status: {
        residence_status: {
          title: 'Wohnsituation',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            renting: 'zur Miete',
            owned_with_loan_installment: 'im Eigentum mit Darlehensrate',
            owned_paid_off: 'in abbezahltem Eigentum',
            rent_free_with_parents_or_partner: 'mietfrei bei Eltern, Partner',
          },
        },
        residence_since: {
          title: 'Wohnhaft seit',
          tooltip: {
            title: 'tooltip-title',
            content: 'Seit wann wohnen Sie an dieser Adresse?',
          },
        },
        monthly_rent: {
          title: 'Monatliche Mietausgaben',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          subtitle:
            'Bei einem gemeinsamen Haushalt, wenn bereits vollständig beim Hauptantragsteller erfasst, bitte 0,00 € angeben.',
        },
      },
      residence_status_at: {
        residence_status: {
          title: 'Wohnsituation',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            renting: 'zur Miete',
            renting_in_cooperative: 'zur Miete in Genossenschaftswohnung',
            owned_with_loan_installment: 'im Eigentum mit Darlehensrate',
            owned_paid_off: 'in abbezahltem Eigentum',
            rent_free_with_parents_or_partner: 'mietfrei bei Eltern, Partner',
          },
        },
        residence_since: {
          title: 'Wohnhaft seit',
          tooltip: {
            title: 'tooltip-title',
            content: 'Seit wann wohnen Sie an dieser Adresse?',
          },
        },
        monthly_rent: {
          title: 'Monatliche Mietausgaben',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          subtitle:
            'Bei einem gemeinsamen Haushalt, wenn bereits vollständig beim Hauptantragsteller erfasst, bitte 0,00 € angeben.',
        },
      },
      residence_address: {
        pagetitle: 'Wohnadresse',
        residence_second_applicant: {
          title: 'Immobilienadresse ist gleich Meldeadresse?',
          address_exists: '{address_main_property}',
          address_missing:
            'Die Adresse der Immobilie, die Sie beleihen möchten.',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        street_name: 'Straße',
        house_number: 'Hausnummer',
        address_line_2: 'Adresszusatz',
        zipcode: 'PLZ',
        zipcode_error: 'Bitte geben Sie eine gültige PLZ an.',
        city: 'Stadt',
        country: 'Land',
      },
      employment: {
        highest_schooling: {
          title: 'Höchste abgeschlossene Ausbildung',
          options: {
            apprenticeship: 'Lehre',
            highschool: 'Gymnasium',
            technical_college: 'Fachhochschule',
            higher_studies_master_bachelor: 'Studium (Magister/Bachelor)',
            higher_studies_doctorate: 'Höheres Studium (Doktorat)',
          },
        },
        employment_type: {
          title: 'Beschäftigungsverhältnis',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            employed: 'Angestellte/r',
            worker: 'Arbeiter/in',
            unemployed: 'Arbeitslose/r',
            official: 'Beamter/in',
            parental_leave: 'Elternzeit',
            freelancer: 'Freiberufler/in',
            marginally_employed: 'Geringfügig Beschäftigte/r',
            managing_partner: 'Geschäftsführende/r Gesellschafter/in',
            house_husband_or_wife: 'Hausmann, -frau',
            person_of_independent_means: 'Privatier/e',
            pensioner: 'Pensionist/in',
            self_employed_on_the_balance_sheet:
              'Selbstständige/r (bilanzierend)',
            self_employed_not_on_the_balance_sheet:
              'Selbstständige/r (nicht bilanzierend)',
            soldier: 'Soldat/in',
            student: 'Student/in',
            executive: 'Vorstand',
          },
        },
        employment_start: {
          title: 'Beschäftigt seit',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        employment_sector: {
          title: 'Branche',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            banks_and_insurance_companies: 'Banken und Versicherungen',
            construction_industry: 'Baugewerbe',
            education_and_training: 'Bildung und Erziehung',
            other_services: 'Sonstige Dienstleistungen',
            energy_supply: 'Energieversorgung',
            erotic_industry: 'Erotikbranche',
            financial_services: 'Finanzdienstleistungen',
            gastronomy: 'Gastronomie',
            public_health: 'Gesundheitswesen',
            wholesale_and_retail: 'Groß- und Einzelhandel',
            handicrafts: 'Handwerk',
            hotel_industry: 'Hotelgewerbe',
            real_estate_agency: 'Immobilienvermittlung',
            it_consulting: 'IT/Beratung',
            car_dealers: 'Kfz-Händler',
            car_workshop: 'Kfz-Werkstatt',
            ecclesiastical_sponsor: 'Kirchlicher Träger',
            kiosk: 'Kiosk',
            courier_service: 'Kurierdienst',
            agriculture: 'Landwirtschaft',
            public_service: 'Öffentlicher Dienst',
            dating_agency: 'Partnervermittlung',
            production_and_industry: 'Produktion und Industrie',
            travel_agency: 'Reisebüro',
            sun_and_fitness_studio: 'Sonnen- und Fitnessstudio',
            petrol_station: 'Tankstelle',
            taxi_company: 'Taxiunternehmen',
            transport: 'Transport',
            video_library: 'Videothek',
            other: 'Sonstige',
          },
        },
        employer: {
          title: 'Arbeitgeber',
          tooltip: {
            title: 'tooltip-title',
            content: 'Bei Selbstständigkeit, bitte Firmenname.',
          },
        },
        job_title: {
          title: 'Beruf',
        },
      },
      self_employed: {
        pagetitle: 'Ihr Unternehmen',
        shareholding_percent: {
          title: 'Besitzanteil',
          tooltip: {
            content:
              'Geben Sie hier an, welchen Anteil Sie an dem Unternehmen besitzen. Bei Ein-Personen-Unternehmen sind das 100%.',
          },
          error: 'Zwischen 0 - 100%.',
        },
        yearly_net_profit: {
          title: 'Netto Jahresgewinn',
          tooltip: {
            content:
              'Geben Sie hier den Durchschnittswert der letzten zwei vollständigen Geschäftsjahre an.',
          },
        },
      },
      employment_temporary: {
        employment_is_temporary: {
          title: 'Befristetes Beschäftigungsverhältnis',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        temporary_end_date: {
          title: 'Befristet bis',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      employment_trial: {
        employment_has_trial_period: {
          title: 'Beschäftigungsverhältnis in Probezeit',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        trial_period_end_date: {
          title: 'Probezeit bis',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      income: {
        salary_monthly_net: {
          title: 'Monatliches Nettoeinkommen',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Für Selbstständige: Ihr Anteil an nicht ausgeschütteten jährlichen Gewinnen kann bei Zusatzeinkommen angegeben werden.',
            content_additional:
              'Für Arbeitslose: Höhe des monatlichen Arbeitslosengeldes',
          },
          subtitle:
            'Bitte nur die Haupteinnahmequelle angeben. Bei keiner Einnahmequelle bitte 0,00 € angeben.',
        },
        salary_number_per_year: {
          title: 'Anzahl Gehälter pro Jahr',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            12: '12',
            12.5: '12,5',
            13: '13',
            13.5: '13,5',
            14: '14',
            0: 'Keine',
          },
        },
        has_additional_income: {
          title: 'Haben Sie monatliches Zusatzeinkommen?',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
      },
      additional_incomes: {
        type: 'Art',
        amount: 'Höhe',
        amount_tooltip: {
          title: 'tooltip-title',
          content: 'Monatliches Nettoeinkommen.',
        },
        description: 'Bezeichnung',
        more_than_5:
          'Bei mehr als 5 Zusatzeinkommen geben Sie im letzten bitte die gesamte Summe an.',
        number_of_additional_incomes: {
          title: 'Anzahl monatlicher Zusatzeinkommen',
          subtitle: 'Aus wie vielen Quellen beziehen Sie Zusatzeinkommen?',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Geben Sie jede Einkommenskategorie (zB.: Mieteinnahmen) als ein Zusatzeinkommen an.',
          },
        },
      },
      assets: {
        assets_bank_balance: 'Bankguthaben',
        assets_other: 'Sonstiges Vermögen',
      },
      monthly_costs: {
        pagetitle: 'Monatliche Kosten',
        page_subtitle:
          'Bei einem gemeinsamen Haushalt, nur angeben, wenn nicht bereits beim Hauptantragsteller erfasst.\r\nFalls nicht zutreffend, bitte 0,00 € angeben.',
        expenses_monthly_energy: {
          title: 'Strom, Gas',
        },
        expenses_monthly_phone_internet_tv: {
          title: 'Telefon, Internet, Fernsehen',
        },
        expenses_monthly_living: {
          title: 'Weitere Lebenserhaltungskosten',
          tooltip: {
            content: 'Bitte ohne Mietausgaben angeben.',
          },
        },
        expenses_alimony: {
          title: 'Unterhaltszahlungen',
        },
      },
      pension: {
        pagetitle: 'Pensionsanspruch',
        subtitle:
          'Bitte geben Sie hier Ihren zukünftigen Pensionsanspruch ein.',
        pension_entitlement_state: {
          title: 'Gesetzlich',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        pension_entitlement_private: {
          title: 'Privat',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      insurance: {
        pagetitle: 'Private Versicherungsbeiträge',
        description: 'Falls zutreffend, bitte angeben.',
        private_health_insurance_monthly_payments: {
          title: 'Zusatzversicherung',
          tooltip: {
            title: 'tooltip-title',
            content: 'Zusatzversicherungen müssen hier nicht angegeben werden.',
          },
        },
        private_pension_insurance_monthly_payment: {
          title: 'Pensionsversicherung',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      insurance_additional: {
        pagetitle: 'Versicherungen',
        has_insurance_endowment: {
          title: 'Ablebensversicherung',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        has_insurance_property_fire: {
          title: 'Feuer- /Sachversicherung',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        has_insurance_accident: {
          title: 'Unfallversicherung',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
      },
      cars: {
        has_cars: {
          title: 'PKW Eigentum',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Nur angeben, wenn nicht bereits beim Hauptantragsteller erfasst.',
          },
        },
        number_of_cars: {
          title: 'Anzahl',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      leasing: {
        has_car_leasing: {
          title: 'Ausstehende Leasingverträge',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        car_leasing_total_monthly_rate: {
          title: 'Monatliche Leasingkosten',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        car_leasing_remaining_debt: {
          title: 'Falls zutreffend: gesamte Restschuld',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
      },
      private_loans: {
        negative_schufa: {
          title: 'Negativer Schufaeintrag',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        has_private_loans: {
          title: 'Ausstehende Privatkredite',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
          tooltip: {
            title: 'tooltip-title',
            content:
              'Privatkredite wie Konsumkredite, Ratenkredite, offene Kreditkartenschulden.',
          },
        },
        number_of_private_loans: {
          title: 'Anzahl',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        private_loans: {
          title: 'Ihre Privatkredite',
          description: 'Bezeichnung',
          bank: 'Bank',
          tooltip: {
            title: 'tooltip-title',
            content:
              'Wählen Sie eine beliebige Bezeichnung, die Ihnen erlaubt den Kredit wiederzuerkennen. ZB.: Verwendungszweck.',
          },
        },
        more_than_3:
          'Hinweis: In den folgenden Fragen können Sie Angaben für bis zu drei Kredite angeben. Geben Sie jene Kredite die Sie mit dieser Beleihung ablösen wollen zuerst an.',
      },
      loan_n: {
        pagetitle: 'Ausstehender Privatkredit : {description}',
        amount_original: {
          title: 'Ursprüngliche Darlehenshöhe',
        },
        amount_remaining: {
          title: 'Restschuld',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        monthly_rate: {
          title: 'Monatliche Rate',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        expiry_rate: {
          title: 'Laufzeit bis',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        total_term_in_months: {
          title: 'Laufzeit insgesamt',
        },
        comments: {
          title: 'Weitere Anmerkungen',
        },
        payoff_loan_checkbox: 'Ich möchte diesen Kredit ablösen',
      },
      review: {
        pagetitle: 'Überprüfen Sie Ihre Angaben',
        name: {
          title: 'Angaben zum Antragsteller',
          salutation: 'Anrede',
          applicant_title: 'Titel',
          first_name: 'Vorname',
          last_name: 'Nachname',
        },
        contact_details: {
          title: 'Kontaktdetails',
          phone_number: 'Telefon',
          email: 'E-Mail',
        },
        personal: {
          title: 'Persönliche Angaben',
          birthdate: 'Geburtsdatum',
          country_of_birth: 'Geburtsland',
          nationality: 'Staatsbürgerschaft',
          nationality2: 'Weitere Staatsbürgerschaft',
          military_civilian_service_completed:
            'Präsenz- / Zivildienst abgeleistet?',
          place_of_birth: 'Geburtsort',
          residency_permit: 'Aufenthaltsgenehmigung',
          residency_permit_until: 'Enddatum der Aufenthaltsgenehmigung',
        },
        taxes: {
          title: 'Steuern',
          tax_domicile: 'Steuerdomizil',
          tax_domicile2: 'Weiteres Steuerdomizil',
        },
        family: {
          title: 'Familienstand',
          family_status: 'Familienstand',
          marriage_property_scheme: 'Güterstand',
        },
        children: {
          title: 'Familie',
          count_of_dependents: 'Anzahl unterhaltspflichtige Personen',
          children: 'Kinder',
        },
        residence_status: {
          title: 'Wohnsituation',
          residence_status: 'Wohnsituation',
          residence_since: 'Wohnhaft seit',
          monthly_rent: 'Monatliche Mietausgaben',
        },
        residence_status_at: {
          title: 'Wohnsituation',
          residence_status: 'Wohnsituation',
          residence_since: 'Wohnhaft seit',
          monthly_rent: 'Monatliche Mietausgaben',
        },
        residence_address: {
          title: 'Wohnadresse',
          residence_second_applicant:
            'Immobilienadresse ist gleich Meldeadresse?',
          street_name: 'Straße',
          house_number: 'Hausnummer',
          address_line_2: 'Adresszusatz',
          zipcode: 'Postleitzahl',
          city: 'Stadt',
          country: 'Land',
        },
        employment: {
          title: 'Beschäftigungsverhältnis',
          highest_schooling: 'Höchste abgeschlossene Ausbildung',
          employment_type: 'Beschäftigungsverhältnis',
          employment_sector: 'Branche',
          employment_start: 'Beschäftigt seit',
          employer: 'Arbeitgeber',
          job_title: 'Beruf',
        },
        self_employed: {
          title: 'Ihr Unternehmen',
          shareholding_percent: 'Besitzanteil',
          yearly_net_profit: 'Netto Jahresgewinn',
        },
        employment_temporary: {
          title: 'Befristetes Beschäftigungsverhältnis',
          employment_is_temporary: 'Befristetes Beschäftigungsverhältnis',
          temporary_end_date: 'Befristet bis',
        },
        employment_trial: {
          title: 'Beschäftigungsverhältnis in Probezeit',
          employment_has_trial_period: 'Beschäftigungsverhältnis in Probezeit',
          trial_period_end_date: 'Probezeit bis',
        },
        income: {
          title: 'Einkommen',
          salary_monthly_net: 'Monatliches Nettoeinkommen',
          salary_number_per_year: 'Anzahl Gehälter pro Jahr',
          has_additional_income: 'Monatliche Zusatzeinkommen',
        },
        additional_incomes: {
          title: 'Zusatzeinkommen',
          number_of_additional_incomes: 'Anzahl monatlicher Zusatzeinkommen',
          applicant_2_additional_incomes: 'Zusatzeinkommen',
        },
        assets: {
          title: 'Vermögenswerte',
          assets_bank_balance: 'Bankguthaben',
          assets_other: 'Sonstiges Vermögen',
        },
        monthly_costs: {
          title: 'Monatliche Kosten',
          expenses_monthly_energy: 'Strom, Gas',
          expenses_monthly_phone_internet_tv: 'Telefon, Internet, Fernsehen',
          expenses_monthly_living: 'Weitere Lebenserhaltungskosten',
          expenses_alimony: 'Unterhaltszahlungen',
        },
        pension: {
          title: 'Pensionsanspruch',
          pension_entitlement_state: 'Gesetzlich',
          pension_entitlement_private: 'Privat',
        },
        insurance: {
          title: 'Private Versicherungsbeiträg',
          private_health_insurance_monthly_payments: 'Zusatzversicherung',
          private_pension_insurance_monthly_payment: 'Pensionsversicherung',
        },
        insurance_additional: {
          title: 'Versicherungen',
          has_insurance_endowment: 'Ablebensversicherung',
          has_insurance_property_fire: 'Feuer- /Sachversicherung',
          has_insurance_accident: 'Unfallversicherung',
        },
        cars: {
          title: 'PKW Eigentum',
          has_cars: 'PKW Eigentum',
          number_of_cars: 'Anzahl',
        },
        leasing: {
          title: 'PKW Leasing',
          has_car_leasing: 'Ausstehende Leasingverträge',
          car_leasing_total_monthly_rate: 'Monatliche Leasingkosten',
          car_leasing_remaining_debt: 'Falls zutreffend: gesamte Restschuld',
        },
        private_loans: {
          title: 'Ausstehende Privatkredite',
          negative_schufa: 'Negativer Schufaeintrag',
          has_private_loans: 'Ausstehende Privatkredite',
          number_of_private_loans: 'Anzahl',
          loans: 'Ihre Privatkredite',
        },
        'loan/1': {
          title: '{reference}',
          amount_original: 'Ursprüngliche Darlehenshöhe',
          amount_remaining: 'Restschuld',
          monthly_rate: 'Monatliche Rate',
          expiry_date: 'Laufzeit bis',
          total_term_in_months: 'Laufzeit insgesamt',
          comments: 'Weitere Anmerkungen',
          payoff_with_new_loan: 'Darlehen ablösen',
        },
        'loan/2': {
          title: '{reference}',
          amount_original: 'Ursprüngliche Darlehenshöhe',
          amount_remaining: 'Restschuld',
          monthly_rate: 'Monatliche Rate',
          expiry_date: 'Laufzeit bis',
          total_term_in_months: 'Laufzeit insgesamt',
          comments: 'Weitere Anmerkungen',
          payoff_with_new_loan: 'Darlehen ablösen',
        },
        'loan/3': {
          title: '{reference}',
          amount_original: 'Ursprüngliche Darlehenshöhe',
          amount_remaining: 'Restschuld',
          monthly_rate: 'Monatliche Rate',
          expiry_date: 'Laufzeit bis',
          total_term_in_months: 'Laufzeit insgesamt',
          comments: 'Weitere Anmerkungen',
          payoff_with_new_loan: 'Darlehen ablösen',
        },
      },
    },
    end: {
      survey: {
        lead_source_survey: {
          title: 'Wie haben Sie von Bambus erfahren?',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
          options: {
            recommendation: 'Empfehlung',
            press: 'Presse',
            radio: 'Radio',
            tv: 'TV',
            facebook: 'Facebook',
            google: 'Google',
            mail: 'Post',
            partner: 'Partner',
            not_specified: 'Keine Angabe',
          },
        },
        recommended_by: {
          title: 'Von wem haben Sie die Empfehlung?',
          tooltip: {
            title: 'tooltip-title',
            content: 'tooltip-content',
          },
        },
        lead_source_survey_partner: {
          title: 'Partner',
        },
      },
      tv_survey: {
        tv_de_partner_consent: {
          title: 'Bambus Teilverkaufspartner',
          checkbox:
            'Ich bin damit einverstanden, dass Bambus meine Daten an seine Teilverkaufspartner sendet',
          options: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        lead_source_survey: {
          title: 'Wie haben Sie von Bambus erfahren?',
          options: {
            recommendation: 'Empfehlung',
            press: 'Presse',
            radio: 'Radio',
            tv: 'TV',
            facebook: 'Facebook',
            google: 'Google',
            mail: 'Post',
            partner: 'Partner',
            not_specified: 'Keine Angabe',
          },
        },
        recommended_by: {
          title: 'Von wem haben Sie die Empfehlung?',
        },
        lead_source_survey_partner: {
          title: 'Partner',
        },
      },
      thank_you: {
        pagetitle: 'Vielen Dank',
        description:
          'Ihre Angaben werden von unseren Finanzierungsexperten überprüft. Einer unserer Berater wird Sie innerhalb von maximal zwei Werktagen kontaktieren.',
      },
      schufa_end: {
        pagetitle: 'Vielen Dank für Ihren Antrag.',
        description:
          'Aufgrund Ihrer negativen Schufa benötigen wir einige Tage Bearbeitungszeit um Ihre Optionen zu prüfen.',
        alternatives:
          'Ein Finanzierungsexperte wird sich in den kommenden Tagen mit Ihnen in Verbindung setzen.',
      },
    },
    welcome: {
      start: {
        propertyLocatedInGermany:
          'Ihre Immobilie befindet sich in Deutschland.',
        propertyLocatedInAustria: 'Ihre Immobilie befindet sich in Österreich.',
        pagetitle: 'Es kann losgehen!',
        description:
          'Ihr kostenloses & unverbindliches Angebot ist zum Greifen nahe.',
        data_required:
          'Die folgenden Fragen zu Ihrem Finanzierungsvorhaben ermöglichen eine erste Machbarkeitszusage bei den Banken.',
        duration: 'Bitte nehmen Sie sich ca. 10-15 Minuten Zeit.',
        valuation: 'Ihre Immobilie hat einen Schätzwert von ',
      },
      tv_start: {
        propertyLocatedInGermany:
          'Ihre Immobilie befindet sich in Deutschland.',
        propertyLocatedInAustria: 'Ihre Immobilie befindet sich in Österreich.',
        pagetitle: 'Entdecken Sie mit dem Teilverkauf neue Möglichkeiten!',
        description:
          'Ihr kostenloses & unverbindliches Angebot ist zum Greifen nahe.',
        data_required:
          'Die folgenden Fragen zu Ihrem Finanzierungsvorhaben ermöglicht es Ihrem Berater, Ihnen eine umfassende Ersteinschätzung zu Ihrem Vorhaben zu geben.',
        duration: 'Bitte nehmen Sie sich ca. 10-15 Minuten Zeit.',
      },
    },
    no_more_info_needed: {
      pagetitle: 'Danke wir haben schon alles!',
      description:
        'Wir benötigen keine Infos mehr! Ihr Berater wird Sie in Kürze kontaktieren.',
    },
  },
  chooseCountryModal: {
    header: 'Den Standort Ihrer Immobilie ändern.',
    descriptionDimensionAT:
      'Sie befinden sich auf der Antragsstrecke für Immobilien mit dem Standort in Österreich. Wenn sich Ihre Immobilie in Deutschland befindet, müssen Sie zur Antragsstrecke für Immobilien mit dem Standort in Deutschland wechseln.',
    descriptionDimensionDE:
      'Sie befinden sich auf der Antragsstrecke für Immobilien mit dem Standort in Deutschland. Wenn sich Ihre Immobilie in Österreich befindet, müssen Sie zur Antragsstrecke für Immobilien mit dem Standort in Österreich wechseln.',
    keep_location: 'Standort beibehalten',
    switch_location: 'Standort wechseln',
  },
  restartOnboardingPrompt: {
    header: 'Willkommen zurück',
    paragraph1:
      'Ihr Antrag ist schon seit einiger Zeit inaktiv, da sich einige Angaben mit der Zeit ändern, können Sie gerne den Antrag zurücksetzen und neu starten.',
    warning: 'Achtung: ',
    warningParagraph:
      'Zu Ihrer Sicherheit werden die bisherigen Daten überschrieben und auch für unser Finanzierungsteam nicht mehr abrufbar sein.',
    buttonRestart: 'Antrag neu starten',
    buttonContinue: 'Bisherigen Antrag fortsetzen',
  },
  manualRestartOnboardingModal: {
    header: 'Sie möchten Ihren Antrag neu starten?',
    paragraph:
      'Wir werden Ihre Daten zurücksetzen. Ihre bisherigen Angaben werden danach auch nicht für unser Finanzierungsteam mehr abrufbar sein.',
    buttonRestart: 'Fortfahren',
    buttonCancel: 'Abbrechen',
  },
  supportModal: {
    header: 'Benötigen Sie Hilfe?',
    paragraph:
      'Wir können Sie unterstützen! Geben Sie Ihre Telefonnummer ein und wir rufen Sie innerhalb von 24 Stunden an:',
    buttonRequestSupport: 'Rückruf vereinbaren',
    buttonCancel: 'Nein, danke',
  },
  progress_indicator: {
    time_remaining_pre_text: 'noch ca.',
    time_remaining_post_text_1_minute: 'Minute',
    time_remaining_post_text_more_than_one_minutes: 'Minuten',
  },
};
