import { FormSteps } from './form';

const routes: { [index: string]: { [index: string]: string } } = {
  registration: {
    renew: '/registration/renew/',
    token: '/registration/validate/:token',
  },
  form: {
    index: '/form/',
    [FormSteps.Start]: '/form/start',
    [FormSteps.TVStart]: '/form/tv_start',
    [FormSteps.ApplicationIntro]: '/form/application/intro',
    [FormSteps.ApplicationPurpose]: '/form/application/purpose',
    [FormSteps.ApplicationPreferences]: '/form/application/preferences',
    [FormSteps.ApplicationDetails]: '/form/application/details',
    [FormSteps.ApplicationApplicants]: '/form/application/applicants',
    [FormSteps.ApplicationEquityRelease]: '/form/application/equity_release',
    [FormSteps.ApplicationNewBuild]: '/form/application/new_build',
    [FormSteps.ApplicationReview]: '/form/application/review',
    [FormSteps.TVApplicationIntro]: '/form/tv_application/intro',
    [FormSteps.TVApplicationPreferences]: '/form/tv_application/preferences',
    [FormSteps.TVApplicationOptions]: '/form/tv_application/options',
    [FormSteps.TVApplicationQuestions]: '/form/tv_application/questions',
    [FormSteps.TVApplicationReview]: '/form/tv_application/review',
    [FormSteps.TVPropertyIntro]: '/form/tv_property/intro',
    [FormSteps.TVPropertyDetails]: '/form/tv_property/details',
    [FormSteps.TVPropertyAddress]: '/form/tv_property/address',
    [FormSteps.TVPropertyUsage]: '/form/tv_property/usage',
    [FormSteps.TVPropertyAdditionalDetails]:
      '/form/tv_property/additional_details',
    [FormSteps.TVPropertyParking]: '/form/tv_property/parking',
    [FormSteps.TVPropertyUserValuation]: '/form/tv_property/user_valuation',
    [FormSteps.TVPropertyRestrictions]: '/form/tv_property/restrictions',
    [FormSteps.TVPropertyLoan]: '/form/tv_property/property_loan',
    [FormSteps.TVPropertyOwnership]: '/form/tv_property/ownership',
    [FormSteps.TVPropertyReview]: '/form/tv_property/review',
    [FormSteps.MainPropertyIntro]: '/form/property/intro',
    [FormSteps.MainPropertyDetails]: '/form/property/details',
    [FormSteps.MainPropertyAddress]: '/form/property/address',
    [FormSteps.MainPropertyUsage]: '/form/property/usage',
    [FormSteps.MainPropertyRented]: '/form/property/rented',
    [FormSteps.MainPropertyValuation]: '/form/property/user_valuation',
    [FormSteps.MainPropertyRestrictions]: '/form/property/restrictions',
    [FormSteps.MainPropertyLeasehold]: '/form/property/leasehold',
    [FormSteps.MainPropertyLandRegister]: '/form/property/land_register',
    [FormSteps.MainPropertyConstruction]: '/form/property/construction',
    [FormSteps.MainPropertyHouse]: '/form/property/house_details',
    [FormSteps.MainPropertyApartment1]: '/form/property/apartment_details_1',
    [FormSteps.MainPropertyApartment2]: '/form/property/apartment_details_2',
    [FormSteps.MainPropertyBuildings]: '/form/property/buildings_details',
    [FormSteps.MainPropertyBuildingsUnits]: '/form/property/buildings_units',
    [FormSteps.MainPropertyUnitsResidential]:
      '/form/property/units_residential',
    [FormSteps.MainPropertyUnitsCommercial]: '/form/property/units_commercial',
    [FormSteps.MainPropertyBathroom]: '/form/property/bathroom',
    [FormSteps.MainPropertyFacilities]: '/form/property/facilities',
    [FormSteps.MainPropertyReview]: '/form/property/review',
    [FormSteps.PropertyDebtIntro]: '/form/property_debt/intro',
    [FormSteps.PropertyDebtOverview]: '/form/property_debt/overview',
    [FormSteps.PropertyDebtMortgage1]: '/form/property_debt/mortgage_1',
    [FormSteps.PropertyDebtMortgage2]: '/form/property_debt/mortgage_2',
    [FormSteps.PropertyDebtConstructionLoan]:
      '/form/property_debt/construction_loan',
    [FormSteps.PropertyDebtReview]: '/form/property_debt/Review',
    [FormSteps.RealEstateIntro]: '/form/real_estate/intro',
    [FormSteps.RealEstateNumber]: '/form/real_estate/number',
    [FormSteps.RealEstateAddress1]: '/form/real_estate/address/1',
    [FormSteps.RealEstateDetails1]: '/form/real_estate/details/1',
    [FormSteps.RealEstateUsage1]: '/form/real_estate/usage/1',
    [FormSteps.RealEstateLoan1]: '/form/real_estate/loan/1',
    [FormSteps.RealEstateAddress2]: '/form/real_estate/address/2',
    [FormSteps.RealEstateDetails2]: '/form/real_estate/details/2',
    [FormSteps.RealEstateUsage2]: '/form/real_estate/usage/2',
    [FormSteps.RealEstateLoan2]: '/form/real_estate/loan/2',
    [FormSteps.RealEstateAddress3]: '/form/real_estate/address/3',
    [FormSteps.RealEstateDetails3]: '/form/real_estate/details/3',
    [FormSteps.RealEstateUsage3]: '/form/real_estate/usage/3',
    [FormSteps.RealEstateLoan3]: '/form/real_estate/loan/3',
    [FormSteps.RealEstateReview]: '/form/real_estate/review',
    [FormSteps.MainApplicantIntro]: '/form/main_applicant/intro',
    [FormSteps.MainApplicantName]: '/form/main_applicant/name',
    [FormSteps.MainApplicantContact]: '/form/main_applicant/contact_details',
    [FormSteps.MainApplicantPersonal]: '/form/main_applicant/personal',
    [FormSteps.MainApplicantTaxes]: '/form/main_applicant/taxes',
    [FormSteps.MainApplicantFamily]: '/form/main_applicant/family',
    [FormSteps.MainApplicantChildren]: '/form/main_applicant/children',
    [FormSteps.MainApplicantResidenceStatus]:
      '/form/main_applicant/residence_status',
    [FormSteps.MainApplicantResidenceStatusAT]:
      '/form/main_applicant/residence_status_at',
    [FormSteps.MainApplicantResidenceAddress]:
      '/form/main_applicant/residence_address',
    [FormSteps.MainApplicantEmployment]: '/form/main_applicant/employment',
    [FormSteps.MainApplicantSelfEmployed]: '/form/main_applicant/self_employed',
    [FormSteps.MainApplicantEmploymentTemporary]:
      '/form/main_applicant/employment_temporary',
    [FormSteps.MainApplicantEmploymentTrial]:
      '/form/main_applicant/employment_trial',
    [FormSteps.MainApplicantIncome]: '/form/main_applicant/income',
    [FormSteps.MainApplicantAdditionalIncome]:
      '/form/main_applicant/additional_incomes',
    [FormSteps.MainApplicantMonthlyCosts]: '/form/main_applicant/monthly_costs',
    [FormSteps.MainApplicantPension]: '/form/main_applicant/pension',
    [FormSteps.MainApplicantInsurance]: '/form/main_applicant/insurance',
    [FormSteps.MainApplicantInsuranceAdditional]:
      '/form/main_applicant/insurance_additional',
    [FormSteps.MainApplicantCars]: '/form/main_applicant/cars',
    [FormSteps.MainApplicantLeasing]: '/form/main_applicant/leasing',
    [FormSteps.MainApplicantLoans]: '/form/main_applicant/private_loans',
    [FormSteps.MainApplicantLoan1]: '/form/main_applicant/loan/1',
    [FormSteps.MainApplicantLoan2]: '/form/main_applicant/loan/2',
    [FormSteps.MainApplicantLoan3]: '/form/main_applicant/loan/3',
    [FormSteps.MainApplicantAssets]: '/form/main_applicant/assets',
    [FormSteps.MainApplicantReview]: '/form/main_applicant/review',
    [FormSteps.TVMainApplicantIntro]: '/form/tv_main_applicant/intro',
    [FormSteps.TVMainApplicantName]: '/form/tv_main_applicant/name',
    [FormSteps.TVMainApplicantContactDetails]:
      '/form/tv_main_applicant/contact_details',
    [FormSteps.TVMainApplicantDetails]: '/form/tv_main_applicant/details',
    [FormSteps.TVMainApplicantEmployment]: '/form/tv_main_applicant/employment',
    [FormSteps.TVMainApplicantResidenceAddress]:
      '/form/tv_main_applicant/residence_address',
    [FormSteps.TVMainApplicantReview]: '/form/tv_main_applicant/review',
    [FormSteps.Applicant2Intro]: '/form/applicant_2/intro',
    [FormSteps.Applicant2Name]: '/form/applicant_2/name',
    [FormSteps.Applicant2Contact]: '/form/applicant_2/contact_details',
    [FormSteps.Applicant2Personal]: '/form/applicant_2/personal',
    [FormSteps.Applicant2Taxes]: '/form/applicant_2/taxes',
    [FormSteps.Applicant2Family]: '/form/applicant_2/family',
    [FormSteps.Applicant2Children]: '/form/applicant_2/children',
    [FormSteps.Applicant2ResidenceStatus]: '/form/applicant_2/residence_status',
    [FormSteps.Applicant2ResidenceStatusAT]:
      '/form/applicant_2/residence_status_at',
    [FormSteps.Applicant2ResidenceAddress]:
      '/form/applicant_2/residence_address',
    [FormSteps.Applicant2Employment]: '/form/applicant_2/employment',
    [FormSteps.Applicant2SelfEmployed]: '/form/applicant_2/self_employed',
    [FormSteps.Applicant2EmploymentTemporary]:
      '/form/applicant_2/employment_temporary',
    [FormSteps.Applicant2EmploymentTrial]: '/form/applicant_2/employment_trial',
    [FormSteps.Applicant2Income]: '/form/applicant_2/income',
    [FormSteps.Applicant2AdditionalIncome]:
      '/form/applicant_2/additional_incomes',
    [FormSteps.Applicant2MonthlyCosts]: '/form/applicant_2/monthly_costs',
    [FormSteps.Applicant2Pension]: '/form/applicant_2/pension',
    [FormSteps.Applicant2Insurance]: '/form/applicant_2/insurance',
    [FormSteps.Applicant2InsuranceAdditional]:
      '/form/applicant_2/insurance_additional',
    [FormSteps.Applicant2Cars]: '/form/applicant_2/cars',
    [FormSteps.Applicant2Leasing]: '/form/applicant_2/leasing',
    [FormSteps.Applicant2Loans]: '/form/applicant_2/private_loans',
    [FormSteps.Applicant2Loan1]: '/form/applicant_2/loan/1',
    [FormSteps.Applicant2Loan2]: '/form/applicant_2/loan/2',
    [FormSteps.Applicant2Loan3]: '/form/applicant_2/loan/3',
    [FormSteps.Applicant2Assets]: '/form/applicant_2/assets',
    [FormSteps.Applicant2Review]: '/form/applicant_2/review',
    [FormSteps.NewPropertyIntro]: '/form/new_property/intro',
    [FormSteps.NewPropertyStatus]: '/form/new_property/status',
    [FormSteps.NewPropertyDetails]: '/form/new_property/details',
    [FormSteps.NewPropertyAddress]: '/form/new_property/address',
    [FormSteps.NewPropertyUsage]: '/form/new_property/usage',
    [FormSteps.NewPropertyRented]: '/form/new_property/rented',
    [FormSteps.NewPropertyRestrictions]: '/form/new_property/restrictions',
    [FormSteps.NewPropertyLandRegister]: '/form/new_property/land_register',
    [FormSteps.NewPropertyConstruction]: '/form/new_property/construction',
    [FormSteps.NewPropertyHouseDetails]: '/form/new_property/house_details',
    [FormSteps.NewPropertyApartment1]: '/form/new_property/apartment_details_1',
    [FormSteps.NewPropertyApartment2]: '/form/new_property/apartment_details_2',
    [FormSteps.NewPropertyBuildings]: '/form/new_property/buildings_details',
    [FormSteps.NewPropertyValuation]: '/form/new_property/valuation_purchase',
    [FormSteps.NewPropertyValuationConstruction]:
      '/form/new_property/valuation_construction',
    [FormSteps.NewPropertyReview]: '/form/new_property/review',
    [FormSteps.NoPermission]: '/form/no_permission',
    [FormSteps.NoStepSuppliedFromServer]: '/form/no_step_supplied_from_server',
    [FormSteps.Survey]: '/form/survey',
    [FormSteps.TVSurvey]: '/form/tv_survey',
    [FormSteps.ThankYouEnd]: '/form/thank_you',
    [FormSteps.SchufaEnd]: '/form/negative_schufa_exit',
    __END_OF_WORKFLOW__: '/form/__END_OF_WORKFLOW__',
  },
  error: {
    NotFoundPage: '/404',
  },
};

export default routes;
