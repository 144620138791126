import { useState, useEffect, useRef } from 'react';
import endpoints from 'consts/endpoints';
import { FormSteps } from 'consts/form';
import { request } from 'utils/api';

const TWO_MINUTES_IN_MS = 120000;

export interface IUseSupportModal {
  currentStep: string | undefined;
}

/**
 *
 * Automatically opens a modal asking whether the user needs help
 * if some time without a form step submission has passed.
 */
const useSupportModal = ({ currentStep }: IUseSupportModal) => {
  const [isAutomaticSupportModalVisible, setIsAutomaticSupportModalVisible] =
    useState<boolean>(false);

  const automaticSupportModalTimerRef = useRef<number | null>(null);

  const initializeAutomaticSupportModalTimer = () => {
    // If the modal is visible -> close it
    setIsAutomaticSupportModalVisible(false);

    //Clear the current timer if any and reinitialize
    automaticSupportModalTimerRef.current &&
      window.clearTimeout(automaticSupportModalTimerRef.current);

    automaticSupportModalTimerRef.current = window.setTimeout(() => {
      // if the timeout passes show the support modal
      setIsAutomaticSupportModalVisible(true);
    }, TWO_MINUTES_IN_MS);
  };

  useEffect(() => {
    initializeAutomaticSupportModalTimer();

    // clear the timer on unmount
    return () => {
      automaticSupportModalTimerRef.current &&
        window.clearTimeout(automaticSupportModalTimerRef.current);
    };
  }, []);

  // If we're at the end, stop the timer as we don't need the support modal anymore
  useEffect(() => {
    if (currentStep === FormSteps.ThankYouEnd) {
      // Clear the current timer if any
      automaticSupportModalTimerRef.current &&
        window.clearTimeout(automaticSupportModalTimerRef.current);
    }
  }, [currentStep]);

  // On each and every form step submit we reinitialize the timer
  const signalSubmitForAutomaticSupportModal = () => {
    initializeAutomaticSupportModalTimer();
  };

  const requestCallback = async (phoneNumber: string) => {
    try {
      await request({
        path: endpoints.requestCallback,
        method: 'POST',
        body: {
          request_location: window.location.href,
          phone_number: phoneNumber,
        },
      });
    } catch {
      // Fail graciously... can't really do anything at this point!
    } finally {
      setIsAutomaticSupportModalVisible(false);
      initializeAutomaticSupportModalTimer();
    }
  };

  return {
    isAutomaticSupportModalVisible,
    signalSubmitForAutomaticSupportModal,
    requestCallback,
    initializeAutomaticSupportModalTimer,
    setIsAutomaticSupportModalVisible,
  };
};

export default useSupportModal;
