import React, { useEffect } from 'react';
import { Router as ReactDomRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as BambusUIComponentsThemeProvider } from 'bambus-ui-components';
import { BaseCSS, ThemeProps, GridThemeProvider } from 'styled-bootstrap-grid';
import Cookies from 'js-cookie';
import GlobalStyles from 'components/GlobalStyles';
import Router from './Router';
import theme from './theme';
import history from './RoutingHistory';
import MatomoPageViewAnalytics from 'components/Tracking/MatomoPageViewAnalytics';
import LocaleContextProvider from 'contexts/LocaleContextProvider';
import I18nProvider from 'contexts/I18nProvider';
import IsMobileContextProvider from 'contexts/IsMobileContextProvider';
import ProgressContextProvider from 'contexts/ProgressContextProvider';
import ExitModalContextProvider from 'contexts/ExitModalContextProvider';
import PropertyContextProvider from 'contexts/PropertyContextProvider';
import SectionsContextProvider from 'contexts/SectionsContextProvider';

const gridTheme = {
  container: {
    padding: 20,
    maxWidth: {
      xl: 1160,
    },
  },
};

const TypedGridThemeProvider = GridThemeProvider as React.FC<ThemeProps>;

const App: React.FC = () => {
  useEffect(() => {
    // As we are using domain wide cookie consent and the Matomo cookies
    // are not shared, we have to check whether we should notify
    // Matomo that we do have a consent available from before (not by clicking on the "Accept Cookies" on
    // Onboarding) - this will in turn create the necessary Matomo cookies if there aren't any yet
    const alreadyExistingTrackingConsent = Cookies.get('bmb_notrack');
    const alreadyExistingTrackingLevel = Cookies.get('bmb_tracklevel');
    const alreadyExistingMatomoTrackingCookie =
      Cookies.get('mtm_cookie_consent');
    if (
      alreadyExistingTrackingConsent === 'false' &&
      alreadyExistingTrackingLevel === 'all' &&
      !alreadyExistingMatomoTrackingCookie
    ) {
      (window as any)._paq &&
        (window as any)._paq.push(['rememberCookieConsentGiven']);
    }
  }, []);

  return (
    <LocaleContextProvider>
      <I18nProvider>
        <IsMobileContextProvider>
          <ProgressContextProvider>
            <ExitModalContextProvider>
              <PropertyContextProvider>
                <SectionsContextProvider>
                  <ThemeProvider theme={theme}>
                    <BambusUIComponentsThemeProvider>
                      <TypedGridThemeProvider gridTheme={gridTheme}>
                        <React.Fragment>
                          <BaseCSS />
                          <GlobalStyles />
                          <MatomoPageViewAnalytics />
                          <ReactDomRouter history={history}>
                            <Router />
                          </ReactDomRouter>
                        </React.Fragment>
                      </TypedGridThemeProvider>
                    </BambusUIComponentsThemeProvider>
                  </ThemeProvider>
                </SectionsContextProvider>
              </PropertyContextProvider>
            </ExitModalContextProvider>
          </ProgressContextProvider>
        </IsMobileContextProvider>
      </I18nProvider>
    </LocaleContextProvider>
  );
};

export default App;
