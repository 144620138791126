import { createContext } from 'react';

const LocaleContext = createContext<{
  locale: string;
  setLocale: (newLocale: string) => void;
}>({
  locale: 'de-de',
  setLocale: () => {},
});

export default LocaleContext;
