import { createContext } from 'react';
import { ProgressElement } from 'models/Progress';

export type ProgressContextContentType = {
  progress: ProgressElement[];
  setProgress: React.Dispatch<React.SetStateAction<ProgressElement[]>>;
};

const ProgressContext = createContext<ProgressContextContentType>({
  progress: [],
  setProgress: () => [],
});

export default ProgressContext;
