import React from 'react';

export interface ISectionsContextData {
  skipped: string[] | undefined | null;
  completed: string[] | undefined | null;
  future: string[] | undefined | null;
  current: string | undefined | null;
}
const SectionsContext = React.createContext<{
  sectionsData: ISectionsContextData;
  setSectionsData: React.Dispatch<React.SetStateAction<ISectionsContextData>>;
}>({
  sectionsData: {
    skipped: null,
    completed: null,
    future: null,
    current: null,
  },
  setSectionsData: () => {},
});

export default SectionsContext;
