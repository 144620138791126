import dayjs from 'dayjs';
import { VariableData } from 'pages/Forms/components/ReviewBox';

export const isTouch = () => 'ontouchstart' in document.documentElement;

// The following are used to and from german number format (string-number)
export const moneyFormat = (value: string) =>
  Number(value).toLocaleString('de');
export const formatGermanNumber = (str: string) =>
  Number(str.replace(/\./g, '').replace(',', '.'));

export const localizeNumber = (value?: number | null, handleZero?: boolean) => {
  if (!!value || (value !== undefined && handleZero === true)) {
    return value!.toLocaleString('de', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
};

export const localizeNumberPropertyValue = (
  value?: number,
  handleZero?: boolean
) => {
  if (!!value || (value !== undefined && handleZero === true)) {
    return value.toLocaleString('de', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }
};

export const localizeNumberSurfaceArea = (
  value?: number,
  handleZero?: boolean
) => {
  if (!!value || (value !== undefined && handleZero === true)) {
    return value.toLocaleString('de', {
      // minimumFractionDigits: 0,
      maximumFractionDigits: 3,
    });
  }
};

// For formatting phone number to include country code
export const formatPhoneNumber = (areaCode: string, number: string) =>
  `${areaCode} ${number.replace(/[\s-]+/g, '')}`;

// split existing phone number into area code and phone number components
export const splitPhoneNumber = (
  phoneNumber?: string,
  defaultAreaCode: string = '+49'
) => {
  if (phoneNumber) {
    const [area_code, ...number] = phoneNumber.split(' ');
    if (area_code[0] === '+') {
      return { area_code, number: number.join('') };
    } else {
      return { area_code: defaultAreaCode, number: phoneNumber };
    }
  }
  return { area_code: defaultAreaCode, number: undefined };
};

// Convert date from YYYY-MM-DD to DD.MM.YYYY format
export const dateConverter = (value?: string | null) => {
  if (!!value) {
    const [y, m, d] = value!.split('-');
    return d.concat('.', m, '.', y);
  }
};

/**
 * Functions for validating dates either in the past or in the future
 */
/* <------------ start section ------------> */

// The date value has to be of ISO 8601 format i.e. "yyyy-mm-ddT.." ex: 2018-04-04T16:00:00.000Z
export const pastDateValidator = (value: string | undefined) => {
  if (!value) return false;
  const [y, m, d] = value.split('-');
  const date = new Date(Number(y), Number(m) - 1, Number(d), 0, 0, 0, 0);
  return (
    !Number.isNaN(date.getTime()) &&
    date.getDate() === Number(d) &&
    date.getMonth() === Number(m) - 1 &&
    (date.getFullYear() < dayjs().year() ||
      (date.getFullYear() === dayjs().year() && dayjs(value).diff(dayjs()) < 0))
  );
};

// The date value has to be of ISO 8601 format i.e. "yyyy-mm-ddT.." ex: 2018-04-04T16:00:00.000Z
export const optionallyPresentPastDateValidator = (
  value: string | undefined | null
) => {
  if (!value) return true;
  if (value === '--') return true;
  const [y, m, d] = value.split('-');
  const date = new Date(Number(y), Number(m) - 1, Number(d), 0, 0, 0, 0);
  return (
    !Number.isNaN(date.getTime()) &&
    date.getDate() === Number(d) &&
    date.getMonth() === Number(m) - 1 &&
    (date.getFullYear() < dayjs().year() ||
      (date.getFullYear() === dayjs().year() && dayjs(value).diff(dayjs()) < 0))
  );
};

// The date value has to be of ISO 8601 format i.e. "yyyy-mm-ddT.." ex: 2018-04-04T16:00:00.000Z
export const futureDateValidator = (value: string | undefined) => {
  if (!value) return false;
  const [y, m, d] = value.split('-');
  const date = new Date(Number(y), Number(m) - 1, Number(d), 0, 0, 0, 0);
  return (
    !Number.isNaN(date.getTime()) &&
    date.getDate() === Number(d) &&
    date.getMonth() === Number(m) - 1 &&
    (date.getFullYear() > dayjs().year() ||
      (date.getFullYear() === dayjs().year() && dayjs(value).diff(dayjs()) > 0))
  );
};

// The date value has to be of ISO 8601 format i.e. "yyyy-mm-ddT.." ex: 2018-04-04T16:00:00.000Z
export const futureDateValidatorEmptyAllowed = (value: string | undefined) => {
  if (!value || value === '--') return true;
  const [y, m, d] = value.split('-');
  const date = new Date(Number(y), Number(m) - 1, Number(d), 0, 0, 0, 0);
  return (
    !Number.isNaN(date.getTime()) &&
    date.getDate() === Number(d) &&
    date.getMonth() === Number(m) - 1 &&
    (date.getFullYear() > dayjs().year() ||
      (date.getFullYear() === dayjs().year() && dayjs(value).diff(dayjs()) > 0))
  );
};

/* <------------ end section ------------> */

// The following functions are used to search for a particular instance of unit/loan/address/income in the provided array (taken from returned user data json from GET details call )
export const getProperty = (
  propertyNumber: number,
  arrayLocation: any[] | undefined
) => {
  try {
    const currentProperty = arrayLocation?.filter(
      (property) => property.additional_property_number === propertyNumber
    );
    if (currentProperty) return currentProperty[0];
  } catch (e) {
    return undefined;
  }
};
export const residentialUnit = (arrayLocation: any[] | undefined) => {
  try {
    const currentUnit = arrayLocation?.filter(
      (unit) => unit.unit_category === 'residential'
    );
    if (currentUnit) return currentUnit[0];
  } catch (e) {
    return undefined;
  }
};
export const commercialUnit = (arrayLocation: any[] | undefined) => {
  try {
    const currentUnit = arrayLocation?.filter(
      (unit) => unit.unit_category === 'commercial'
    );
    if (currentUnit) return currentUnit[0];
  } catch (e) {
    return undefined;
  }
};
export const getPropertyLoan = (
  propertyNumber: number,
  arrayProperties: any[] | undefined,
  arrayLoans: any[] | undefined
) => {
  try {
    const currentProperty = getProperty(propertyNumber, arrayProperties);
    const currentLoan = arrayLoans?.filter(
      (loan) => loan.associated_property === currentProperty.id
    );
    if (currentLoan) return currentLoan[0];
  } catch (e) {
    return undefined;
  }
};
export const getPrivateLoan = (
  loanNumber: number,
  arrayLocation: any[] | undefined
) => {
  try {
    const currentUnit = arrayLocation?.filter(
      (loan) => loan.loan_number === loanNumber
    );
    if (currentUnit) return currentUnit[0];
  } catch (e) {
    return undefined;
  }
};
export const getAddress = (
  propertyNumber: number,
  arrayProperties: any[] | undefined,
  arrayAddress: any[] | undefined
) => {
  try {
    const currentProperty = getProperty(propertyNumber, arrayProperties);
    const currentAddress = arrayAddress?.filter(
      (address) => address.associated_property === currentProperty.id
    );
    if (currentAddress) return currentAddress[0];
  } catch (e) {
    return undefined;
  }
};
export const getAdditionalIncome = (
  incomeNumber: number,
  arrayLocation: any[] | undefined
) => {
  try {
    const currentIncome = arrayLocation?.filter(
      (income) => income.income_number === incomeNumber
    );
    if (currentIncome) return currentIncome[0];
  } catch (e) {
    return undefined;
  }
};

export const getSubset = (obj: object, arrayOfKeys: string[]) =>
  Object.keys(obj)
    .filter((key) => arrayOfKeys.indexOf(key) >= 0)
    .reduce((obj2, key) => Object.assign(obj2, { [key]: obj[key] }), {});

export const getSubsetOfReviewVariables = (
  variables: VariableData[],
  variableNamesToKeep: string[] | undefined
): VariableData[] => {
  return variableNamesToKeep !== undefined
    ? variables.filter((variable) =>
        variableNamesToKeep.includes(variable.name)
      )
    : variables;
};

export const hasValue = (anyObject: any) => {
  return anyObject !== undefined && anyObject !== null;
};

export const isNullOrUndefined = (anyObject: any) => {
  return anyObject === undefined || anyObject === null;
};
