import { theme } from 'bambus-ui-components';
import { DefaultTheme } from 'styled-components';
import { StyledBootstrapGrid } from 'styled-bootstrap-grid';

export interface ThemeWithGrid extends DefaultTheme {
  styledBootstrapGrid: StyledBootstrapGrid;
}

export type WithTheme<Props> = Props & {
  theme: ThemeWithGrid;
};

const spreadTheme = {
  ...theme,
};

export default spreadTheme;
