import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, Paragraph } from 'bambus-ui-components';
import { media } from 'styled-bootstrap-grid';

type ManualRestartOnboardingModalProps = {
  isModalVisible: boolean;
  onCloseModal: () => any;
  onCancel: () => any;
  onRestartOnboarding: () => Promise<any>;
};

const StyledWrapper = styled(Modal.Wrapper)`
  min-width: 300px;
  ${media.lg`
    min-width: 750px;
  `}
`;

const ButtonsWrapper = styled.div`
  // emulating gap
  --gap: 20px;
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  // this needs 2x specificity thus the 2 "&" as we're manipulating the Button components
  && > * {
    margin: var(--gap) 0 0 var(--gap);
  }

  & > * {
    flex: 1;
  }

  ${media.lg`
    & > * {
      flex: 0;
    }
  `}
`;

const ManualRestartOnboardingModal = ({
  isModalVisible,
  onCloseModal,
  onCancel,
  onRestartOnboarding: onRestartOnboardingProp,
}: ManualRestartOnboardingModalProps) => {
  const [isRestartInProgress, setIsRestartInProgress] = useState(false);

  const onRestartOnboarding = async () => {
    setIsRestartInProgress(true);
    await onRestartOnboardingProp();
    setIsRestartInProgress(false);
  };

  return (
    <StyledWrapper
      isOpen={isModalVisible}
      style={{
        overlay: {
          zIndex: 10,
        },
      }}
    >
      <Modal.CloseButton onClick={onCloseModal} />
      <Modal.Content>
        <Modal.Header tag="h4" size={3}>
          <FormattedMessage id="manualRestartOnboardingModal.header" />
        </Modal.Header>
        <Paragraph large>
          <FormattedMessage id="manualRestartOnboardingModal.paragraph" />
        </Paragraph>
        <ButtonsWrapper>
          <Button outline onClick={onCancel} disabled={isRestartInProgress}>
            <FormattedMessage id="manualRestartOnboardingModal.buttonCancel" />
          </Button>
          <Button
            muted
            onClick={onRestartOnboarding}
            disabled={isRestartInProgress}
          >
            <FormattedMessage id="manualRestartOnboardingModal.buttonRestart" />
          </Button>
        </ButtonsWrapper>
      </Modal.Content>
    </StyledWrapper>
  );
};

export default ManualRestartOnboardingModal;
