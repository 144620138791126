import React, { useContext } from 'react';
import styled from 'styled-components';
import { media, Container } from 'styled-bootstrap-grid';
import { CookieConsent, VerticalSpacer } from 'bambus-ui-components';
import { ReactComponent as BambusLogo } from 'assets/BambusBlueLogoWithName.svg';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { HEADER_HEIGHT_PX } from 'consts/layout';
import IsMobileContext from 'contexts/IsMobileContext';

const Wrapper = styled.div`
  min-height: 100vh;
  background: ${({ theme }) => theme.background};

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'content'
    'footer';

  ${media.lg`
    grid-column-gap: 20px;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'aside header'
      'aside content'
      'footer footer';
  `}
`;

const Content = styled.div`
  grid-area: content;

  min-height: calc(100vh - ${HEADER_HEIGHT_PX}px);

  ${media.md`
    min-height: unset;
  `}

  ${media.lg`
    margin: 15px 0;
    flex-grow: 1;
  `}
`;

const Aside = styled.div`
  padding: 40px 60px 0px;
  min-width: 387px;
  background-color: ${({ theme }) => theme.colors.bambusBlue2dot5};

  display: none;

  ${media.lg`
    display: block;
  `}
`;

const LargeSizeDown = styled.div`
  ${media.lg`
    display: none;
  `}
`;

const MobileProgressIndicatorWrapper = styled.div`
  padding: 10px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.bambusBlue2dot5};

  ${media.lg`
    display: none;
  `}
`;

const BackButtonWrapper = styled.div``;

const StickyWrapper = styled.div`
  position: sticky;
  top: 30px;
`;

interface IPage {
  isPropertyInfoVisible?: boolean;
  progressIndicator?: React.ReactNode;
  backButton?: React.ReactNode;
}

const Page = ({
  children,
  isPropertyInfoVisible,
  progressIndicator,
  backButton,
}: React.PropsWithChildren<IPage>) => {
  const isMobile = useContext(IsMobileContext);

  return (
    <Wrapper>
      {progressIndicator && (
        <Aside style={{ gridArea: 'aside' }}>
          <StickyWrapper>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bambus.io"
            >
              <BambusLogo
                style={{
                  transform: 'scale(1.3) translateX(10px)',
                }}
              />
            </a>
            <VerticalSpacer space="45px" />
            {progressIndicator}
          </StickyWrapper>
        </Aside>
      )}

      <Header gridArea="header" showPropertyInfo={isPropertyInfoVisible} />
      <Content style={{ gridArea: 'content' }}>
        <Container>
          {backButton && <BackButtonWrapper>{backButton}</BackButtonWrapper>}
          <LargeSizeDown>
            <VerticalSpacer space="20px" />
          </LargeSizeDown>
          {progressIndicator && (
            <MobileProgressIndicatorWrapper>
              {progressIndicator}
            </MobileProgressIndicatorWrapper>
          )}
          {children}
        </Container>
      </Content>
      <div style={{ gridArea: 'footer' }}>
        <Footer />
      </div>
      <CookieConsent
        isMobile={isMobile}
        cookieDomain={process.env.REACT_APP_CONSENT_COOKIE_DOMAIN}
        onAccept={() => {
          (window as any)._paq &&
            (window as any)._paq.push(['rememberCookieConsentGiven']);
          window.location.reload();
        }}
        onPartialAccept={() => {
          window.location.reload();
        }}
        onReject={() => {
          (window as any)._paq &&
            (window as any)._paq.push(['forgetCookieConsentGiven']);
          window.location.reload();
        }}
      />
    </Wrapper>
  );
};

export default Page;
