import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Home, Maximize, MapPin } from 'react-feather';
import { localizeNumberPropertyValue } from 'utils/utils';
import { Database } from 'react-feather';
import { useContext } from 'react';
import PropertyContext from 'contexts/PropertyContext';

const PropertyValue = styled.div`
  margin: 0 20px 0 10px;
  font-size: ${({ theme }) => theme.fsMedium};
  white-space: nowrap;
`;

const WrappedPropertyValue = styled.div`
  margin: 0 20px 0 10px;
  font-size: ${({ theme }) => theme.fsMedium};
  white-space: pre-wrap;
  min-width: 80px;
`;

const Wrapper = styled.div`
  ${({ theme }) =>
    `
    border: 1px solid transparent;
    border-radius: 3px;
    margin: 0;
    position: relative;
    &:hover {
          border: 1px solid ${theme.slider.default.handlerOutlineColor};
        }
  `}
  color: ${({ theme }) => theme.text};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  svg {
    box-sizing: none;
    flex-shrink: 0;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: center;

  @keyframes FadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: FadeIn 0.3s;
`;

const PropertyInfo = () => {
  const { propertyData: property } = useContext(PropertyContext);

  if (!property.property_type) return null;

  return (
    <Wrapper>
      <Section>
        <Home size={12} />
        <PropertyValue>
          <FormattedMessage id={`property_options.${property.property_type}`} />
        </PropertyValue>
      </Section>
      <Section>
        <Maximize size={12} />
        {!!property.size && (
          <PropertyValue>
            {property.size}
            m2
          </PropertyValue>
        )}
      </Section>
      {!!property.zip_code && (
        <Section>
          <MapPin size={12} />
          {(property.city ?? '').length < 7 ? (
            <PropertyValue>
              {`${property.zip_code} ${property.city || ''}`}
            </PropertyValue>
          ) : (
            <WrappedPropertyValue>
              {`${property.zip_code} ${property.city || ''}`}
            </WrappedPropertyValue>
          )}
        </Section>
      )}
      {(!!property.pricehubble_valuation_lower ||
        !!property.valuation_customer) && (
        <Section>
          <Database size={12} />
          <PropertyValue>
            <strong>
              {property.pricehubble_valuation_accepted
                ? `€ ${localizeNumberPropertyValue(
                    property.pricehubble_valuation_lower!
                  )} - ${localizeNumberPropertyValue(
                    property.pricehubble_valuation_upper!
                  )}`
                : `€ ${
                    localizeNumberPropertyValue(property.valuation_customer!) ??
                    0
                  }`}
            </strong>
          </PropertyValue>
        </Section>
      )}
    </Wrapper>
  );
};

export default PropertyInfo;
