import React from 'react';
import styled from 'styled-components';
import { CheckCircle, Circle, Disc } from 'react-feather';
import FlipMove from 'react-flip-move';
import theme from 'theme';
import { FormattedMessage } from 'react-intl';

const actionGreen = '#558B6E';

const IconCompletedSection = () => {
  return <CheckCircle color={actionGreen} style={{ gridArea: 'icon' }} />;
};

const IconInProgressSection = () => {
  return <Disc color={theme.blue} style={{ gridArea: 'icon' }} />;
};

const IconOpenSection = () => {
  return <Circle color={theme.colorBlue25} style={{ gridArea: 'icon' }} />;
};

type ProgressIndicatorProps = {
  sections: {
    title: string;
    active: boolean;
    progressPercentage: number;
    minutesRemaining: number;
    skipped?: boolean;
  }[];
  showOnlyTheActiveSection?: boolean;
  disableAnimations?: boolean;
};

const ProgressIndicatorWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: clamp(10px, calc(10px + 5vw), 18px);
`;

const ProgressIndicatorSectionWrapper = styled.div<{ active: boolean }>`
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: ${({ active }) => (active ? 'auto auto auto' : 'auto')};
  grid-template-areas: ${({ active }) =>
    active
      ? ` 
    'icon section-title'
    'percentage-indicator percentage-indicator'
    'minutes-indicator minutes-indicator'`
      : ` 
    'icon section-title'`};
  gap: 8px;
`;

const ProgressIndicatorSectionText = styled.span<{
  type: 'completed' | 'in-progress' | 'open';
}>`
  align-self: center;
  font-size: 16px;
  line-height: 21px;
  color: ${({ type, theme }) => {
    if (type === 'completed') return actionGreen;
    if (type === 'in-progress') return theme.colorBlue100;
    return theme.colors.bambusBlue75;
  }};

  ${({ type }) => {
    if (type === 'in-progress') return 'font-weight: 700;';
  }}
`;

const ProgressIndicatorRemainingMinutesText = styled.span`
  grid-area: minutes-indicator;
  font-size: 14px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.bambusBlue75};
`;

const PercentageIndicator = styled.div<{ percentage: number }>`
  grid-area: percentage-indicator;
  position: relative;
  background-color: ${({ theme }) => theme.colorBlue10};
  height: 3px;
  border-radius: 2px;
  overflow: hidden;

  ::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: ${({ percentage }) => 100 - percentage}%;
    background-color: ${({ theme }) => theme.blue};
    border-radius: 2px;
    transition: right 0.5s ease-out;
  }
`;

const getIcon = (progressPercentage: number, active: boolean) => {
  if (active) {
    return <IconInProgressSection />;
  } else {
    if (progressPercentage === 100) {
      return <IconCompletedSection />;
    } else {
      return <IconOpenSection />;
    }
  }
};

const ProgressIndicator = ({
  sections,
  showOnlyTheActiveSection = false,
  disableAnimations = false,
}: ProgressIndicatorProps) => {
  return (
    <ProgressIndicatorWrapper>
      <FlipMove
        typeName={null}
        enterAnimation="fade"
        leaveAnimation="fade"
        disableAllAnimations={disableAnimations}
      >
        {sections.map(
          ({ progressPercentage, title, active, minutesRemaining }) => {
            if (showOnlyTheActiveSection && !active) return null;
            return (
              <ProgressIndicatorSectionWrapper active={active} key={title}>
                {getIcon(progressPercentage, active)}
                <ProgressIndicatorSectionText
                  type={
                    active
                      ? 'in-progress'
                      : progressPercentage === 100
                      ? 'completed'
                      : 'open'
                  }
                >
                  {title}
                </ProgressIndicatorSectionText>
                {active && (
                  <>
                    <ProgressIndicatorRemainingMinutesText>
                      <FormattedMessage id="progress_indicator.time_remaining_pre_text" />
                      &nbsp;
                      <strong
                        style={{
                          color: theme.colors.bambusBlue100,
                        }}
                      >
                        {minutesRemaining}&nbsp;
                        {minutesRemaining > 1 ? (
                          <FormattedMessage id="progress_indicator.time_remaining_post_text_more_than_one_minutes" />
                        ) : (
                          <FormattedMessage id="progress_indicator.time_remaining_post_text_1_minute" />
                        )}
                      </strong>
                    </ProgressIndicatorRemainingMinutesText>
                    <PercentageIndicator percentage={progressPercentage} />
                  </>
                )}
              </ProgressIndicatorSectionWrapper>
            );
          }
        )}
      </FlipMove>
    </ProgressIndicatorWrapper>
  );
};

export default ProgressIndicator;
