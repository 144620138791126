import Cookies from 'universal-cookie';

/**
 * Adding the ability to intercept a fetch call, so we can handle error status codes globally
 */
const originalFetch = window.fetch;
window.fetch = function (...args) {
  return new Promise((resolve, reject) => {
    originalFetch
      .apply(this, args)
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          reject();
          window.location.href =
            process.env.REACT_APP_AUTH_URL ?? 'https://auth.bambus.io';
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fileFormData = (body: any) => {
  const formData = new FormData();
  formData.append('file', body.file);
  return formData;
};

export type RequestParams = {
  path: string;
  method?: string;
  body?: any;
  headers?: { [key: string]: string };
  abortSignal?: AbortSignal;
};

export const request = ({
  path,
  method = 'GET',
  body = null,
  headers = {},
  abortSignal,
}: RequestParams) => {
  const cookies = new Cookies();
  const csrf = cookies.get('csrftoken');

  let contentType: { [key: string]: string } = {
    'Content-Type': 'application/json',
  };
  let bodyContent: { body?: any } = body ? { body: JSON.stringify(body) } : {};

  if (body && body.file) {
    contentType = {};
    bodyContent = { body: fileFormData(body) };
  }

  return fetch(path, {
    method,
    headers: {
      ...headers,
      ...contentType,
      ...(csrf ? { 'X-CSRFToken': csrf } : {}),
    },
    ...bodyContent,
    credentials: 'include',
    ...(abortSignal && { signal: abortSignal }),
  }).then((response) => response.json());
};
