import { useEffect } from 'react';
import {
  Applicant2SectionScreens,
  ApplicationSectionScreens,
  MainApplicantSectionScreens,
  MainPropertySectionScreens,
  NewPropertySectionScreens,
  PropertyDebtSectionScreens,
  RealEstateSectionScreens,
  TVApplicationSectionScreens,
  TVMainApplicantSectionScreens,
  TVPropertySectionScreens,
} from 'consts/form';

export interface IUsePreloadSectionJSBundles {
  currentSection: string | undefined;
}

/**
 *
 * Preloads all the screens' JavaScript code for the next section (as lazy loading & code splitting are used).
 *
 */
const usePreloadSectionJSBundles = ({
  currentSection,
}: IUsePreloadSectionJSBundles) => {
  useEffect(() => {
    /**
     * Effect that preloads all the screens for a specific section (given that we use lazy loading & code splitting).
     */
    if (currentSection === 'application') {
      ApplicationSectionScreens.forEach((screen) => screen.preload?.());
    }
    if (currentSection === 'tv_application') {
      TVApplicationSectionScreens.forEach((screen) => screen.preload?.());
    }
    if (currentSection === 'tv_property') {
      TVPropertySectionScreens.forEach((screen) => screen.preload?.());
    }
    if (currentSection === 'property') {
      MainPropertySectionScreens.forEach((screen) => screen.preload?.());
    }
    if (currentSection === 'main_applicant') {
      MainApplicantSectionScreens.forEach((screen) => screen.preload?.());
    }
    if (currentSection === 'tv_main_applicant') {
      TVMainApplicantSectionScreens.forEach((screen) => screen.preload?.());
    }
    if (currentSection === 'applicant_2') {
      Applicant2SectionScreens.forEach((screen) => screen.preload?.());
    }
    if (currentSection === 'new_property') {
      NewPropertySectionScreens.forEach((screen) => screen.preload?.());
    }
    if (currentSection === 'real_estate') {
      RealEstateSectionScreens.forEach((screen) => screen.preload?.());
    }
    if (currentSection === 'property_debt') {
      PropertyDebtSectionScreens.forEach((screen) => screen.preload?.());
    }
  }, [currentSection]);

  return null;
};

export default usePreloadSectionJSBundles;
