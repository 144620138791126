export type ProgressElement = {
  title: string;
  active: boolean;
  progressPercentage: number;
  minutesRemaining: number;
  skipped?: boolean;
};

export const ProgressSectionsEstimatedTimeInMinutesMap = {
  application: 1,
  property: 3,
  property_debt: 2,
  real_estate: 2,
  main_applicant: 4,
  applicant_2: 4,
  new_property: 3,
  tv_application: 1,
  tv_property: 4,
  tv_main_applicant: 3,
};
