import { useContext } from 'react';
import { IntlProvider } from 'react-intl';
import messages, { flattenMessages } from 'translations';
import LocaleContext from './LocaleContext';

function I18nProvider({ children }) {
  const { locale } = useContext(LocaleContext);

  return (
    <IntlProvider locale={locale} messages={flattenMessages(messages[locale])}>
      {children}
    </IntlProvider>
  );
}

export default I18nProvider;
