import React from 'react';
import styled from 'styled-components';
import { Modal, Paragraph, Button } from 'bambus-ui-components';
import { FormattedMessage } from 'react-intl';
import { Formik, Form, FormikProps } from 'formik';
import endpoints from 'consts/endpoints';
import { request } from 'utils/api';
import FormData from 'consts/formData';
import ErrorTooltip from 'pages/Forms/components/ErrorTooltip';

interface IExitModal {
  hideModal: () => void;
  isModalVisible: boolean;
  data: FormData;
}
interface FormValues {
  email?: string;
}
const StyledButton = styled(Button)`
  width: 100%;
  border-radius: 0px;
  height: 46px;

  &:after,
  &:before {
    display: none;
  }
`;

const StyledModalWrapper = styled(Modal.Wrapper)`
  width: 100%;
`;

const ExitModal = ({ isModalVisible, hideModal, data }: IExitModal) => {
  const [isFormError, setIsFormError] = React.useState<boolean>(false);
  const closeModal = () => {
    hideModal();
    setIsFormError(false);
  };
  return (
    <StyledModalWrapper
      isOpen={isModalVisible}
      maxWidth={360}
      red
      style={{
        overlay: {
          zIndex: 10,
        },
      }}
    >
      <Modal.CloseButton onClick={closeModal} />
      <Formik
        initialValues={{
          email: data.primary_contact_details?.email,
        }}
        onSubmit={() => {
          request({ path: endpoints.logout, method: 'POST' })
            .then(() => {
              hideModal();
              setIsFormError(false);
              setTimeout(() => {
                const homeUrl = 'https://www.bambus.io';
                document.location.href = homeUrl;
              }, 1500);
            })
            .catch(() => {
              setIsFormError(true);
            });
        }}
      >
        {({ isSubmitting }: FormikProps<FormValues>) => (
          <Form>
            <Modal.Content>
              <Modal.Header tag="h4" size={4} red>
                <FormattedMessage id="exitModal.question" />
              </Modal.Header>
              <Paragraph medium>
                <FormattedMessage id="exitModal.description" />
              </Paragraph>
            </Modal.Content>
            <Modal.ButtonsWrapper>
              <StyledButton type="submit" normal disabled={isSubmitting}>
                <FormattedMessage id="exitModal.submitButton" />
              </StyledButton>
              {isFormError && <ErrorTooltip isExitModalError={isFormError} />}
            </Modal.ButtonsWrapper>
          </Form>
        )}
      </Formik>
    </StyledModalWrapper>
  );
};
export default ExitModal;
