import React, { useContext } from 'react';
import styled from 'styled-components';
import { media, Row, Col, Container } from 'styled-bootstrap-grid';
import { X } from 'react-feather';
import { ReactComponent as BambusLogo } from 'assets/BambusBlueLogoWithLetterB.svg';
import { Button } from 'bambus-ui-components';
import { HEADER_HEIGHT_PX } from 'consts/layout';
import PropertyInfo from 'components/Header/PropertyInfo';
import ExitModalContext from 'contexts/ExitModalContext';

interface IHeader {
  gridArea?: string;
  showPropertyInfo?: boolean;
}

const StyledHeader = styled.header<any>`
  grid-area: ${({ gridArea }) => gridArea};
  position: sticky;
  top: 0px;
  // Header has fixed height as certain elements that are not statically positioned are based on this and need it
  height: ${HEADER_HEIGHT_PX}px;
  // This is needed as on the 18th of June 2021 the mobile version of the header broke if not width: 100%
  width: 100%;
  padding: 22px 0;
  padding-bottom: 5px;
  z-index: 10;

  // Background with a nice fade effect on the bottom
  &:after {
    content: '';
    background: ${({ theme }) => theme.menuGradient};
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100px;
    pointer-events: none;
    z-index: 1;
  }

  & > ${Container} {
    position: relative;
    z-index: 2;
  }
`;

const HeaderAccessory = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const HeaderLink = styled.a`
  cursor: pointer;
`;

const ExitButton = styled(Button)`
  border: 1px solid rgba(1, 42, 89, 0.1);
  & {
    margin-left: 21px;
  }
  ${media.lg`
    border: 1px solid transparent;
    box-shadow: none;
    & {
      margin-left: 22px;
    }
  `}
`;

const Header = ({ gridArea = 'header', showPropertyInfo }: IHeader) => {
  const { setIsExitModalVisible } = useContext(ExitModalContext);

  return (
    <StyledHeader gridArea={gridArea}>
      <Container>
        <Row alignItems="center" mdJustifyContent="between">
          <Col xs={4} sm={4} md={1} hiddenLgUp>
            <HeaderLink
              target="_blank"
              rel="noopener noreferrer"
              href="https://bambus.io"
            >
              <BambusLogo />
            </HeaderLink>
          </Col>
          <Col hiddenLgDown md={8} lg={11}>
            {showPropertyInfo && <PropertyInfo />}
          </Col>
          <Col xs={8} sm={8} md={1} lg={1}>
            <HeaderAccessory>
              <ExitButton
                basic
                icon={<X />}
                onClick={() => setIsExitModalVisible(true)}
              />
            </HeaderAccessory>
          </Col>
        </Row>
      </Container>
    </StyledHeader>
  );
};

export default Header;
