import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body, input, button, textarea, select {
    font-family: 'Mulish', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
  }
  
  * {
    box-sizing: border-box;
  }

  .container {
    width: 100%;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    *, *::before, *::after  {
      animation-duration: 0.001ms !important;
      animation-iteration-count: 1 !important; /* Hat tip Nick/cssremedy (https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/#comment-1700170) */
      transition-duration: 0.001ms !important;
    }
  }

`;

export default GlobalStyles;
