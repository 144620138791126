import ProgressContext from 'contexts/ProgressContext';
import { ProgressElement } from 'models/Progress';
import { useMemo, useState } from 'react';

function ProgressContextProvider({ children }) {
  const [progress, setProgress] = useState<ProgressElement[]>([]);

  const value = useMemo(() => {
    return {
      progress,
      setProgress,
    };
  }, [progress, setProgress]);

  return (
    <ProgressContext.Provider value={value}>
      {children}
    </ProgressContext.Provider>
  );
}

export default ProgressContextProvider;
