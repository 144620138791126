import React from 'react';

export interface IPropertyData {
  property_type: string | null | undefined;
  size: number | null | undefined;
  zip_code: string | null | undefined;
  city: string | null | undefined;
  pricehubble_valuation_lower: number | null | undefined;
  pricehubble_valuation_upper: number | null | undefined;
  pricehubble_valuation_accepted: boolean | null | undefined;
  valuation_customer: number | null | undefined;
}
export interface IPropertyContext {
  propertyData: IPropertyData;
  setPropertyData: (newValue: IPropertyData) => void;
}
const PropertyContext = React.createContext<IPropertyContext>({
  propertyData: {
    property_type: null,
    size: null,
    zip_code: null,
    city: null,
    pricehubble_valuation_lower: null,
    pricehubble_valuation_upper: null,
    pricehubble_valuation_accepted: null,
    valuation_customer: null,
  },
  setPropertyData: () => {},
});

export default PropertyContext;
