import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArrowUp } from 'react-feather';
import { Button } from 'bambus-ui-components';
import form, { FormSteps } from 'consts/form';
import endpoints from 'consts/endpoints';
import routes from 'consts/routes';
import FormData, {
  Address,
  AdditionalIncome,
  Application,
  ContactDetails,
  Property,
  Applicant,
  Loan,
  Unit,
} from 'consts/formData';
import { request } from 'utils/api';
import ProgressIndicator from 'pages/Forms/components/ProgressIndicator';
import FormLayout from 'pages/Forms/components/FormLayout';
import ExitModal from 'components/ExitModal';
import LocaleContext from 'contexts/LocaleContext';
import ProgressContext from 'contexts/ProgressContext';
import { ProgressSectionsEstimatedTimeInMinutesMap } from 'models/Progress';
import { useMediaQuery } from 'react-responsive';
import RestartOnboardingModal from 'components/RestartOnboardingModal';
import useRestartOnboardingModal from 'components/RestartOnboardingModal/useRestartOnboardingModal';
import SupportModal from 'components/SupportModal';
import useSupportModal from 'components/SupportModal/useSupportModal';
import usePreloadSectionJSBundles from 'hooks/usePreloadSectionJSBundles';
import ExitModalContext from 'contexts/ExitModalContext';
import PropertyContext from 'contexts/PropertyContext';
import SectionsContext from 'contexts/SectionsContext';

const BackButtonWrapper = styled.div`
  margin-left: -8px;
`;

export interface FormProps {
  onSubmit: (
    data:
      | FormData
      | AdditionalIncome
      | Address
      | Application
      | Applicant
      | ContactDetails
      | Loan
      | Property
      | Unit
  ) => Promise<any>;
  data: FormData;
  variablesToDisplay: string[] | undefined;
  variablesToDisplayByStep: object;
  isFormDataLoading: boolean;
  step: string;
  current_screen?: string;
  error?: boolean;
  history: string[];
  onJumpBackToStep: (step: string) => any;
}
export type FormComponent = React.FC<FormProps>;

const defaultConfig = {
  Layout: FormLayout,
  isProgressIndicatorVisible: true,
  hideProgressIndicatorMobileMode: false,
  isBackButtonVisible: true,
};

const getCurrentSectionNameBasedOnURLPath = (path: string) => {
  return path.slice(6).split('/')[1] ? path.slice(6).split('/')[0] : undefined;
};

const Forms = () => {
  const [history, setHistory] = useState<Array<string>>([]);
  const [currentStep, setCurrentStep] = useState<string>();
  const [formData, setFormData] = useState<FormData>({});
  const [formError, setFormError] = useState<boolean>();
  const [variablesToDisplayByStep, setVariablesToDisplayByStep] =
    useState<object>({});
  const [isFormDataLoading, setIsFormDataLoading] = useState<boolean>(true);

  const location = useLocation();
  const currentSection = getCurrentSectionNameBasedOnURLPath(location.pathname);

  const intl = useIntl();

  const {
    isAutomaticSupportModalVisible,
    signalSubmitForAutomaticSupportModal,
    requestCallback,
    setIsAutomaticSupportModalVisible,
    initializeAutomaticSupportModalTimer,
  } = useSupportModal({ currentStep });

  const {
    isRestartOnboardingModalVisible,
    setIsRestartOnboardingModalVisible,
    restartOnboarding,
    checkWhetherToShowResetModal,
  } = useRestartOnboardingModal();

  const { setLocale } = useContext(LocaleContext);
  const { progress, setProgress } = useContext(ProgressContext);
  const { isExitModalVisible, setIsExitModalVisible } =
    useContext(ExitModalContext);
  const { setPropertyData } = useContext(PropertyContext);
  const { sectionsData, setSectionsData } = useContext(SectionsContext);

  useEffect(() => {
    setIsFormDataLoading(true);
    // This hook is called when the user enters a new section, it defines sections for sidebar. Request user's steps history; then group sections into: completed, future, skipped; then set boolean to trigger dispatch update
    request({ path: endpoints.steps })
      .then((data) => {
        if (data.completed) {
          // Filter list of completed steps for sections that contain input screens (length > 1) and map names of those sections in new array, then set completedSections
          const completedSections = Object.entries(data.completed)
            .filter((section: any[]) => {
              return (
                section[1].filter(
                  (value: string, index: number, self: any[]) =>
                    self.indexOf(value) === index
                ).length > 1
              );
            })
            .map((section) => section[0]);
          setSectionsData((oldSectionsData) => ({
            ...oldSectionsData,
            completed: completedSections,
          }));
        }
        if (data.user_skip_requested) {
          // Filter list of skipped steps for section that contain input screens (if screen name contains section & step name, then it can be split by "/"), then set skippedSections
          const skippedSections = data.user_skip_requested
            .filter((item: string) => {
              const step = item.split('/')[1];
              return !!step;
            })
            .map((step: string) => step.split('/')[0]);
          setSectionsData((oldSectionsData) => ({
            ...oldSectionsData,
            skipped: skippedSections.filter(
              (section: string) =>
                !oldSectionsData.completed ||
                oldSectionsData.completed.indexOf(section) === -1
            ),
          }));
        }
        if (data.allowed_current_steps) {
          // Filter list of allowed next steps for sections that contain input screens (if screen name contains section & step name, then it can be split by "/"), then set futureSections
          const futureSections = data.allowed_current_steps
            .filter((item: string) => {
              const step = item.split('/')[1];
              return !!step;
            })
            .map((step: string) => step.split('/')[0]);
          setSectionsData((oldSectionsData) => ({
            ...oldSectionsData,
            future: futureSections.filter(
              (section: string) =>
                !oldSectionsData.skipped ||
                oldSectionsData.skipped.indexOf(section) === -1
            ),
          }));
        }
        setSectionsData((oldSectionsData) => ({
          ...oldSectionsData,
          current: currentSection,
        }));
        if (data.screens_display_variables_list) {
          setVariablesToDisplayByStep(data.screens_display_variables_list);
        }
      })
      .then(() => {
        setIsFormDataLoading(false);
      });
  }, [currentSection, setSectionsData]);

  usePreloadSectionJSBundles({ currentSection });

  useEffect(() => {
    // This hook is called when the page is loaded, it retrieves user data from the GET Details endpoint and stores it
    request({ path: endpoints.details }).then((data) => {
      // If the user has Cockpit enabled, redirect to Cockpit
      if (data?.application?.cockpit_enabled) {
        window.location.assign(
          process.env.REACT_APP_COCKPIT_URL || 'https://app.bambus.io/'
        );
      }

      // Updating the locale if we have locale data
      let locale = '';
      if (data?.application?.language) {
        locale = data.application.language;
      }
      if (data?.application?.language_variant) {
        locale = `${locale}-${data.application.language_variant}`;
      }
      locale = locale.toLowerCase();
      if (locale) {
        setLocale(locale);
      }

      setFormData((currentFormData) => ({ ...currentFormData, ...data }));

      setPropertyData({
        property_type: data?.main_property?.property_type,
        size: data?.main_property?.living_area,
        zip_code: data?.main_property_address?.zipcode,
        city: data?.main_property_address?.city,
        pricehubble_valuation_lower:
          data?.main_property?.pricehubble_valuation_lower,
        pricehubble_valuation_upper:
          data?.main_property?.pricehubble_valuation_upper,
        pricehubble_valuation_accepted:
          data?.main_property?.pricehubble_valuation_accepted,
        valuation_customer: data?.main_property?.valuation_customer,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Hook called when currentStep changes - refetches and updates the progress data
    if (currentStep) {
      const controller = new AbortController();
      const { signal } = controller;
      request({ path: endpoints.progress, abortSignal: signal })
        .then(
          (
            progressData: {
              section_name: string;
              total_countable: number;
              finished_countable: number;
              completed?: boolean;
              skipped?: boolean;
            }[]
          ) => {
            setProgress(
              progressData
                .map((section) => {
                  const sectionMinutesTotalEstimated =
                    ProgressSectionsEstimatedTimeInMinutesMap[
                      section.section_name
                    ];
                  const progressFraction =
                    section.finished_countable / section.total_countable;
                  const progressPercentage =
                    currentStep.includes('review') &&
                    currentSection?.includes(section.section_name)
                      ? 100
                      : progressFraction * 100;
                  const active = section.section_name === currentSection;

                  return {
                    title: intl.formatMessage({
                      id: `sections.${section.section_name}`,
                    }),
                    active,
                    progressPercentage,
                    minutesRemaining:
                      Math.ceil(
                        sectionMinutesTotalEstimated -
                          sectionMinutesTotalEstimated * progressFraction
                      ) || 1,
                    skipped: section.skipped,
                    // Setting an order number as the ordering is the following: 1. Finished Sections, 2. Active Section, 3. Other (upcoming & skipped)
                    orderNumber:
                      progressPercentage === 100 ? 1 : active ? 2 : 3,
                  };
                })
                .sort(
                  (sectionA, sectionB) =>
                    sectionA.orderNumber - sectionB.orderNumber
                )
            );
          }
        )
        .catch((err) => {
          if (err.name === 'AbortError') {
            console.log('Fetch aborted');
          } else {
            console.error('Error', err);
          }
        });
      // abort the request (if still ongoing) when this effect reruns
      return () => {
        controller.abort();
      };
    }
  }, [currentStep, currentSection, setProgress, intl]);

  useEffect(() => {
    // When form is loaded get the users step history from GET Steps endpoint - the response json contains allowed steps, the default next step and the previously completed steps.
    // When a step belongs to a section the format is : section_name/screen_name eg "application/intro". Otherwise just the screen name.
    // The following defines the logic for which step (screen) is loaded and sets the users step history according to previously completed steps
    request({ path: endpoints.steps }).then((data) => {
      const calledStep = location.pathname.slice(6);
      const calledSection = calledStep.split('/')[0];

      if (data.latest_submitted_step?.timestamp) {
        checkWhetherToShowResetModal(data.latest_submitted_step.timestamp);
      }

      if (
        data.allowed_current_steps?.includes(calledStep) ||
        calledStep === data.default_current_step
      ) {
        //  check if step specified in location is in allowed steps or the default_next_step, then set step and set step history
        setCurrentStep(calledStep);
        !!data.completed[calledSection]
          ? setHistory(data.completed[calledSection].concat(calledStep))
          : setHistory([]);
      } else if (
        location.pathname === `/form/` ||
        !calledStep ||
        (!!data.completed[calledSection] &&
          data.completed[calledSection].includes(calledStep))
      ) {
        if (data.default_current_step === null) {
          setCurrentStep('no_step_supplied_from_server');
          return;
        }
        // if no step is specified or its a step completed in the past, then set the default_next_step as current step
        const defaultSection = data.default_current_step.split('/')[0];
        setCurrentStep(data.default_current_step);
        // if there are previously completed steps, set step history with default_next_step included
        !!data.completed[defaultSection]
          ? data.completed[defaultSection].indexOf(data.default_current_step) >
            0
            ? setHistory(
                data.completed[defaultSection].slice(
                  0,
                  data.completed[defaultSection].indexOf(
                    data.default_current_step
                  ) + 1
                )
              )
            : setHistory(
                data.completed[defaultSection].concat(data.default_current_step)
              )
          : setHistory([]);
      } else {
        setCurrentStep('no_permission');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToPreviousStep = () => {
    const step = location.pathname.slice(6);
    // TODO: Investigate if below logic is really needed
    // If current step is in History then take what's before the current step
    // but otherwise.. take the last step from history? (why/when can that happen?)
    const previousStep = history.includes(step)
      ? history[history.indexOf(step) - 1]
      : history[history.length - 1];
    // Keep all items from History that are there before the previous step
    // Basically returns to a previous step and deletes all History that happened after it
    setHistory([
      ...history.filter((s, index) => {
        return index <= history.indexOf(previousStep);
      }),
    ]);

    setCurrentStep(previousStep);
    setFormError(false);
  };

  const onJumpBackToStep = (step: string) => {
    const stepIndexInHistory = history.indexOf(step);
    if (stepIndexInHistory !== -1) {
      setHistory([
        ...history.filter((s, index) => {
          return index <= stepIndexInHistory;
        }),
      ]);
      setCurrentStep(step);
      // TODO: Not sure if the below are needed, investigate and refactor if necessary
      setFormError(false);
    }
  };

  const setNextStep = (step: string) => {
    setCurrentStep(step);
    setHistory([...history, step]);
    setFormError(false);
  };

  const getNextSection = () => {
    /**
     * All the sections that are in the "future" sections array that satisfy the conditions:
     * 1. Not the current section
     * 2. Is not completed
     */
    const incompleteFutureSections =
      sectionsData?.future?.filter(
        (section) =>
          section !== sectionsData.current &&
          (!sectionsData.completed ||
            sectionsData.completed.indexOf(section) === -1)
      ) ?? [];

    /**
     * All the sections that are incomplete future or skipped
     */
    const allIncompleteSections =
      incompleteFutureSections.concat(
        sectionsData?.skipped?.filter(
          (section) => section !== sectionsData.current
        ) ?? []
      ) ?? [];
    if (allIncompleteSections.length > 0) {
      setNextStep(`${allIncompleteSections[0]}/intro`);
    } else {
      setNextStep('survey');
    }
  };

  /**
   * Submits the current step's data and sets the next step.
   *
   * Logic:
   * IF there's a next step in the response AND
   *    (response.user_skip_requested is empty OR all the sections from response.user_skip_requested have been completed OR the next_step is 'thank_you')
   *   THEN
   *      IF the reponse.next_step is an "intro" step (read: screen) AND the associated section has already been completed
   *        THEN getNextSection()
   *        ELSE set the next step as response.next_step IF not empty - if empty then there was a server logic error -> set the logic error screen
   *   ELSE
   *      IF there are requested skips
   *        THEN navigate to the first requested skip
   *        ELSE set form error true
   *
   */
  const submit = (endpoint: string) => (data: any) =>
    request({ path: endpoint, method: 'PATCH', body: data })
      .then((response) => {
        signalSubmitForAutomaticSupportModal();
        setFormData({ ...formData, ...data });
        if (
          !!response.next_step &&
          (!response.user_skip_requested.length ||
            response.user_skip_requested?.filter(
              (screen: string) =>
                !sectionsData.completed?.includes(screen.split('/')[0])
            ).length === 0 ||
            response.next_step === 'thank_you')
        ) {
          if (
            !!response.next_step?.split('/')[1] &&
            response.next_step?.split('/')[1] === 'intro' &&
            sectionsData.completed?.includes(response.next_step?.split('/')[0])
          ) {
            getNextSection();
          } else {
            if (!response.next_step) {
              setNextStep('no_step_supplied_from_server');
            } else {
              setNextStep(response.next_step);
            }
          }
          setFormError(false);
        } else if (
          !!response.user_skip_requested &&
          response.user_skip_requested.length > 0
        ) {
          setNextStep(response.user_skip_requested[0]);
          setFormError(false);
        } else {
          setFormError(true);
        }
      })
      .then(() => {
        request({ path: endpoints.details }).then((data) => {
          setFormData((currentFormData) => ({ ...currentFormData, ...data }));

          // Updating the locale if we have new locale data
          let locale = '';
          if (data?.application?.language) {
            locale = data.application.language;
          }
          if (data?.application?.language_variant) {
            locale = `${locale}-${data.application.language_variant}`;
          }
          locale = locale.toLowerCase();
          if (locale) {
            setLocale(locale);
          }

          setPropertyData({
            property_type: data?.main_property?.property_type,
            size: data?.main_property?.living_area,
            zip_code: data?.main_property_address?.zipcode,
            city: data?.main_property_address?.city,
            pricehubble_valuation_lower:
              data?.main_property?.pricehubble_valuation_lower,
            pricehubble_valuation_upper:
              data?.main_property?.pricehubble_valuation_upper,
            pricehubble_valuation_accepted:
              data?.main_property?.pricehubble_valuation_accepted,
            valuation_customer: data?.main_property?.valuation_customer,
          });
        });
      })
      .catch(() => {
        setFormError(true);
      });

  const formConfig = currentStep ? form.steps[currentStep] : {};

  const Config = {
    ...defaultConfig,
    ...formConfig,
  };

  const isProgressIndicatorInMobileMode = useMediaQuery({
    maxWidth: 992,
  });

  const shouldShowPropertyHeader =
    sectionsData.completed?.includes('property') ||
    (history.includes(FormSteps.MainPropertyValuation) &&
      currentStep !== FormSteps.MainPropertyValuation);

  return (
    <Config.Layout
      progressIndicator={
        Config.isProgressIndicatorVisible &&
        !(
          isProgressIndicatorInMobileMode &&
          Config.hideProgressIndicatorMobileMode
        ) && (
          <ProgressIndicator
            sections={progress}
            showOnlyTheActiveSection={
              isProgressIndicatorInMobileMode &&
              !currentStep?.includes('intro') &&
              !currentStep?.includes('start')
            }
          />
        )
      }
      backButton={
        history.length > 1 &&
        Config.isBackButtonVisible && (
          <BackButtonWrapper>
            <Button
              transparent
              onClick={navigateToPreviousStep}
              icon={<ArrowUp />}
            >
              <FormattedMessage id="previous_step" />
            </Button>
          </BackButtonWrapper>
        )
      }
      isPropertyInfoVisible={shouldShowPropertyHeader}
    >
      <React.Suspense fallback={null}>
        <Switch>
          {Object.keys(form.steps).map((key) => {
            const {
              name,
              component: Component,
              endpoint = endpoints.details,
            }: {
              name: string;
              component: FormComponent;
              endpoint?: string;
            } = form.steps[key];
            return (
              <Route
                exact
                key={name}
                path={routes.form[name]}
                render={() => (
                  <Component
                    onSubmit={submit(endpoint)}
                    data={formData}
                    variablesToDisplay={variablesToDisplayByStep[name]}
                    variablesToDisplayByStep={variablesToDisplayByStep}
                    isFormDataLoading={isFormDataLoading}
                    step={name}
                    error={formError}
                    history={history}
                    onJumpBackToStep={onJumpBackToStep}
                  />
                )}
              />
            );
          })}
        </Switch>
      </React.Suspense>
      {currentStep && <Redirect to={`/form/${currentStep}`} />}
      <ExitModal
        isModalVisible={isExitModalVisible}
        data={formData}
        hideModal={() => setIsExitModalVisible(false)}
      />
      {/* The "initializeAutomaticSupportModalTimer" function call is present at the RestartOnboardingModal modal's
      closing time because we do not want to show the SupportModal right (or soon) after closing this modal. */}
      <RestartOnboardingModal
        isModalVisible={isRestartOnboardingModalVisible}
        onCloseModal={() => {
          initializeAutomaticSupportModalTimer();
          setIsRestartOnboardingModalVisible(false);
        }}
        onRestartOnboarding={async () => {
          initializeAutomaticSupportModalTimer();
          await restartOnboarding();
        }}
        onContinueApplication={() => {
          initializeAutomaticSupportModalTimer();
          setIsRestartOnboardingModalVisible(false);
        }}
      />
      <SupportModal
        isModalVisible={
          !isRestartOnboardingModalVisible && isAutomaticSupportModalVisible
        }
        onCloseModal={() => {
          setIsAutomaticSupportModalVisible(false);
          initializeAutomaticSupportModalTimer();
        }}
        onContactSupport={requestCallback}
        data={formData}
      />
    </Config.Layout>
  );
};

export default Forms;
