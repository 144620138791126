import { useEffect } from 'react';

import history from 'RoutingHistory';

const MatomoPageViewAnalytics = () => {
  /**
   * Set up tracking of page views for Matomo
   */
  useEffect(() => {
    const unlisten = history.listen(() => {
      const _paq: any = (window as any)._paq;
      if (_paq) {
        _paq.push(['setCustomUrl', window.location]);
        _paq.push(['setDocumentTitle', document.title]);
        _paq.push(['trackPageView']);
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  return null;
};

export default MatomoPageViewAnalytics;
