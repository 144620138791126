import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from 'utils/scrollToTop';
import { Forms, CustomErrorPage } from './pages';
import routes from './consts/routes';

const AppRouter: React.FC = () => (
  <ScrollToTop>
    <Switch>
      <Redirect exact from="/" to="/form/" />
      <Route path={routes.form.index} component={Forms} />
      <Route path={routes.error.NotFoundPage} component={CustomErrorPage} />
      <Route component={CustomErrorPage} />
    </Switch>
  </ScrollToTop>
);

export default AppRouter;
