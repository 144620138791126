import { useMediaQuery } from 'react-responsive';
import IsMobileContext from 'contexts/IsMobileContext';

function IsMobileContextProvider({ children }) {
  const isMobile = useMediaQuery({
    maxWidth: 800,
  });

  return (
    <IsMobileContext.Provider value={isMobile}>
      {children}
    </IsMobileContext.Provider>
  );
}

export default IsMobileContextProvider;
