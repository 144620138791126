import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { media } from 'styled-bootstrap-grid';
import { Formik, Form, FormikProps, FieldProps, Field } from 'formik';
import {
  Modal,
  Paragraph,
  Button,
  InputWrapper,
  CountryCodeSelect,
  TextInput,
} from 'bambus-ui-components';
import * as Yup from 'yup';
import { formatPhoneNumber, splitPhoneNumber } from 'utils/utils';
import FormData from 'consts/formData';

type RestartOnboardingModalProps = {
  isModalVisible: boolean;
  data: FormData;
  onCloseModal: () => any;
  onContactSupport: (phoneNumber: string) => Promise<any>;
};

interface FormValues {
  area_code: string;
  number?: string;
}

const SupportModalFormSchema = Yup.object().shape({
  area_code: Yup.string(),
  number: Yup.string()
    .required()
    .matches(
      /^\s*([1-9]{1})(\d{1,})[-. ]*(\d{2,})[-. ]*(\d{2,})[-. ]*(\d{0,})(?: *x(\d+))?\s*$/gm,
      'must be a valid phone number'
    ),
});

const StyledWrapper = styled(Modal.Wrapper)`
  min-width: 350px;
  ${media.lg`
    min-width: 750px;
  `}
  overflow: visible;
`;

const ButtonsWrapper = styled.div`
  // emulating gap
  --gap: 20px;
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  // this needs 2x specificity thus the 2 "&" as we're manipulating the Button components
  && > * {
    margin: var(--gap) 0 0 var(--gap);
  }

  & > * {
    flex: 1;
  }

  ${media.lg`
    & > * {
      flex: 0;
    }
  `}
`;

const SupportModal = ({
  isModalVisible,
  onCloseModal,
  data,
  onContactSupport: onContactSupportProp,
}: RestartOnboardingModalProps) => {
  const [isRequestingSupportInProgress, setIsRequestingSupportInProgress] =
    useState(false);

  const onContactSupport = async (phoneNumber: string) => {
    setIsRequestingSupportInProgress(true);
    await onContactSupportProp(phoneNumber);
    setIsRequestingSupportInProgress(false);
  };

  const isLocaleAT =
    `${data.application?.language}-${data.application?.language_variant}`.toUpperCase() ===
    'DE-AT';

  return (
    <StyledWrapper
      isOpen={isModalVisible}
      style={{
        overlay: {
          zIndex: 10,
        },
      }}
    >
      <Modal.CloseButton onClick={onCloseModal} />
      <Modal.Content>
        <Modal.Header tag="h4" size={3}>
          <FormattedMessage id="supportModal.header" />
        </Modal.Header>
        <Paragraph large>
          <FormattedMessage id="supportModal.paragraph" />
        </Paragraph>
        <Formik
          initialValues={{
            ...splitPhoneNumber(
              data.main_applicant_contact_details?.phone_number ??
                (data.application?.primary_contact_is_main_applicant
                  ? data.primary_contact_details?.phone_number
                  : data.main_applicant_contact_details?.phone_number),
              isLocaleAT ? '+43' : '+49'
            ),
          }}
          onSubmit={(values: FormValues, { setSubmitting }) => {
            onContactSupport(
              formatPhoneNumber(
                values.area_code,
                (values.number || '')
                  // eslint-disable-next-line
                  .replace(/\ /g, '')
                  // eslint-disable-next-line
                  .replace(/\-/g, '')
                  .replace(/\(/g, '')
                  .replace(/\)/g, '')
              )
            ).finally(() => {
              setSubmitting(false);
            });
          }}
          validateOnMount={true}
          validationSchema={SupportModalFormSchema}
        >
          {({
            errors,
            touched,
            isSubmitting,
            isValid,
            setFieldValue,
            setFieldTouched,
          }: FormikProps<FormValues>) => (
            <Form>
              <div style={{ maxWidth: 600, marginBottom: 30 }}>
                <InputWrapper sm={140}>
                  <Field type="text" name="area_code">
                    {({ field }: FieldProps) => (
                      <CountryCodeSelect
                        {...field}
                        onChange={(v) => {
                          setFieldValue(field.name, v);

                          /**
                           * Workaround to fix validation not triggered correctly on mobile:
                           * https://github.com/formium/formik/issues/2083#issuecomment-639962222
                           */
                          setTimeout(() => {
                            setFieldTouched(field.name, true);
                          });
                        }}
                        value={field.value}
                      />
                    )}
                  </Field>
                  <Field type="text" name="number">
                    {({ field }: FieldProps) => (
                      <TextInput
                        {...field}
                        hasError={touched.number && !!errors.number}
                        isValid={
                          touched.number && !!field.value && !errors.number
                        }
                        mobileKeyboardType="tel"
                      />
                    )}
                  </Field>
                </InputWrapper>
              </div>
              <ButtonsWrapper>
                <Button
                  outline
                  onClick={onCloseModal}
                  disabled={isRequestingSupportInProgress}
                >
                  <FormattedMessage id="supportModal.buttonCancel" />
                </Button>
                <Button
                  muted
                  type="submit"
                  disabled={isRequestingSupportInProgress}
                >
                  <FormattedMessage id="supportModal.buttonRequestSupport" />
                </Button>
              </ButtonsWrapper>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </StyledWrapper>
  );
};

export default SupportModal;
