import React from 'react';
import styled, { css } from 'styled-components';
import { AlertCircle } from 'react-feather';
import { FormattedMessage } from 'react-intl';

const ModalWrapper = styled.div`
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.red};
  height: auto;
  font-size: 14px;
  display: flex;
  position: absolute;
  bottom: 46px;
  left: 0;
  padding: 7px 10px 7px 10px;
  justify-content: center;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  z-index: 5;
  border-radius: 3px;

  svg {
    stroke: ${({ theme }) => theme.white};
    display: block;
  }

  span {
    display: block;
    font-size: ${({ theme }) => theme.fsNormal};
    font-weight: bold;
    margin: 0 0 0 9px;
  }
`;

const WrapperArrowLeft = css`
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translate(0, -50%);
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent ${({ theme }) => theme.red} transparent
      transparent;
  }
`;

const WrapperArrowBottom = css`
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50, 0%);
    margin-bottom: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({ theme }) => theme.red} transparent transparent
      transparent;
  }
`;

const Wrapper = styled.div`
  color: ${({ theme }) => theme.white};
  background-color: ${({ theme }) => theme.red};
  height: auto;
  font-size: 14px;
  display: flex;
  position: absolute;
  top: -60px;
  left: 0;
  padding: 7px 80px 7px 10px;
  justify-content: center;
  flex-flow: row nowrap;
  width: 100%;
  align-items: center;
  z-index: 5;
  border-radius: 3px;

  svg {
    display: none;
    stroke: ${({ theme }) => theme.white};
  }

  ${WrapperArrowBottom}

  @media (min-width: 768px) {
    width: auto;
    padding: 7px 10px;
    position: absolute;
    top: 70%;
    right: -10px;
    left: unset;
    transform: translate(100%, -50%);

    span {
      width: 250px;
    }

    svg {
      display: block;
    }

    ${WrapperArrowLeft}
  }

  span {
    display: block;
    font-size: ${({ theme }) => theme.fsNormal};
    font-weight: bold;
    margin: 0 0 0 9px;
  }
`;

export interface IErrorTooltip {
  isExitModalError?: boolean;
}
const ErrorTooltip = ({ isExitModalError }: IErrorTooltip) => {
  if (isExitModalError) {
    return (
      <ModalWrapper>
        <AlertCircle />
        <FormattedMessage tagName="span" id="errors.could_not_save" />
      </ModalWrapper>
    );
  }
  return (
    <Wrapper>
      <AlertCircle />
      <FormattedMessage tagName="span" id="errors.generic_error" />
    </Wrapper>
  );
};

export default ErrorTooltip;
