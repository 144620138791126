import endpoints from 'consts/endpoints';
import { useState } from 'react';
import { request } from 'utils/api';

const useRestartOnboardingModal = () => {
  const [isRestartOnboardingModalVisible, setIsRestartOnboardingModalVisible] =
    useState<boolean>(false);

  const checkWhetherToShowResetModal = (lastActivityTimestamp: string) => {
    const lastActivityDate = new Date(lastActivityTimestamp);
    if (lastActivityDate.toString() === 'Invalid Date') {
      return;
    }

    const currentDate = new Date();

    const daysDistance = Math.round(
      (currentDate.getTime() - lastActivityDate.getTime()) /
        (1000 * 60 * 60 * 24)
    );

    if (daysDistance >= 7) {
      setIsRestartOnboardingModalVisible(true);
    }
  };

  const restartOnboarding = async () => {
    try {
      await request({ path: endpoints.resetOnboarding, method: 'POST' });

      // Not the best way to reload the app as the browser needs to parse all the FE JS again (takes time).
      // However, given the complexity of the code at the time of implementing this feature ("reset Onboarding"), this was the simplest
      // and quickest solution.
      // The window location is set to the origion (thus removing any subpath - to start from scratch)
      window.location.href = window.location.origin;
    } catch {
      // Fail graciously... can't really do anything at this point! :(
    } finally {
      setIsRestartOnboardingModalVisible(false);
    }
  };

  return {
    isRestartOnboardingModalVisible,
    checkWhetherToShowResetModal,
    setIsRestartOnboardingModalVisible,
    restartOnboarding,
  };
};

export default useRestartOnboardingModal;
