import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Mail, Phone } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import LocaleContext from 'contexts/LocaleContext';
import { FOOTER_BREAKPOINT_PX, FOOTER_BREAKPOINT_LG_PX } from 'consts/layout';
import { ReactComponent as BambusLogo } from 'assets/BambusBlueLogoWithName.svg';
import ManualRestartOnboardingModal from 'components/ManualRestartOnboardingModal';
import { request } from 'utils/api';
import endpoints from 'consts/endpoints';
import { Button } from 'bambus-ui-components';

const StyledFooter = styled.footer`
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'logo .'
    'phone-number email'
    'data-protection imprint'
    'restart .';
  align-items: center;
  row-gap: 20px;
  column-gap: 3px;

  padding: 20px;

  & .icon {
    display: none;
  }

  background: ${({ theme }) => theme.grey};

  @media (min-width: ${FOOTER_BREAKPOINT_PX}px) {
    grid-template-rows: auto;
    grid-template-columns: auto auto auto 1fr auto auto;
    grid-template-areas:
      'logo phone-number email . data-protection imprint'
      'restart restart . . . .';
    column-gap: 2vw;
    //playing around with the padding here
    padding: 40px min(4vw, 60px);

    & .icon {
      display: block;
    }
  }

  @media (min-width: ${FOOTER_BREAKPOINT_LG_PX}px) {
    grid-template-columns: auto auto auto 1fr auto auto auto;
    grid-template-areas: 'logo phone-number email . data-protection imprint restart';
  }
`;

const FooterLink = styled.a`
  border: none;
  appearance: none;
  cursor: pointer;

  color: ${({ theme }) => theme.colorBlue100};
  text-decoration: none;

  font-size: ${({ theme }) => theme.fsNormal};
  white-space: nowrap;

  @media (min-width: ${FOOTER_BREAKPOINT_PX}px) {
    font-size: ${({ theme }) => theme.fsMedium};
  }
`;

const IconAndCommunicationOptionHolder = styled.div`
  display: flex;
  align-items: center;
  & .icon {
    margin-right: 10px;
  }
`;

const Footer = () => {
  const { locale } = useContext(LocaleContext);
  const theme = useContext(ThemeContext);

  const [
    isManualRestartOnboardingModalVisible,
    setIsManualRestartOnboardingModalVisible,
  ] = useState(false);

  const onRestartOnboarding = async () => {
    try {
      await request({ path: endpoints.resetOnboarding, method: 'POST' });

      // Not the best way to reload the app as the browser needs to parse all the FE JS again (takes time).
      // However, given the complexity of the code at the time of implementing this feature ("reset Onboarding"), this was the simplest
      // and quickest solution.
      // The window location is set to the origion (thus removing any subpath - to start from scratch)
      window.location.href = window.location.origin;
    } catch {
      // Fail graciously... can't really do anything at this point! :(
    } finally {
      setIsManualRestartOnboardingModalVisible(false);
    }
  };

  return (
    <StyledFooter>
      <div style={{ gridArea: 'logo' }}>
        <BambusLogo />
      </div>
      <FooterLink
        href={locale === 'de-at' ? 'tel:0043800007171' : 'tel:004930568373000'}
        style={{ gridArea: 'phone-number' }}
      >
        <IconAndCommunicationOptionHolder>
          <Phone size={16} className="icon" color={theme.colorBlue100} />
          {locale === 'de-at' ? (
            <span>+43 800 00 7171</span>
          ) : (
            <span>+49 30 837 960 40</span>
          )}
        </IconAndCommunicationOptionHolder>
      </FooterLink>
      <FooterLink
        href="mailto:beratung@bambus.io"
        style={{ gridArea: 'email' }}
      >
        <IconAndCommunicationOptionHolder>
          <Mail size={16} className="icon" color={theme.colorBlue100} />
          <span>
            <FormattedMessage id="footer.email_address" />
          </span>
        </IconAndCommunicationOptionHolder>
      </FooterLink>
      <FooterLink
        href="https://www.bambus.io/datenschutz/"
        target="_blank"
        style={{ gridArea: 'data-protection' }}
      >
        <FormattedMessage id="footer.menu.dataProtection" />
      </FooterLink>
      <FooterLink
        href="https://www.bambus.io/impressum/"
        target="_blank"
        style={{ gridArea: 'imprint' }}
      >
        <FormattedMessage id="footer.menu.imprint" />
      </FooterLink>
      <Button
        outline
        // @ts-ignore
        style={{
          gridArea: 'restart',
        }}
        onClick={() => {
          setIsManualRestartOnboardingModalVisible(true);
        }}
      >
        <FormattedMessage id="footer.menu.restart" />
      </Button>
      <ManualRestartOnboardingModal
        isModalVisible={isManualRestartOnboardingModalVisible}
        onCloseModal={() => {
          setIsManualRestartOnboardingModalVisible(false);
        }}
        onCancel={() => {
          setIsManualRestartOnboardingModalVisible(false);
        }}
        onRestartOnboarding={onRestartOnboarding}
      />
    </StyledFooter>
  );
};

export default Footer;
