import React from 'react';
import Page from 'components/Page';
import { Paragraph, Spacer, Button, Heading } from 'bambus-ui-components';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import { FormattedMessage } from 'react-intl';

const CustomErrorPage = () => (
  <Page>
    <Container>
      <Row>
        <Col xs={1} sm={1} md={1} lg={2} xl={3} />
        <Col xs={10} sm={10} md={8} lg={7} xl={6}>
          <Spacer level="3">
            <Heading tag="h1" size={1}>
              <FormattedMessage id="errors.error_page_title" />
            </Heading>
          </Spacer>
          <Heading tag="h2" size={3}>
            <FormattedMessage id="errors.error_page_subtitle" />
          </Heading>
          <Paragraph large>
            <FormattedMessage id="errors.error_page_text" />
          </Paragraph>
          <a href="https://bambus.io">
            <Button normal large>
              <FormattedMessage id="back_to_home" />
            </Button>
          </a>
        </Col>
      </Row>
    </Container>
  </Page>
);

export default CustomErrorPage;
